import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import NavbarParceiro from '../../../components/NavbarParceiro'
import FooterCtrl from '../../../components/footer'
import { Card, Input, Typography,  Button, Row, Col, Select, Space, message, Modal } from 'antd'
import { validateEmail } from '../../../service/util'
import InputMask from 'react-input-mask'
import * as parceiroAction from '../../../store/actions/parceiro.action'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../service/api'
import ContratoCtrl from '../../../components/parceiros/contratoCtrl'
import { isMobile } from 'react-device-detect'

const { Option } = Select

export const ParceiroRegistro = (props) => {

    const { parceiro, token, saveParceiro } = props
    const navigate = useNavigate()
    const [register, setRegister] = useState(parceiro?.parceiro)
    const [loading, setLoading] = useState(false)
    const [mask, setMask] = useState("(99) 99999-9999");
    const [disableButton, setDisableButton] = useState(true)
    const [visivel, setVisivel] = useState(false)

    const [status, setStatus] = useState('Todos')
    const [key, setKey] = useState('1')

    useEffect(() => {
        //  console.log("Parceiro:", register)
        validarForm()
    }, [register])


    function validarForm() {
        var erro = register?.senha !== register?.senha2
        console.log('register?.senha !== register?.senha2', register?.senha !== register?.senha2)
        try {
            if (!register)
                erro = true
            if (!register)
                erro = true
            if (!register?.nome)
                erro = true
            if (register?.cpf?.includes('_') || register?.cpf === '')
                erro = true
            if (register?.fone1?.includes('_') || register?.fone1 === '')
                erro = true
            if (!register?.senha)
                erro = true
            if (register?.senha !== register?.senha2)
                erro = true
            if (!validateEmail(register?.email))
                erro = true
            if (!register?.endereco)
                erro = true
            if (!register?.bairro)
                erro = true
            if (!register?.cidade)
                erro = true
        } catch (error) {

        }
        console.log('Result::', erro)
        setDisableButton(erro)
    }


    async function salvar() {
        try {
            setLoading(true)
            if (register.id > 0) {
                const response = await api.put('parceiros', register,
                    {
                        headers: {
                            'x-access-token': token,
                        }
                    })
                if (response.status === 200) {
                    message.success('Dados atualizados com sucesso!')
                    navigate('/parceiros')
                } else {
                    message.error('Não foi possível salvar os dados!')
                }
            } else {
                const response = await api.post('parceiros', register)
                if (response.status === 200) {
                    message.success('Dados salvos com sucesso!')
                    saveParceiro(response.data)
                    navigate('/parceiros')
                } else {
                    message.error('Não foi possível salvar os dados!')
                }
            }
        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401)
                    navigate('/parceiros/login')
            } else {
                message.error(error.message)
            }
        }
        setLoading(false)
    }


    return (
        <div style={{ backgroundColor: 'whitesmoke' }}>
            <NavbarParceiro />
            <div style={{ margin: 40 }}>
                <Typography.Title level={4}>Programa de Revenda</Typography.Title>
                <div>
                    <Card size="small"
                        title={<Typography.Title level={5}
                            style={{ color: 'royalblue' }} >Seus Dados</Typography.Title>}>
                        <Row gutter={[16, 8]}>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="nome">Nome</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input id="nome" name="nome" placeholder='Seu nome'
                                        value={register?.nome} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, nome: e.target.value })}
                                        status={register?.nomeErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!register?.nome)
                                                setRegister({ ...register, nomeErro: true })
                                            else if (register?.nome?.trim() === '')
                                                setRegister({ ...register, nomeErro: true })
                                            else
                                                setRegister({ ...register, nomeErro: false })
                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="nome"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="cpf">CPF</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <InputMask id="cpf" name="cpf" placeholder='CPF'
                                        mask={'999.999.999-99'}
                                        value={register?.cpf}
                                        onChange={(e) => setRegister({ ...register, cpf: e.target.value })}
                                        children={() => <Input />}>
                                    </InputMask>
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="cpf"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="email">E-mail</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input id="email" name="email" placeholder='Seu e-mail'
                                        value={register?.email} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, email: e.target.value })}
                                        status={register?.emailErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!validateEmail(register?.email))
                                                setRegister({ ...register, emailErro: true })
                                            else
                                                setRegister({ ...register, emailErro: false })
                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="sobrenome"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="fone1">Telefone</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <InputMask
                                        {...props}
                                        mask={mask}
                                        onBlur={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask("(99) 9999-9999");
                                            }
                                            if (register?.fone1 === '')
                                                setRegister({ ...register, fone1Erro: true })
                                            else
                                                setRegister({ ...register, fone1Erro: false })
                                        }}
                                        onFocus={e => {
                                            if (e.target.value.replace("_", "").length === 14) {
                                                setMask("(99) 99999-9999");
                                            }
                                        }}
                                        children={() => <Input status={register?.fone1Erro === true ? 'error' : ''} />}
                                        value={register?.fone1}
                                        onChange={(e) => setRegister({ ...register, fone1: e.target.value })}>

                                    </InputMask>
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="fone1"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>


                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="senha">Senha</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input.Password id="senha" name="senha" placeholder='Informe a senha'
                                        value={register?.senha} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, senha: e.target.value })}
                                        status={register?.senhaErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!register?.senha)
                                                setRegister({ ...register, senhaErro: true })
                                            else if (register?.senha?.trim() === '' || register?.senha !== register?.senha2)
                                                setRegister({ ...register, senhaErro: true })
                                            else
                                                setRegister({ ...register, senhaErro: false })
                                        }}
                                    />

                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="senha"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="senha2">Confirmar senha</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input.Password id="senha2" name="senha2" placeholder='Confirme a senha'
                                        value={register?.senha2}
                                        onChange={(e) => setRegister({ ...register, senha2: e.target.value })}
                                        status={register?.senhaErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (register?.senha2?.trim() === '' || register?.senha !== register?.senha2)
                                                setRegister({ ...register, senhaErro: true })
                                            else
                                                setRegister({ ...register, senhaErro: false })
                                        }} />

                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="senha2"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>



                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="fantasia">Fantasia</label>
                                </div>
                                <div>
                                    <Input id="fantasia" name="fantasia" placeholder='Nome fantasia'
                                        value={register?.fantasia} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, fantasia: e.target.value })}
                                        status={register?.fantasiaErro === true ? 'error' : ''}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="fantasia"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="razaosocial">Razão Social</label>
                                </div>
                                <div>
                                    <Input id="razaosocial" name="razaosocial" placeholder='Razão Social'
                                        value={register?.razaosocial} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, razaosocial: e.target.value })}
                                        status={register?.razaosocialErro === true ? 'error' : ''}
                                        onBlur={() => {

                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="razaosocial"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="cnpj">CNPJ</label>
                                </div>
                                <div>
                                    <InputMask id="cnpj" name="cnpj" placeholder='CNPJ'
                                        mask={'99.999.999/9999-99'}
                                        value={register?.cnpj}
                                        onChange={(e) => setRegister({ ...register, cnpj: e.target.value })}
                                        children={() => <Input />}>

                                    </InputMask>
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="cnpj"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="endereco">Endereço</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input id="endereco" name="endereco" placeholder='Endereço'
                                        value={register?.endereco} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, endereco: e.target.value })}
                                        status={register?.enderecoErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!register?.endereco)
                                                setRegister({ ...register, enderecoErro: true })
                                            else if (register?.endereco?.trim() === '')
                                                setRegister({ ...register, enderecoErro: true })
                                            else
                                                setRegister({ ...register, enderecoErro: false })
                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="endereco"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="bairro">Bairro</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input id="bairro" name="bairro" placeholder='Bairro'
                                        value={register?.bairro} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, bairro: e.target.value })}
                                        status={register?.bairroErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!register?.bairro)
                                                setRegister({ ...register, bairroErro: true })
                                            else if (register?.bairro?.trim() === '')
                                                setRegister({ ...register, bairroErro: true })
                                            else
                                                setRegister({ ...register, bairroErro: false })
                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="bairro"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>
                            <Col xs={24} lg={5}>
                                <div>
                                    <label class="control-label" for="Cidade">Cidade</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Input id="cidade" name="cidade" placeholder='Cidade'
                                        value={register?.cidade} autoComplete={'new-password'}
                                        onChange={(e) => setRegister({ ...register, cidade: e.target.value })}
                                        status={register?.cidadeErro === true ? 'error' : ''}
                                        onBlur={() => {
                                            if (!register?.cidade)
                                                setRegister({ ...register, cidadeErro: true })
                                            else if (register?.cidade?.trim() === '')
                                                setRegister({ ...register, cidadeErro: true })
                                            else
                                                setRegister({ ...register, cidadeErro: false })
                                        }}
                                    />
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="cidade"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>
                            <Col xs={24} lg={3}>
                                <div>
                                    <label class="control-label" for="uf">UF</label>
                                    <span style={{ color: 'red' }}>*</span>
                                </div>
                                <div>
                                    <Select
                                        id="uf" name="uf"
                                        defaultValue={'CE'}
                                        value={register?.uf} style={{ width: '100%' }}
                                        onChange={(value) => {
                                            setRegister({ ...register, uf: value })
                                        }}>
                                        <Option value={'AC'}>AC</Option>
                                        <Option value={'AL'}>AL</Option>
                                        <Option value={"AP"}>AP</Option>
                                        <Option value={"AM"}>AM</Option>
                                        <Option value={"BA"}>BA</Option>
                                        <Option value={"CE"}>CE</Option>
                                        <Option value={"DF"}>DF</Option>
                                        <Option value={"ES"}>ES</Option>
                                        <Option value={"GO"}>GO</Option>
                                        <Option value={"MA"}>MA</Option>
                                        <Option value={"MT"}>MT</Option>
                                        <Option value={"MS"}>MS</Option>
                                        <Option value={"MG"}>MG</Option>
                                        <Option value={"PA"}>PA</Option>
                                        <Option value={"PB"}>PB </Option>
                                        <Option value={"PR"}>PR</Option>
                                        <Option value={"PE"}>PE</Option>
                                        <Option value={"PI"}>PI</Option>
                                        <Option value={"RJ"}>RJ</Option>
                                        <Option value={"RN"}>RN</Option>
                                        <Option value={"RS"}>RS</Option>
                                        <Option value={"RO"}>RO</Option>
                                        <Option value={"RR"}>RR</Option>
                                        <Option value={"SC"}>SC</Option>
                                        <Option value={"SP"}>SP</Option>
                                        <Option value={"SE"}>SE</Option>
                                        <Option value={"TO"}>TO</Option>
                                    </Select>
                                </div>
                                <div>
                                    <span class="field-validation-valid" data-valmsg-for="cidade"
                                        data-valmsg-replace="true"></span>
                                </div>
                            </Col>

                            <Col span={24} style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 20, marginBottom: 30 }}>
                                <Space>
                                    <Button type='default' icon={<LeftOutlined />}
                                        onClick={() => {
                                            if (register?.id > 0)
                                                navigate('/parceiros')
                                            else
                                                navigate('/parceiros/login')
                                        }}>Voltar</Button>
                                    <Button type='primary' style={{ minWidth: 90 }}
                                        loading={loading}
                                        disabled={disableButton}
                                        onClick={() => salvar()}> Cadastrar </Button>
                                </Space>
                            </Col>
                            <Col span={24} style={{ textAlign: 'center', fontSize: 12 }}>
                                Ao clicar em cadastrar você concorda com os termos descritos nesse <a href='#'
                                    style={{ fontSize: 12 }}
                                    onClick={() => setVisivel(true)}>
                                    contrato
                                </a>
                            </Col>
                            <Col span={24} style={{ textAlign: 'center', fontSize: 12 }}>
                                Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM.
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <FooterCtrl />

            <Modal footer={null} width={isMobile ? '90%' : '70%'} visible={visivel} onOk={() => setVisivel(false)} onCancel={() => setVisivel(false)}
            ><div>
                    <ContratoCtrl setStatus={setStatus} setKey={setKey} props={props} />
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                        <Button onClick={() => setVisivel(false)} type='primary' > Fechar </Button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ParceiroRegistro)