import React from 'react'
import { connect } from 'react-redux'
import Navbar from '../../components/Navbar'
import FooterCtrl from '../../components/footer'
import '../../App.css'
import {  Typography, Space} from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import '../../css/fontawesome-all.min.css'



export const AtualizacoesPage = (props) => {
    return <div style={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
        <Navbar style={{ marginTop: 0 }} props={props} />


        <div style={{ display: 'flex', flexDirection: 'column', margin: 50 }}>
            <Typography.Title level={4} strong>
                Confira abaixo nossas últimas atualizações e melhorias no sistema.
            </Typography.Title>


            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Space style={{ color: 'royalblue' }}>
                    <DownloadOutlined style={{ fontSize: 30 }} />
                    <Typography.Text style={{ fontSize: 20 }}>
                        Atualização versão 12.2.3
                    </Typography.Text>
                </Space>
                <div style={{ marginLeft: 40, display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text>
                        Versão 12.2.3
                    </Typography.Text>
                    <Typography.Text>
                        Lançada em 28/11/2022
                    </Typography.Text>
                </div>

            </div>


            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Space style={{ color: 'royalblue' }}>
                    <DownloadOutlined style={{ fontSize: 30 }} />
                    <Typography.Text style={{ fontSize: 20 }}>
                        Atualização versão 12.0.8
                    </Typography.Text>
                </Space>
                <div style={{ marginLeft: 40, display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text>
                        Versão 12.0.8
                    </Typography.Text>
                    <Typography.Text>
                        Lançada em 26/04/2022
                    </Typography.Text>
                </div>

            </div>


            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Space style={{ color: 'royalblue' }}>
                    <DownloadOutlined style={{ fontSize: 30 }} />
                    <Typography.Text style={{ fontSize: 20 }}>
                        Atualização Versão 10.9
                    </Typography.Text>
                </Space>
                <div style={{ marginLeft: 40, display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text>
                        Versão 10.9
                    </Typography.Text>
                    <Typography.Text>
                        Lançada em 05/07/2019
                    </Typography.Text>
                </div>
            </div>



            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Space style={{ color: 'royalblue' }}>
                    <DownloadOutlined style={{ fontSize: 30 }} />
                    <Typography.Text style={{ fontSize: 20 }}>
                        Atualização versão 10.8
                    </Typography.Text>
                </Space>
                <div style={{ marginLeft: 40, display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text>
                        Versão 10.8
                    </Typography.Text>
                    <Typography.Text>
                        Lançada em 17/06/2019
                    </Typography.Text>
                </div>

            </div>




            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <Space style={{ color: 'royalblue' }}>
                    <DownloadOutlined style={{ fontSize: 30 }} />
                    <Typography.Text style={{ fontSize: 20 }}>
                        Atualização versão 10.7
                    </Typography.Text>
                </Space>
                <div style={{ marginLeft: 40, display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text>
                        Versão 10.7
                    </Typography.Text>
                    <Typography.Text>
                        Lançada em 26/05/2019
                    </Typography.Text>
                </div>

            </div>

        </div>

<FooterCtrl/>

    </div>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AtualizacoesPage)
