import React from 'react'
import { connect } from 'react-redux'
import  LoginCtrl  from '../../components/login'
import { FooterCtrl } from '../../components/footer'
import { Navbar } from '../../components/Navbar'
import { isDesktop } from 'react-device-detect'

export const index = (props) => {
    return (
        <div style={{
            width: '100%', display: 'flex', height: '100vh', flexDirection: 'column'
        }}>
            <Navbar/>
            <div style={{
                width: '100%', display: 'flex', justifyContent: 'center',
                alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{
                    maxWidth: isDesktop ? 400 : '100%', backgroundColor: 'whitesmoke',
                    padding: 30, borderWidth: 0.5, borderColor: 'lightgray',
                    borderStyle: 'solid', borderRadius: 5, marginBottom: isDesktop ? 30 : 0, marginTop: isDesktop ? 20 : 0
                }}>

                    <LoginCtrl />

                </div>
               
            </div>

            <FooterCtrl />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)