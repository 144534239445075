import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Space, Steps, Typography, Table, Tag, Row, Col, Input, Checkbox, message } from 'antd'
import Navbar from '../../components/Navbar'
import { CheckOutlined, DeleteOutlined, PlusOutlined, DollarOutlined } from '@ant-design/icons'
import nfce from '../../images/nfce.png'
import licencekey from '../../images/license_key.png'
import android from '../../images/android.png'
import site from '../../images/site.png'
import engfoodMobile from '../../images/engfood-mobile.png'
import iosmobile from '../../images/ios.png'
import { useNavigate, useLocation } from 'react-router-dom'
import pciPNG from '../../images/pci.png'
import mpformaspagamentoPNG from '../../images/mp-formas-pagamento.png'
import * as userAction from '../../store/actions/user.action'
import { api, BASEURL, BASEURLRAIZ } from '../../service/api'

const { Step } = Steps
const { Column } = Table

export const CompraPage = (props) => {

    const { user, token, saveUser } = props
    const [etapa, setEtapa] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([
        location.state.plano,
        { index: 1, descricao: "Emissão de Cupom Fiscal NFCe (50 reais por mês)", valor: 50 },
        { index: 2, descricao: 'Plataforma Própria de Delivery (Site de Pedidos + App Android)', valor: 0 },
        { index: 3, descricao: 'Engfood Mobile (Garçom e QRCode)', valor: 0 },
        { index: 4, descricao: 'App iOS (Necessário conta Apple Developder)', valor: 0 },

    ])

    useEffect(() => {
        if (!location.state.plano)
            navigate('/planos')
    }, [])


    function getFiscal(meses) {
        var plano = {
            id: 1,
            descricao: "Fiscal NFCe 50 reais por mês",
            meses: 1,
            valor: 50,
            code: 'F1',
            nome: 'Fiscal NFCe',
            dias: 30
        }
        switch (meses) {
            case 1:
                plano = {
                    id: 1,
                    descricao: "Fiscal NFCe 50 reais por mês",
                    meses: 1,
                    valor: 50,
                    code: 'F1',
                    nome: 'Fiscal NFCe',
                    dias: 30
                }
                break;
            case 3:
                plano = {
                    id: 6,
                    descricao: "Fiscal NFCe 50 reais por mês",
                    meses: 3,
                    valor: 150,
                    code: 'F3',
                    nome: 'Fiscal NFCe',
                    dias: 90
                }
                break;
            case 6:
                plano = {
                    id: 7,
                    descricao: "Fiscal NFCe 50 reais por mês",
                    meses: 6,
                    valor: 300,
                    code: 'F6',
                    nome: 'Fiscal NFCe',
                    dias: 180
                }
                break;
            case 12:
                plano = {
                    id: 12,
                    descricao: "Fiscal NFCe 50 reais por mês",
                    meses: 12,
                    valor: 600,
                    code: 'F12',
                    nome: 'Fiscal NFCe',
                    dias: 365
                }
                break;

        }
        return plano
    }


    async function sendPedido() {
        try {
            setLoading(true)
            // console.log('Iniciando')
            var pedido = JSON.parse(JSON.stringify(user))
            //  console.log('Iniciando 2')
            pedido.itens = [JSON.parse(JSON.stringify(location.state.plano))]
            // console.log('Iniciando 3')
            if (data[1].selecionado === true)
                pedido.itens.push(getFiscal(data[0].meses))
            const result = await api.post('/user/pedido', pedido, {
                headers: {
                    'x-access-token': token,
                }
            })
            // console.log('result.data', result.data)
            var aux = JSON.parse(JSON.stringify(result.data))
            var url = "https://menuengfood.com.br/notificationsite/init?external_reference=".concat(aux.id)
            //navigate(`${url}`)
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401) {
                    saveUser(null)
                    navigate('/')
                }
            } else {
                message.error(error.message)
            }
        }
        setLoading(false)
    }


    function renderStep() {
        switch (etapa) {
            case 0:
                return <div>
                    <Table
                        pagination={false}
                        size="small"
                        dataSource={data}
                        footer={() => {
                            var total = data[0].valor + (data[1].selecionado === true ? 50 * data[0].meses : 0)
                            return <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 16, fontWeight: 'bold' }}>
                                <Space>
                                    <span>TOTAL</span>
                                    <span style={{ marginLeft: 50 }}>{"R$ ".concat(total.toFixed(2))}</span>
                                </Space>
                            </div>
                        }}>
                        <Column
                            title={<strong>Descrição</strong>}
                            key={"descicao"}
                            dataIndex={'descricao'}
                            render={(text, record, index) => {
                                switch (record.index) {
                                    case 0:
                                        return <Space>
                                            <img src={licencekey} alt="icon" height={30} />
                                            {text}
                                        </Space>
                                    case 1:
                                        return <Space>
                                            <img src={nfce} alt="icon" height={25} />
                                            {text}
                                        </Space>
                                    case 2:
                                        return <Space>
                                            <img src={site} alt="icon" height={25} />
                                            <img src={android} alt="icon" height={25} />
                                            {text}
                                        </Space>
                                    case 3:
                                        return <Space>
                                            <img src={site} alt="icon" height={25} />
                                            <img src={engfoodMobile} alt="icon" height={25} />
                                            {text}
                                        </Space>

                                    case 4:
                                        return <Space>
                                            <img src={iosmobile} alt="icon" height={25} />
                                            {text} *
                                        </Space>

                                    default:
                                        return text

                                }
                            }} />

                        <Column
                            width={130}
                            title={<strong>Valor</strong>}
                            key={"index"}
                            dataIndex={'valor'}
                            render={(text, record, index) => {
                                if (record.index === 0)
                                    return <Space style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}> {"R$ ".concat(parseFloat(text).toFixed(2))} </Space>
                                else if (record.index === 1) {
                                    if (record.selecionado === true) {
                                        return <Space style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            {"".concat(data[0].meses, " x ", parseFloat(text).toFixed(2))}
                                            <Button type='text' size='small' icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    var item = data[record.index]
                                                    item.selecionado = !item.selecionado
                                                    data[record.index] = item
                                                    setData([...data])
                                                }} />
                                        </Space>
                                    }
                                    else
                                        return <Button style={{ borderRadius: 5 }} type='primary' size='small'
                                            icon={<PlusOutlined style={{ height: 20 }} />}
                                            onClick={() => {
                                                var item = data[record.index]
                                                item.selecionado = !item.selecionado
                                                data[record.index] = item
                                                setData([...data])
                                            }}>Adicionar</Button>
                                } else
                                    return text === 0 ? <Space style={{ display: 'flex', justifyContent: 'center' }}> <Tag color={'volcano'}
                                        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>grátis</Tag> </Space> :
                                        <Space style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}> "R$ ".concat(parseFloat(text).toFixed(2)) </Space>
                            }} />
                    </Table>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Space>
                            <Button type='default' onClick={() => navigate(-1)}>Voltar</Button>
                            <Button type='primary' onClick={() => setEtapa(1)}>Avançar</Button>
                        </Space>

                    </div>

                </div>
            case 1:
                return <div>
                    <div style={{ display: 'flex', marginTop: 20 }}>

                        <Row gutter={[16, 16]} style={{ width: '100%' }}>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="fantasia">Nome Fantasia</label>
                                </div>
                                <div>
                                    <Input id="fantasia" name="fantasia" value={user?.fantasia} disabled />
                                </div>
                            </Col>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="razao">Razão Social</label>
                                </div>
                                <div>
                                    <Input id="razao" name="razao" value={user?.razao_social} disabled></Input>
                                </div>
                            </Col>
                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="cnpj">CNPJ</label>
                                </div>
                                <div>
                                    <Input id="cnpj" name="cnpj" value={user?.cnpj} disabled></Input>
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="fone1">Telefone 1</label>
                                </div>
                                <div>
                                    <Input id="fone1" name="fone1" value={user?.fone1} disabled />
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="fone2">Telefone 2</label>
                                </div>
                                <div>
                                    <Input id="fone2" name="fone2" value={user?.fone2} disabled />
                                </div>
                            </Col>

                            <Col xs={24} lg={8}>
                                <div>
                                    <label class="control-label" for="email">{"Token(e-mail)"}</label>
                                </div>
                                <div>
                                    <Input id="email" name="email" value={user?.tok_email} disabled />
                                </div>
                            </Col>

                            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Space>
                                    <Button type='default' onClick={() => setEtapa(0)}>Voltar</Button>
                                    <Button type='primary' onClick={() => setEtapa(2)}>Avançar</Button>
                                </Space>
                            </Col>
                        </Row>

                    </div>
                </div>
            case 2:
                return <div>
                    <Row gutter={[32, 16]}>

                        <Col xs={24}>
                            <Row>
                                <Col span={24}>
                                    <Checkbox checked={true}>
                                        <strong> Estou ciente e de acordo com os prazos de liberação </strong>
                                    </Checkbox>
                                </Col>
                                <Col span={24} >
                                    <span style={{ fontSize: 12, marginLeft: 10 }}>
                                        - Boleto bancário: Até 2 dias úteis (Não é necessário enviar o comprovante).
                                    </span>
                                </Col>
                                <Col span={24}>
                                    <span style={{ fontSize: 12, marginLeft: 10 }}>
                                        - Cartão de Crédito/Débito: Imediata ou em algumas horas após aprovação.
                                    </span>
                                </Col>
                            </Row>

                        </Col>
                        <Col style={{ marginTop: 20 }} span={24}></Col>

                        <Col xs={24} lg={12}>
                            <strong>
                                Escolha como quer pagar e ainda parcele sua compra em até 12x.
                            </strong>
                            <br />
                            <span style={{ fontSize: 12, marginLeft: 10 }}>
                                Aceitamos varias opções de cartões para você pagar sua compra.
                            </span>
                            <br />
                            <img src={mpformaspagamentoPNG} alt="formas de pagemnto" />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Space>
                                <img src={pciPNG} alt="pci" />
                                O Mercadopago possui certificado de segurança PCI
                            </Space>
                            <Space style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                <Button type='default' onClick={() => setEtapa(1)}>Voltar</Button>
                                <Button type='primary'
                                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                                    icon={<DollarOutlined style={{ fontSize: 20 }} />}
                                    loading={loading}
                                    onClick={() => {
                                        sendPedido()
                                        // navigate('https://programaengfood.com/notificationsite/init?external_reference='.concat())
                                    }}>Ir para Pagamento</Button>
                            </Space>
                        </Col>
                        <Col span={24} style={{ fontSize: 12 }}>
                            {"Ao clicar em pagar você concorda com o "}
                            <a href="https://www.programaengfood.com.br/downloads/contrato-de-licenca-de-uso.pdf" target="_blank">
                                Contrato de Termos de Uso </a>
                            {" do Programa Engfood."}
                        </Col>


                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        </Col>
                    </Row>
                </div>
        }
    }


    return <div>
        <Navbar />
        <section>

            <div style={{ backgroundColor: 'royalblue', height: 50, display: 'flex', alignItems: 'center' }}>
                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    marginLeft: 20
                }}>
                    <CheckOutlined style={{ fontSize: 25, color: 'white' }} />
                    <span style={{ color: 'white', marginLeft: 10 }}>CONTRATAÇÃO</span>
                </div>

            </div>

            <div style={{ margin: 40 }}>

                <Steps current={etapa}>
                    <Step title="Sua Compra" />
                    <Step title="Seus Dados" />
                    <Step title="Finalizar" />
                </Steps>
            </div>
            <div style={{ marginLeft: 40, marginRight: 40 }}>
                {
                    renderStep()
                }
            </div>
        </section>

    </div>

}

const mapStateToProps = (state) => ({
    user: state.userReducer.user.user,
    token: state.userReducer.user.token,
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompraPage)