import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as parceiroAction from '../../store/actions/parceiro.action'
import { Row, Col, Button, Input, Select, message, Modal } from 'antd'
import InputMask from 'react-input-mask'
import { api } from '../../service/api'
import { validateEmail } from '../../service/util'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'

const { Option } = Select

export const DadosCtrl = (props) => {

  const navigate = useNavigate()
  const { parceiro, token, saveParceiro } = props
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(Object.assign({}, parceiro))
  const [diableButton, setDisableButton] = useState(true)
  const [modalSenha, setModalSenha] = useState(false)

  useEffect(() => {
    //console.log('Parceiro: ', user)
    validarForm()
    //  validarSenha()
  }, [user])

  function validarForm() {
    var erro = false
    // console.log('register?.senha !== register?.senha2', register?.senha !== register?.senha2)
    try {
      if (!user)
        erro = true
      if (!user)
        erro = true
      if (!user?.nome)
        erro = true
      if (user?.cpf?.includes('_') || user?.cpf === '')
        erro = true
      if (user?.fone1?.includes('_') || user?.fone1 === '')
        erro = true
      if (!validateEmail(user?.email))
        erro = true
      if (!user?.endereco)
        erro = true
      if (!user?.bairro)
        erro = true
      if (!user?.cidade)
        erro = true
    } catch (error) {

    }
    //  console.log('Result::', erro)
    setDisableButton(erro)
  }

  async function salvar() {
    try {
      setLoading(true)
      user.clientes = []
      const response = await api.put('parceiros', user,
        {
          headers: {
            'x-access-token': token,
          }
        })
      message.success('Dados atualizados com sucesso!')
      saveParceiro(response.data)

    } catch (error) {
      console.log(error.message)
      if (error?.response) {
        message.error(error?.response.statusText)
        if (error?.response.status === 401)
          navigate('/parceiros/login')
      } else {
        console.log(error.message)
        message.error(error.message)
      }
    }
    setLoading(false)
  }

  function validarSenha() {
    if (user?.pwd?.trim() === '' || user?.pwd !== user?.pwd2)
      setUser({ ...user, pwdErro: true })
    else
      setUser({ ...user, pwdErro: false })
  }


  async function updateSenha() {
    try {
      setLoading(true)
      user.clientes = []
      const response = await api.put('parceiros/pwd', { id: parceiro.id, senha: user.pwd },
        {
          headers: {
            'x-access-token': token,
          }
        })
      if (response.status === 200) {
        message.success('Dados atualizados com sucesso!')
        setModalSenha(false)
        //  saveParceiro(response.data)
      } else {
        message.error('Não foi possível salvar os dados!')
      }
    } catch (error) {
      console.log(error.message)
      if (error?.response) {
        message.error(error?.response.statusText)
        if (error?.response.status === 401)
          navigate('/parceiros/login')
      } else {
        console.log(error.message)
        message.error(error.message)
      }
    }
    setLoading(false)
  }


  return (
    <div style={{ fontSize: 14 }}>
      <Row gutter={[4, 2]}>
        <Col span={24} style={{ marginTop: 10, marginBottom: 10, color: 'royalblue' }}>
          <strong>DADOS PARA CONTATO</strong></Col>
        <Col xs={24} lg={4}>
          Nome <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.nome} onChange={(e) => setUser({ ...user, nome: e.target.value })}
            maxLength={255} />
        </Col>

        <Col xs={24} lg={4}>
          E-mail <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.email} disabled={true} maxLength={255} />
        </Col>
        <Col xs={24} lg={4}>
          CPF <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <InputMask id="cpf" name="cpf" placeholder='CPF'
            mask={'999.999.999-99'}
            value={user?.cpf}
            onChange={(e) => setUser({ ...user, cpf: e.target.value })}
            children={() => <Input />}>
          </InputMask>
        </Col>
        <Col xs={24} lg={4}>
          Celular <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <InputMask id="fone1" name="fone1" placeholder='Celular'
            mask={'99 99999-9999'}
            value={user?.fone1}
            onChange={(e) => setUser({ ...user, fone1: e.target.value })}
            children={() => <Input />}>
          </InputMask>
        </Col>

        <Col span={24} style={{ marginTop: 10, marginBottom: 10, color: 'royalblue' }}><strong>DADOS COMERCIAIS</strong></Col>

        <Col xs={24} lg={4}>
          Fantasia:
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.fantasia} onChange={(e) => setUser({ ...user, fantasia: e.target.value })} maxLength={150} />
        </Col>
        <Col xs={24} lg={4}>
          Razão Social:
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.razaosocial} onChange={(e) => setUser({ ...user, razaosocial: e.target.value })} maxLength={150} />
        </Col>
        <Col xs={24} lg={4}>
          CNPJ:
        </Col>
        <Col xs={24} lg={20}>
          <InputMask id="cnpj" name="cnpj" placeholder='CNPJ'
            mask={'99.999.999/9999-99'}
            value={user?.cnpj}
            onChange={(e) => setUser({ ...user, cnpj: e.target.value })}
            children={() => <Input />}>
          </InputMask>
        </Col>

        <Col xs={24} lg={4}>
          Endereço <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.endereco} onChange={(e) => setUser({ ...user, endereco: e.target.value })} maxLength={255} />
        </Col>
        <Col xs={24} lg={4}>
          Bairro <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.bairro} onChange={(e) => setUser({ ...user, bairro: e.target.value })} maxLength={85} />
        </Col>
        <Col xs={24} lg={4}>
          Cidade <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Input value={user?.cidade} onChange={(e) => setUser({ ...user, cidade: e.target.value })} maxLength={85} />
        </Col>
        <Col xs={24} lg={4}>
          Estado <span style={{ color: 'red' }}>*</span>
        </Col>
        <Col xs={24} lg={20}>
          <Select
            id="uf" name="uf"
            defaultValue={'CE'}
            value={user?.uf} style={{ width: '100%' }}
            onChange={(value) => {
              setUser({ ...user, uf: value })
            }}>
            <Option value={'AC'}>AC</Option>
            <Option value={'AL'}>AL</Option>
            <Option value={"AP"}>AP</Option>
            <Option value={"AM"}>AM</Option>
            <Option value={"BA"}>BA</Option>
            <Option value={"CE"}>CE</Option>
            <Option value={"DF"}>DF</Option>
            <Option value={"ES"}>ES</Option>
            <Option value={"GO"}>GO</Option>
            <Option value={"MA"}>MA</Option>
            <Option value={"MT"}>MT</Option>
            <Option value={"MS"}>MS</Option>
            <Option value={"MG"}>MG</Option>
            <Option value={"PA"}>PA</Option>
            <Option value={"PB"}>PB </Option>
            <Option value={"PR"}>PR</Option>
            <Option value={"PE"}>PE</Option>
            <Option value={"PI"}>PI</Option>
            <Option value={"RJ"}>RJ</Option>
            <Option value={"RN"}>RN</Option>
            <Option value={"RS"}>RS</Option>
            <Option value={"RO"}>RO</Option>
            <Option value={"RR"}>RR</Option>
            <Option value={"SC"}>SC</Option>
            <Option value={"SP"}>SP</Option>
            <Option value={"SE"}>SE</Option>
            <Option value={"TO"}>TO</Option>
          </Select>
        </Col>
        <Col span={24}>
          <div style={{
            margin: 20, marginRight: 0,
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>
            <Button type='link'
              style={{ width: 120, marginLeft: isDesktop ? 200 : 20 }}
              onClick={() => setModalSenha(true)}> Alterar Senha </Button>
            <Button type='primary'
              style={{ width: 120 }}
              onClick={() => salvar()} loading={loading} disabled={diableButton}> Salvar </Button>
          </div>
        </Col>


      </Row>

      <Modal title="Alteração de Senha" visible={modalSenha} footer={() => null} >
        <Row gutter={[8, 8]}>
          <Col span={24}>Nova senha</Col>
          <Col span={24}>
            <Input.Password id="senha" name="senha" placeholder='Nova senha'
              value={user?.pwd}
              onChange={(e) => setUser({ ...user, pwd: e.target.value })}
              status={user?.pwdErro === true ? 'error' : ''}
              onBlur={() => {
                if (user?.pwd?.trim() === '' || user?.pwd !== user?.pwd2)
                  setUser({ ...user, pwdErro: true })
                else
                  setUser({ ...user, pwdErro: false })
              }} />
          </Col>
          <Col span={24}>Confirmar senha</Col>
          <Col span={24}>
            <Input.Password id="senha2" name="senha2" placeholder='Confirme a senha'
              value={user?.pwd2}
              onChange={(e) => setUser({ ...user, pwd2: e.target.value })}
              status={user?.pwdErro === true ? 'error' : ''}
              onBlur={() => {
                if (user?.pwd?.trim() === '' || user?.pwd !== user?.pwd2)
                  setUser({ ...user, pwdErro: true })
                else
                  setUser({ ...user, pwdErro: false })
              }} />
          </Col>
          <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <Button type='default' onClick={() => setModalSenha(false)}>Cancelar</Button>

            <Button type='primary'
              loading={loading}
              disabled={user?.pwdErro === undefined || user?.pwdErro === true}
              onClick={() => {
                updateSenha()
              }}> Salvar </Button>
          </Col>
        </Row>

      </Modal>


    </div>
  )
}

const mapStateToProps = (state) => ({
  parceiro: state.parceiroReducer.parceiro.parceiro,
  token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
  saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DadosCtrl)