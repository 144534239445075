import { Action_Parceiro_Types } from '../actions/parceiro.action'

const initialState = {
    parceiro: null

}

const parceiroReducer = (state = initialState, action) => {

    switch (action.type) {

        case Action_Parceiro_Types.SAVE_PARCEIRO:
            return {
                ...state,
                parceiro: action.parceiro
            }

        default:
            return state;
    }
}


export default parceiroReducer;