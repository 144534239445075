import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Navbar from '../../components/Navbar'
import FooterCtrl from '../../components/footer'
import '../../App.css'
import { Card, Row, Col, Typography, Space, Button, Divider, Modal, Alert, message, Steps, Drawer } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import '../../css/fontawesome-all.min.css'
import appgarcomPNG from '../../images/appgarcom.png'
import appdeliveryPNG from '../../images/appdelivery2.png'
import appqrcodePNG from '../../images/appqrcode.png'
import LoginCtrl from '../../components/login'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect';

const { Meta } = Card;
const { Step } = Steps

export const PlanosPage = (props) => {

  const navigate = useNavigate()
  const { user } = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCompra, setIsModalVisibleCompra] = useState(false)
  const [etapa, setEtapa] = useState(1)

  return <div style={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
    <Navbar style={{ marginTop: 0 }} props={props} />


    {/* PLANOS */}
    <section>
    </section>
    <section className="pricing-table" id='secprincing' style={{ marginTop: 0 }}>
      <div>

        <div className="block-heading">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h4 style={{ fontSize: '1.3em' }}><span style={{ color: 'black', fontWeight: 'bold' }}>PLANOS E PREÇOS</span></h4>
          </div>
          <p style={{ display: 'flex', fontSize: 'medium', color: 'black', fontWeight: 500, justifyContent: 'center' }}>
            Sem taxa de instalação, sem contrato de fidelidade e ativação imediata
          </p>
        </div>
        {
          /*
          isDesktop ? <img src={require('../../images/promotion/black-friday_.png')}
            alt="black" style={{ width: '100%' }} /> :
            <img src={require('../../images/promotion/black-friday-mobile_.png')}
              alt="black" style={{ width: '100%' }} />
              */
        }
      </div>


      {/* MENSAL */}
      <Row style={{ marginBottom: '20px', justifyContent: 'space-around' }}>
        <Col md={12} lg={6} xs={24}>
          <div className="item">
            <div className="heading">
              <h3>MENSAL</h3>
            </div>
            <div className="features">
              <br />
              <div className='div-princing-1'>
                <div className='div-princing-2'>
                  <span className="span-princing-1">R$</span>
                  <span className="span-princing-2">100</span>
                  <span className='span-princing-3'>,00</span>
                </div>
              </div>
              <hr />
              <ul style={{ fontSize: 13, color: 'darkgray' }}>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Suporte via acesso remoto, e-mail, telefone e whatshap."
                    template='<div class="tooltip" style="width:150px; color:orange" role="tooltip"><div style="width:150px; color:orange" class="tooltip-arrow"></div><div class="tooltip-inner" style="width:150px; color:orange"></div></div>'>
                  </span>
                  Suporte
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Registro ilimitado de pedidos sem pagar adicionais">
                  </span>Pedidos ilimitados
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Utilize em quantos computadores precisar dentro da mesma rede sem custos adicionais."></span>Sem limite de estações
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Tenha um app de delivery próprio, sem custos adicionais e fortaleça sua marca."></span>App delivery (Android e iOS*)
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Receba pedidos delivery direto do seu cardápio na internet sem que seu cliente precise instalar nenhum app."></span>Site de pedidos
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Garçom pode efetuar pedidos de mesas e comandas por smartphone ou tablet."></span>Engfood  Mobile
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Acesse as informações do seu negocio de qualquer lugar."></span>Manager
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Sem retrabalhos, receba os pedidos do iFood direto no sistema Engfood."></span>Integração iFood
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle financeiro total, com sistema de plano de contas."></span>Gerenciamento Financeiro
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle completo com ficha técnica de produtos"></span>Controle de estoque
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Crie campanhas de pontuação e fidelizando seus clientes."></span>Campanha de Fidelidade
                </li>
                <li><span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"></span>E muito mais...</li>
              </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size='large' shape='round' type='primary'
                onClick={() => {
                  if (user) {
                    navigate('/loja', {
                      state: {
                        plano: { index: 0, descricao: "Plano Mensal", meses: 1, valor: 100.00, code: 'P1', nome: 'Licenca Mensal', dias: 30, id: 1 }
                      }
                    })
                  } else {
                    message.info("Antes de efetuar a compra faça login ou crie seu Token Engfood!", 6)
                    setIsModalVisible(true)
                  }
                }}>Comprar</Button>
            </div>
            <br />
          </div>
        </Col>

        {/* TRIMESTARL */}
        <Col md={12} lg={6} xs={24}>
          <div className="item">
            <div className="heading">
              <h3>TRIMESTRAL</h3>
            </div>
            <div className="features">
              <br />
              <div className='div-princing-1'>
                <div className='div-princing-2'>
                  <div>
                    3x de
                  </div>
                  <span className="span-princing-1">R$</span>
                  <span className="span-princing-2">87</span>
                  <span className='span-princing-3'>,90</span>
                </div>
              </div>
              <hr />
              <ul style={{ fontSize: 13, color: 'darkgray' }}>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Suporte via acesso remoto, e-mail, telefone e whatshap."
                    template='<div class="tooltip" style="width:150px; color:orange" role="tooltip"><div style="width:150px; color:orange" class="tooltip-arrow"></div><div class="tooltip-inner" style="width:150px; color:orange"></div></div>'>
                  </span>
                  Suporte
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Registro ilimitado de pedidos sem pagar adicionais">
                  </span>Pedidos ilimitados
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Utilize em quantos computadores precisar dentro da mesma rede sem custos adicionais."></span>Sem limite de estações
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Tenha um app de delivery próprio, sem custos adicionais e fortaleça sua marca."></span>App delivery (Android e iOS*)
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Receba pedidos delivery direto do seu cardápio na internet sem que seu cliente precise instalar nenhum app."></span>Site de pedidos
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Garçom pode efetuar pedidos de mesas e comandas por smartphone ou tablet."></span>Engfood  Mobile
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Acesse as informações do seu negocio de qualquer lugar."></span>Manager
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Sem retrabalhos, receba os pedidos do iFood direto no sistema Engfood."></span>Integração iFood
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle financeiro total, com sistema de plano de contas."></span>Gerenciamento Financeiro
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle completo com ficha técnica de produtos"></span>Controle de estoque
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Crie campanhas de pontuação e fidelizando seus clientes."></span>Campanha de Fidelidade
                </li>
                <li><span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"></span>E muito mais...</li>
              </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size='large' shape='round' type='primary'
                onClick={() => {
                  if (user) {
                    navigate('/loja', {
                      state: {
                        plano: { index: 0, descricao: "Plano Trimestral", meses: 3, valor: 263.70, code: 'P3', nome: 'Licenca Trimestral', dias: 90, id: 2 }
                      }
                    })
                  } else {
                    message.info("Antes de efetuar a compra faça login ou crie seu Token Engfood!", 6)
                    setIsModalVisible(true)
                  }
                }}>Comprar</Button>
            </div>
            <br />
          </div>
        </Col>

        {/* SEMESTRAL */}
        <Col md={12} lg={6} xs={24}>
          <div className="item">
            <div className="heading">
              <h3>SEMESTRAL</h3>
            </div>
            <div className="features">
              <br />
              <div className='div-princing-1'>
                <div className='div-princing-2'>
                  <div>
                    6x de
                  </div>
                  <span className="span-princing-1">R$</span>
                  <span className="span-princing-2">69</span>
                  <span className='span-princing-3'>,90</span>
                </div>
              </div>
              <hr />
              <ul style={{ fontSize: 13, color: 'darkgray' }}>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Suporte via acesso remoto, e-mail, telefone e whatshap."
                    template='<div class="tooltip" style="width:150px; color:orange" role="tooltip"><div style="width:150px; color:orange" class="tooltip-arrow"></div><div class="tooltip-inner" style="width:150px; color:orange"></div></div>'>
                  </span>
                  Suporte
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Registro ilimitado de pedidos sem pagar adicionais">
                  </span>Pedidos ilimitados
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Utilize em quantos computadores precisar dentro da mesma rede sem custos adicionais."></span>Sem limite de estações
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Tenha um app de delivery próprio, sem custos adicionais e fortaleça sua marca."></span>App delivery (Android e iOS*)
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Receba pedidos delivery direto do seu cardápio na internet sem que seu cliente precise instalar nenhum app."></span>Site de pedidos
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Garçom pode efetuar pedidos de mesas e comandas por smartphone ou tablet."></span>Engfood  Mobile
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Acesse as informações do seu negocio de qualquer lugar."></span>Manager
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Sem retrabalhos, receba os pedidos do iFood direto no sistema Engfood."></span>Integração iFood
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle financeiro total, com sistema de plano de contas."></span>Gerenciamento Financeiro
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle completo com ficha técnica de produtos"></span>Controle de estoque
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Crie campanhas de pontuação e fidelizando seus clientes."></span>Campanha de Fidelidade
                </li>
                <li><span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"></span>E muito mais...</li>
              </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size='large' shape='round' type='primary'
                onClick={() => {
                  if (user) {
                    navigate('/loja', {
                      state: {
                        plano: { index: 0, descricao: "Plano Semestral", meses: 6, valor: 419.40, code: 'P6', nome: 'Licenca Semestral', dias: 180, id: 3 }
                      }
                    })
                  } else {
                    message.info("Antes de efetuar a compra faça login ou crie seu Token Engfood!", 6)
                    setIsModalVisible(true)
                  }
                }}>Comprar</Button>
            </div>
            <br />
          </div>
        </Col>


        {/* ANUAL */}
        <Col md={12} lg={6} xs={24}>
          <div className="item">
            <div class="ribbon">Mais Vendido</div>
            <div className="heading">
              <h3>ANUAL</h3>
            </div>
            <div className="features">
              <br />
              <div className='div-princing-1'>
                <div className='div-princing-2'>
                  <div>
                    12x de
                  </div>
                  <span className="span-princing-1">R$</span>
                  <span className="span-princing-2">59</span>
                  <span className='span-princing-3'>,90</span>
                </div>
              </div>
              <hr />
              <ul style={{ fontSize: 13, color: 'darkgray' }}>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Suporte via acesso remoto, e-mail, telefone e whatshap."
                    template='<div class="tooltip" style="width:150px; color:orange" role="tooltip"><div style="width:150px; color:orange" class="tooltip-arrow"></div><div class="tooltip-inner" style="width:150px; color:orange"></div></div>'>
                  </span>
                  Suporte
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Registro ilimitado de pedidos sem pagar adicionais">
                  </span>Pedidos ilimitados
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Utilize em quantos computadores precisar dentro da mesma rede sem custos adicionais."></span>Sem limite de estações
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Tenha um app de delivery próprio, sem custos adicionais e fortaleça sua marca."></span>App delivery (Android e iOS*)
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Receba pedidos delivery direto do seu cardápio na internet sem que seu cliente precise instalar nenhum app."></span>Site de pedidos
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Garçom pode efetuar pedidos de mesas e comandas por smartphone ou tablet."></span>Engfood  Mobile
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Acesse as informações do seu negocio de qualquer lugar."></span>Manager
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Sem retrabalhos, receba os pedidos do iFood direto no sistema Engfood."></span>Integração iFood
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle financeiro total, com sistema de plano de contas."></span>Gerenciamento Financeiro
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Controle completo com ficha técnica de produtos"></span>Controle de estoque
                </li>
                <li>
                  <span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"
                    data-toggle="tooltip" data-placement="left" title="Crie campanhas de pontuação e fidelizando seus clientes."></span>Campanha de Fidelidade
                </li>
                <li><span class="glyphicon glyphicon-info-sign m-r-5" aria-hidden="true"></span>E muito mais...</li>
              </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size='large' shape='round' type='primary'
                onClick={() => {
                  if (user) {
                    navigate('/loja', {
                      state: {
                        plano: { index: 0, descricao: "Plano Anual", meses: 12, valor: 718.80, code: 'P12', nome: 'Licenca Anual', dias: 365, id: 4 }
                      }
                    })
                  } else {
                    message.info("Antes de efetuar a compra faça login ou crie seu Token Engfood!", 6)
                    setIsModalVisible(true)
                  }
                }}>Comprar</Button>
            </div>
            <br />
          </div>
        </Col>
      </Row>
    </section>

    <section className='section2'>
      <div style={{ margin: 20 }}>
        <div style={{ marginTop: 40, marginBottom: 20, display: 'flex', justifyContent: 'center' }} span={24}>
          <Typography.Text level={3} style={{ fontSize: '1.3em' }}>
            <strong>JÁ INCLUSO, EM QUALQUER PLANO </strong>
          </Typography.Text>
        </div>


        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="app garçom" src={appgarcomPNG} />}>
              <Meta title="Engfood Mobile (Garçom)"
                description="Engfood Mobile para que seu Garçom efetue pedidos direto pelo smartphone ou tablet gerando mais agilidade no atendimento de seus clientes." />
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="Delivery" src={appdeliveryPNG} />}>
              <Meta title="Plataforma Delivery"
                description="Site e aplicativos exclusivos de delivery Android e iOS*. Para que dividir os clientes se você pode ter um aplicativo exclusivo seu." />
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="qrcode" src={appqrcodePNG} />}>
              <Meta title="Cardápio QRCode" description="Auto atendimento para seus clientes fazerem os pedidos na mesa sem o garçom, tudo integrado ao seu Engfood e enviados para impressoras e setores de produção." />
            </Card>
          </Col>
        </Row>
      </div>
    </section>


    <section>
      <div style={{ marginTop: 50 }}>
        <div className="block-heading">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h4 style={{ fontSize: '1.3em' }}><span style={{ color: 'black', fontWeight: 'bold' }}>COMPARE E COMPROVE</span> </h4>
          </div>
          <p style={{ display: 'flex', fontSize: 'medium', color: 'black', fontWeight: 500, justifyContent: 'center' }}>
            Módulos sem Custos Adicionais
          </p>
        </div>
        <Row style={{ margin: 40 }}>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <InfoCircleFilled style={{ fontSize: 24, color: 'royalblue' }} />
            <Typography.Text style={{ margin: 10, fontSize: 14 }}> No Programa Engfood você não tem custos adicionais para habilitar o cardápio QR Code, Manager, App de delivery ou mobile.
            </Typography.Text> </Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <InfoCircleFilled style={{ fontSize: 24, color: 'royalblue' }} />
            <Typography.Text style={{ margin: 10, fontSize: 14 }}>Utilize em quantos computadores, smartphone ou tablet´s precisar sem pagar nada a mais por isso .
            </Typography.Text></Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <InfoCircleFilled style={{ fontSize: 24, color: 'royalblue' }} />
            <Typography.Text style={{ margin: 10, fontSize: 14 }}> Qualquer plano adquirido dá acesso a todas as funcionalidades sem pagar nada a mais por isso exceto a emissão de cupom fiscal (NFCe, SAT ou MFE).
            </Typography.Text></Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col xs={24} md={24} lg={24}
            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: 13 }}>
            * A emissão de Cupom Fiscal é feita em parceria com a TOTVS e possui custo adicional.<br />
            * Taxa de instalação grátis
          </Col>
        </Row>
      </div>
    </section>
    <FooterCtrl />

    <Modal
      title={null}
      closable={true}
      footer={null}
      width={400}
      visible={isModalVisible}
      destroyOnClose={true}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}>
      <LoginCtrl setIsModalVisibleLogin={setIsModalVisible} />
    </Modal>


  </div>
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => ({
  // saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanosPage)
