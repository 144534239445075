import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import './App.css';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserRouter, RouterProvider, Outlet,
  useNavigate
} from "react-router-dom";
//import 'antd/dist/antd.css';
import { Button, Result } from 'antd';

import Home from './pages';
import PlanosPage from './pages/planos'
import PoliticaPrivacidade from './pages/politica_privacidade'

import AtualizacoesPage from './pages/atualizacoes'

import AccountPage from './pages/account'
import RegisterPage from './pages/register'
import CompraPage from './pages/compra'
import FailurePage from './pages/compra/failure'
import PendingPage from './pages/compra/pending'
import ConclusaoPage from './pages/compra/conclusao'

//Manager
import ManagerPage from './pages/account/manager';

//Parceiros
import LoginParceirosPage from './pages/parceiros/login'
import DashboardPage from './pages/parceiros/dashboard';
import ParceiroRegistro from './pages/parceiros/register'
import ParceiroRegistroCliente from './pages/parceiros/registerCliente'
import ParceiroRevenda from './pages/parceiros/revenda'

import ParceirosOutlet from './pages/parceiros/parceirosOutlet';
import ParceiroClientes from './pages/parceiros/clientes'
import ParceiroCliente from './pages/parceiros/cliente'
import ParceiroDados from './pages/parceiros/dados'
import ContratoPage from './pages/parceiros/contrato'
import ParceiroTransacoes from './pages/parceiros/transacoes';
import ParceiroTokens from './pages/parceiros/tokens';
import ParceiroRevendedores from './pages/parceiros/revendedores';
import ParceiroPagamentos from './pages/parceiros/pagamentos';
import ParceiroDownloads from './pages/parceiros/downloads';
import ParceiroPedidosMenuengfood from './pages/parceiros/pedidosMenuengfood';

//Manager
import ManagerOutlet from './pages/manager/managerOutlet'
import DashboardManager from './pages/manager/dashboard'

//Manual
import ManualPage from './pages/manual'
import ManualAppSiteDeliveryPage from './pages/manual/meuAppDelivery'
import ManualAppgarcom from './pages/manual/appGarcom'
import ManualEngfoodMobile from './pages/manual/engfoodMobile'
import ManualIntegracaoIfood from './pages/manual/integracaoIfood'
import ManualImpressoraGeneric from './pages/manual/instalarImpressoraGeneric'
import ManualSistemaRede from './pages/manual/sistemaEmRede'

import Authorization from './pages/authorization/index'
import authorizationcodeJs from './pages/authorizationcode.js';

import GA4React, { useGA4React } from "ga-4-react";
import Login from './pages/login';

const TRAKING_ID = 'G-2N6FZYJBK1'//'G-LYZ1NXNRG3'
const ga4react = new GA4React(TRAKING_ID);


function MyApp() {
  return <Outlet />
}



const Erro404 = () => {
  const navigate = useNavigate()
  return <Result
    status="404"
    title="404"
    subTitle="Desculpa, a pagina que você visitou não existe!"
    extra={<Button type="primary" onClick={() => {
      navigate("/")
    }}>Voltar principal</Button>}
  />
}



//function App() {
export const Rotas = (props) => {



  const router = createBrowserRouter([
    {
      path: "/",
      element: <MyApp />,
      errorElement: <Erro404 />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "parceiros/login",
          element: <LoginParceirosPage />,
        },
        {
          path: "planos",
          element: <PlanosPage />,
        },
        {
          path: "politica-de-privacidade",
          element: <PoliticaPrivacidade />,
        },
        {
          path: "account",
          element: <AccountPage />,
        },
        {
          path: "Loja/Planos",
          element: <PlanosPage />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <RegisterPage />,
        },
        {
          path: "Loja/Detalhes",
          element: <CompraPage />,
        },
        {
          path: "loja",
          element: <CompraPage />,
        },
        {
          path: "Loja/failure",
          element: <FailurePage />,
        },
        {
          path: "Loja/pending",
          element: <PendingPage />,
        },
        {
          path: "Loja/conclusao",
          element: <ConclusaoPage />,
        },
        {
          path: "Account/Register",
          element: <RegisterPage />,
        },
        {
          path: "manual/tokenengfood",
          element: <ManualPage />,
        },
        {
          path: "account/manager",
          element: <ManagerPage />,
        },
        {
          path: "atualizacoes",
          element: <AtualizacoesPage />,
        },
        {
          path: "parceiros/registro",
          element: <ParceiroRegistro />,
        },
        {
          path: "parceiros/registro-cliente",
          element: <ParceiroRegistroCliente />,
        },
        {
          path: "/parceiros/revenda",
          element: <ParceiroRevenda />,
        },
        {
          path: "parceiros",
          element: <ParceirosOutlet />,
          children: [
            {
              path: "/parceiros",
              element: <DashboardPage />,
            }, 
            {
              path: "/parceiros/clientes",
              element: <ParceiroClientes />,
            },
            {
              path: "/parceiros/cliente",
              element: <ParceiroCliente />,
            },
            {
              path: "/parceiros/dados",
              element: <ParceiroDados />,
            },
            {
              path: "/parceiros/contrato",
              element: <ContratoPage />,
            },
            {
              path: "/parceiros/transacoes",
              element: <ParceiroTransacoes />,
            },
            {
              path: "/parceiros/tokens",
              element: <ParceiroTokens />,
            },
            {
              path: "/parceiros/revendedores",
              element: <ParceiroRevendedores />,
            },
            {
              path: "/parceiros/pagamentos",
              element: <ParceiroPagamentos />,
            },
            {
              path: "/parceiros/downloads",
              element: <ParceiroDownloads />,
            },
            {
              path: "/parceiros/pedidos-menuengfood",
              element: <ParceiroPedidosMenuengfood />,
            },
           
          ]
        },

        {
          path: "manager",
          element: <ManagerOutlet />,
          children: [
            {
              path: "/manager",
              element: <DashboardManager />,
            },
          ]
        },
        {
          path: "revenda",
          element: <ParceiroRevenda />,
        },
        {
          path: "Account/Register",
          element: <RegisterPage />,
        },
        {
          path: "authorization/index",
          element: <Authorization />,
        },
        {
          path: "authorization-code",
          element: <authorizationcodeJs />,
        }
      ]
    }
  ])


  async function load() {
    ga4react.initialize().then((ga4) => {
      ga4.pageview(window.location.pathname + window.location.search)
    }, (err) => {
      console.error(err)
    })
  }

  useEffect(() => {
    load()
    //  console.log('pageview', window.location.pathname + window.location.search)
  }, [])



  return <RouterProvider router={router} />

}

//export default App;
const mapStateToProps = (state) => ({
  user: state.userReducer.user?.user,
  token: state.userReducer.user?.token
})

const mapDispatchToProps = dispatch => ({
  //saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Rotas)