import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import LOGO from '../../images/engfood-round.png'
import { useSearchParams } from "react-router-dom";

export const Index = (props) => {

  const [code, setCode] = useState('')

  useEffect(()=>{
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('code');
    setCode(foo)
  },[])

  function copiarTexto(){
    const inputTest = document.querySelector("#inputTest");

    //seleciona todo o texto do elemento
    inputTest.select();
    //executa o comando copy
    //aqui é feito o ato de copiar para a area de trabalho com base na seleção
    document.execCommand('copy');
  }

  return <div> <br /><br />
    <div className="container">
      <div className="container">
        <div className="jumbotron" style={{margin:'70px', textAlign:'center'}}>
          <p><img heigth='90px' width='90px' src={LOGO} /> </p>


          <p style={{color:'black', marginTop:'20px', fontSize:'23px'}}>Código de Autorização Mercado Pago.</p>

          <div className="input-group">
            <input type="text" id="inputTest" className="form-control" style={{textAlign:'center', marginLeft:'50px', marginRight:'50px'}}
              placeholder="" value={code} />
            <span className="input-group-btn">
              <button className="btn btn-primary" onClick={()=>copiarTexto()} type="button"> Copiar </button>
            </span>
          </div>
          <p style={{color:'black', marginTop:'30px', fontSize:'17px'}}>
            Agora volte ao programa Engfood e informe esse código.
            </p>
        </div>

        <div className="alert alert-warning" role="alert"
          style={{marginLeft:'70px', marginRight:'70px', textAlign:'center'}}>
          <b>Atenção! </b> Esse código só tem válidade de 10 minutos, finalize as configurações em seu programa Engfood
        </div>

      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)