import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    Row, Col, Input, Typography, Divider, Button, Modal, Alert, message
} from 'antd'
//import { validateEmail } from '../../service/util';
import * as userAction from '../../store/actions/user.action'
//import { DownOutlined } from '@ant-design/icons'
import { api } from '../../service/api'
import { useNavigate } from 'react-router-dom';

export const LoginCtrl = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false)
    //const [isModalVisibleLogin, setIsModalVisibleLogin] = useState(false)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [msgError, setmsgError] = useState('')
    const { user, saveUser } = props
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        
        saveUser(undefined)
        
    }, [])

    useEffect(() => {
        
        if (user) {
            if (user !== undefined) {
                navigate('/account/')
            }
        }
        
    }, [user])

    async function login() {
        try {
            setLoading(true)
            setmsgError('')
            const result = await api.post('login', { token: email, pwd: senha })
            console.log('result: ', result.status, result.data)
            if (result.status === 200 && result.data) {
                saveUser(result.data)
               // console.log('login')
                
            } else {
                setmsgError('E-mail e/ou senha incorretos!')
            }

        } catch (error) {
            // console.log('ERROR', error.message)
            setmsgError(error.message)
        }
        setLoading(false)
    }


    async function recuperarSenha() {
        try {
            const result = await api.post('user/recuperar-senha', { token: email, email: email })
            message.info('Foi enviado um e-mail para '.concat(email, ' para recuperação da senha'))
            setIsModalVisible(false)
        } catch (error) {
            message.error('Erro ao recuperar a senha!')
        }
    }


    return <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'whitesmooke' }}>
        <Row>
            <Col span={24}>
                <strong>Token Engfood</strong>
                <Divider style={{ marginTop: 5 }} />
            </Col>

            <Col span={24}>
                <Typography.Text style={{ color: 'red' }}>{msgError && msgError}</Typography.Text>
            </Col>


            <Col span={24}>E-mail</Col>
            <Col span={24}>
                <Input value={email} onChange={e => setEmail(e.target.value)} />
            </Col>
            <Col span={24}>Senha</Col>
            <Col span={24}>
                <Input.Password value={senha} onChange={e => setSenha(e.target.value)} />
            </Col>
            <Col span={24}>
                <div style={{
                    color: 'royalblue', display: 'flex',
                    flexDirection: 'row-reverse', marginTop: 8, marginBottom: 8
                }}>
                    <Button type='link' onClick={() => setIsModalVisible(true)}>Não lembra a senha?</Button>
                </div>
            </Col>
            <Col span={24}>
                <Button type='primary' block loading={loading}
                    onClick={() => login()}>Login</Button>
            </Col>
            <Col span={24}><Divider /></Col>
            <Col span={24}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    Novo aqui? <Button type='link' onClick={() => navigate('/register')}>
                        Criar Token
                    </Button>
                </div>
            </Col>
        </Row>

        <Modal
            visible={isModalVisible}
            destroyOnClose={true}
            closable={false}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => { recuperarSenha() }}>
            <Row>
                <Col span={24}><Alert
                    message="Não lembra a senha?"
                    description="Fique calmo, informe seu e-mail abaixo que enviamos para você."
                    type={'info'}
                    showIcon
                /></Col>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Input value={email} onChange={e => setEmail(e.target.value)} />
                </Col>
            </Row>
        </Modal>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCtrl)