import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Typography, Modal, Table, Space, Select, message, Input, DatePicker} from 'antd'
import { api } from '../../service/api'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import '@inovua/reactdatagrid-community/index.css';
import { useNavigate } from 'react-router-dom'
import * as parceiroAction from '../../store/actions/parceiro.action'
import {  isDesktop } from 'react-device-detect';
import { ClienteCtrl } from '../../components/parceiros/clienteCtrl'


const { Column } = Table
const { Search } = Input
const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY';

export const PaymentsCtrl = (props) => {

    const [data, setData] = useState([])
    const { parceiro, saveParceiro, token } = props
    const [status, setStatus] = useState(props.status)
    const [searching, setSeaching] = useState('')
    const navigate = useNavigate()
    const [contratoSelecionado, setContratoSelecionado] = useState({})
    const [modalAccount, setModalAccount] = useState(false)
    const [range, setRange] = useState([dayjs().startOf('month'), dayjs()])
    const [situacao, setSituacao] = useState('approved')
    const [total, setTotal] = useState(0)

    useEffect(() => {
        //  console.log('parceiros', parceiro.clientes)
    }, [status, searching])

    useEffect(() => {
        load(range, situacao)
    }, [])

    async function load(value, sit) {
        try {
            // setLoadLicencas(true)
            const response = await api.get('payments', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: value[0].format('YYYY-MM-DD'),
                    data2: value[1].format('YYYY-MM-DD'),
                    situacao: sit
                }
            })
            setData(response.data)
            var aux = 0
            response.data.map((item, index) => {
                aux += parseFloat(item.total)
            })
            setTotal(aux)
        } catch (error) {
            console.log(error.message)
            if (error?.response.status === 401) {
                saveParceiro(null)
                navigate('/parceiros/login')
            }
            message.error(error.message)
        }
    }




    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                <Space direction={isDesktop? 'horizontal' : 'vertical'}>
                    <RangePicker
                        allowClear={false}
                        value={range}
                        onChange={(value) => {
                            // console.log(value)
                            setRange(value)
                            load(value, situacao)
                        }}
                        format={dateFormat}
                    />

                    <Select value={situacao} style={{ width: 200 }}
                        onChange={(value) => {
                            setSituacao(value)
                            load(range, value)
                        }}>
                        <Option value={"Todas"}>Todas</Option>
                        <Option value={"approved"}>Paga</Option>
                        <Option value={"Aguardando pagamento"}>Aguardando pagamento</Option>
                        <Option value={"Retenção temporária"}>Retenção temporária</Option>
                        <Option value={"Devolvida"}>Devolvida</Option>
                        <Option value={"Aprovada"}>Approved</Option>
                    </Select>
                </Space>
            </div>
            <div>
                <Table dataSource={data} pagination={false}
                    size="small" showHeader={isDesktop ? true : false}
                    footer={() => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Row gutter={[8, 8]} style={{ width: '100%' }}>
                            <Col xs={12} lg={6}><Typography.Text>Total: {data.length} {'    R$ '.concat(total.toFixed(2))}</Typography.Text></Col>
                            <Col xs={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}> </Col>
                        </Row>

                    </div>}>
                    {isDesktop && <Column title={<strong>Fantasia</strong>}
                        dataIndex={"data"} key={"id"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}> {dayjs(text).format('DD/MM/YYYY HH:mm')} </div>
                        }} />
                    }
                    {isDesktop && <Column title={<strong>Código</strong>}
                        dataIndex={"codigo"} key={"id"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column title={<strong>Status</strong>}
                        dataIndex={"status"} key={"status"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column width={130} title={<strong>E-mail</strong>}
                        dataIndex={"email"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column title={<strong>Descrição</strong>}
                        dataIndex={"descricao"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column width={100} title={<strong>Valor</strong>}
                        dataIndex={"total"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {
                        !isDesktop && <Column
                            render={(text, record, index) => {
                                return <div
                                    style={{ display: 'flex', flexDirection: 'column', fontSize: 12, wordBreak: 'break-all' }}>
                                    <div style={{ color: 'royalblue', fontSize: 12 }} onClick={() => {
                                        // setContratoSelecionado(record)
                                        //  setModalAccount(true)
                                    }}>
                                        <strong style={{ fontSize: 12 }}> {dayjs(record.data).format('DD/MM/YYYY HH:mm')} </strong>
                                    </div>
                                    <div style={{ fontSize: 12 }}>{record.codigo}</div>
                                    <div style={{ wordBreak: 'break-all', textTransform: 'lowercase' }}> {record.email} </div>
                                    <div style={{ fontSize: 12 }}>{record.status}</div>
                                    <div style={{ fontSize: 12 }}>{record.total}</div>
                                </div>
                            }} />

                    }

                </Table>
            </div>
            {
                <Modal visible={modalAccount}
                    width={isDesktop ? '80%' : '100%'}
                    footer={null}
                    destroyOnClose={true}
                    closable={true}
                    onCancel={() => setModalAccount(false)}>
                    <ClienteCtrl parceiroID={parceiro.id}
                        setModalAccount={setModalAccount} cliente={contratoSelecionado} />
                </Modal>
            }

        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsCtrl)