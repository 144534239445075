import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Avatar, List } from 'antd';
import { api, BASEURL } from '../../service/api'


export const DownloadsCtrl = (props) => {


    const { token } = props
    const [data, setData] = useState([])

    useEffect(() => {
        load()
    }, [])


    async function load() {
        try {
            const response = await api.get('parceiro/files',
                {
                    headers: {
                        'x-access-token': token,
                    }
                })
          //  console.log(response.data)
            setData(response.data)
        } catch (error) {

        }
    }

    function download(item){
        const link = document.createElement('a');
        link.href = item.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <div>
            <div style={{marginBottom:15}}>
                <strong>ARQUIVOS PARA DOWNLOAD</strong>
            </div>
            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item onClick={()=>download(item)} style={{cursor:'pointer'}}
                        actions={[<span>{item.versao}</span>]}>
                            <List.Item.Meta
                                avatar={<Avatar src={require('../../images/baixar.png')} />}
                                title={<b>{item.titulo}</b>}
                                description={item.descricao}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    // saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsCtrl)