import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const IntegracaoIfood = (props) => {

    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/integracao-ifood/');
    },[])

    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                <Typography.Title level={4}>
                    Integração iFood <br />
                    <span style={{ fontSize: 13, fontWeight: 'normal' }}>
                        Atualizado em 28/06/2022
                    </span>
                </Typography.Title>

                <br />
                <Typography.Title level={5}>Vantagens</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Os pedidos realizados pelos seus clientes através do iFood caem direto no seu Programa Engfood. Sem registros manuais, sem retrabalho. Ao receber os pedidos seu Engfood vai processar como um pedido realizado delivery no próprio Engfood, controlando estoque e enviando para os setores de produção se necessário.
                    <br /><br />
                    <Typography.Title level={5}>Iniciando a integração</Typography.Title>
                    <br />
                    <img src={require('../../images/manual/ifood-1.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br />
                    <br />
                    Primeiro, você deve se certificar que está utilizando a versão 12.0.8 ou superior, na sequência acesse: menu integrações /iFood. Ao ver tela abaixo, clique no botão Próximo.
                    <br /><br />
                    <img src={require('../../images/manual/ifood-2.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br />
                    <br />
                    Clique em solicitar integração e depois em Acessar para ser direcionado ao portal do ifood
                    <br /><br />
                    <img src={require('../../images/manual/ifood-3.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br /><br />
                    Já no portal do ifood clique em altorizar para receber o código de ativação
                    <br /><br />
                    <img src={require('../../images/manual/ifood-4.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br /><br />
                    Cópie o código e volte ao programa Engfood para configurar e salvar sua integração.
                    <br /><br />
                    <img src={require('../../images/manual/ifood-5.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br /><br />
                    <img src={require('../../images/manual/ifood-6.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />

                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Feito essas configurações reinicie o Engfood para que sua integração funcione corretamente."
                        type="warning"
                        showIcon
                    />
                </div>
                <br /> <br />
                <br />

            </div>


            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 20 }}>
                <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
            </div>       <br /><br />

            <FooterCtrl />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(IntegracaoIfood)