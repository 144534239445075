import React from 'react'
import { connect } from 'react-redux'
import { Typography } from 'antd'

export const ContratoPage = (props) => {

  const { parceiro } = props

  return (
    <div>
      {parceiro && <div>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}> <Typography.Title level={4}>
          CONTRATO DE PARCERIA PARA REVENDA DE LICENÇA<br />
          PROGRAMA ENGFOOD

        </Typography.Title> </div>

        <div style={{ fontSize: 13 }}> Pelo presente instrumento particular, e na melhor forma de direito, as partes a seguir qualificadas: <br />
          Programa Engfood sociedade por quotas de responsabilidade limitada, com sede na Rua Delmiro Júnior 401, AP 202B, inscrita no CNPJ/MF sob o nº 30358497000185, neste ato representada nos termos do seu contrato social, doravante denominada simplesmente PARCEIRA, e, </div>
        <div style={{ fontSize: 13 }}> Nome: {parceiro?.nome} endereço: {parceiro?.endereco} CPF nº {parceiro?.cpf} doravante denominada simplesmente REVENDEDOR</div>
      </div>
      }
      <br />
      <div> <strong>1. DO OBJETO</strong> </div>
      <br />
      <div style={{ fontSize: 13 }}> 1.1. O presente Contrato tem por objeto estabelecer as condições pelas quais o <b>REVENDEDOR</b> passará a prestar os serviços desenvolvidos pela <b>PARCEIRA</b>. </div>
      <br />
      <div> <strong>2. DAS CONDIÇÕES GERAIS</strong> </div>
      <br />
      <div style={{ fontSize: 13 }}> 2.1. Considera-se <b>REVENDEDOR</b> a empresa ou pessoa física que prestar em seu nome os serviços prestados pela <b>PARCEIRA</b> e oferecer através de equipe própria, suporte técnico aos seus clientes. </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.2 O <b>REVENDEDOR</b> está autorizado a prestar os serviços da <b>PARCEIRA</b> mediante uso de marca e identificações próprias, responsabilizando-se o <b>REVENDEDOR</b> por todos os ônus e custos oriundos desta autorização, tais como registros de marca, responsabilidade em face dos clientes e outros.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.3. Para cada cliente que contratar os serviços objeto deste contrato, o <b>REVENDEDOR</b> deverá celebrar um contrato com seu
        cliente, contendo todas as condições estabelecidas no termo de uso veiculado no site <a href='/contrato-de-licenca-de-uso'
          target={'_blank'}>www.programaengfood.com.br</a> .
        Os serviços autorizados a serem prestados pelo <b>REVENDEDOR</b> aos seus clientes são:
      </div>
      <br />
      <ul style={{ fontSize: 13 }}>
        <li><b>LICENÇA DE SOFTWARE:</b> Licença Mensal(30 dias) do Programa Engfood</li>
      </ul>
      <br />
      <div style={{ fontSize: 13 }}>
        2.4 As especificações e características dos serviços prestados aos clientes do revendedor podem ser modificadas de forma
        unilateral pela <b>PARCEIRA</b>, mediante comunicação prévia com 30(trinta) dias de antecedência, obrigando-se o <b>REVENDEDOR</b> a comunicar o cliente das alterações realizadas.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.5. A cada novo contrato celebrado, o <b>REVENDEDOR</b>  deverá enviar as especificações para a prestação dos serviços, contendo
        todas as informações sobre o cliente, conforme modelo fornecido pela <b>PARCEIRA</b> (cadastro de cliente).
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.6 A <b>PARCEIRA</b> não se responsabiliza pelo cancelamento generalizado do serviço prestado, assim considerado a prestação dos serviços aos clientes da <b>PARCEIRA</b> e do <b>REVENDEDOR</b>, por indisponibilidade técnica, decisão empresarial ou qualquer outro motivo, permanecendo válidas as condições contratadas vigentes para os demais serviços não atingidos pelo ora referido cancelamento.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.7 A responsabilidade da <b>PARCEIRA</b> perante terceiros está restrita à providenciar os reparos nos serviços, mediante solicitação prévia do <b>REVENDEDOR</b>, constituindo responsabilidade do <b>REVENDEDOR</b>  responder por eventuais reclamações dos clientes perante qualquer Juízo ou instância judicial, bem como procedimentos administrativos em qualquer órgão público, além das licenças necessárias para a atividade perante  a administração pública, bem como em face de terceiros em geral no que tange aos serviços regulados neste instrumento.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.8 Sem prejuízo da cláusula 2.7 acima, de qualquer forma, a responsabilidade da <b>PARCEIRA</b> neste contrato está restrito aos danos diretos, excluindo-se lucros cessantes, danos emergentes, danos de imagem, expectativas de direitos e outros.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        2.9. A tabela de preços utilizada pelo <b>REVENDEDOR</b> referente a licença deve ser igual ao praticado no site da <b>PARCERIA</b>, podendo cobrar por serviços adicionais como de suporte local entre outros e adotar o preço que entender adequado para atender seus clientes.
      </div>
      <br />
      <div>
        <strong>3. DA POLÍTICA DE NÃO CONCORRÊNCIA</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        3.1. O <b>REVENDEDOR</b>  não poderá iniciar ou manter relações comerciais que envolvam o mesmo serviço, com prospects e
        clientes que estejam sendo atendidos pela <b>PARCEIRA</b> ou por outros revendedores.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        3.2. A <b>PARCEIRA</b> garante através deste instrumento que todas as negociações com clientes ou prospects conduzidas pelo
        <b> REVENDEDOR</b> serão preservadas com exclusividade a este.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        3.3. O <b>REVENDEDOR</b> não poderá atuar como fornecedor de serviços similares, destinados ao mesmo fim, fornecido por empresas
        concorrentes, durante toda a vigência deste Contrato.
      </div>
      <br />
      <div>
        <strong>4. DO ATENDIMENTO AOS CLIENTES</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        4.1. É de responsabilidade do <b>REVENDEDOR</b> fornecer suporte técnico aos seus clientes, garantindo a estes o uso adequado dos
        serviços, resolução de dúvidas e acompanhamento durante a prestação de serviços.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        4.2 Todos os contatos serão realizados apenas entre a <b>PARCEIRA</b> e o <b>REVENDEDOR</b>, especificadamente à qualquer
        solicitação de reparo ou manutenção relacionada aos serviços prestados aos clientes do <b>REVENDEDOR</b>.
      </div>
      <br />
      <div>
        <strong>5. DA FORMA DE REMUNERAÇÃO</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.1. Para cada negócio realizado, o <b>REVENDEDOR</b> emitirá Nota Fiscal, recibo ou ficha de compensação diretamente para o seu cliente.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.2. Como o pagamento dos serviços prestados através desta parceria será feito pelo <b>REVENDEDOR</b> à <b>PARCEIRA</b>, sua remuneração se dará através de descontos na tabela de preços de varejo dos serviços disponibilizadas no site programaengfood.com.br, descontos estes especificados no item 5.3 abaixo.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.3. Para qualquer modalidade de cobrança, a <b>PARCEIRA</b>  aplicará a seguinte tabela de descontos:
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        <b> Contrato Desconto sobre a Licença Mensal </b><br />
        {
          /*
          1º Contrato 20%.<br />
          2º Contrato 30%.<br />
          3º Contrato e até o 19º contrato 40%.<br />
          A partir do 20º contrato 45%.<br />
          */
        }
        A partir do 1º contrato 40% de desconto.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.4. As partes poderão alterar a forma de remuneração superior a tabela prevista no item 5.3, de comum acordo entre as partes, e mediante celebração de termo aditivo.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.5. A inadimplência por parte do cliente não desobriga o <b>REVENDEDOR</b> ao pagamento dos valores devidos.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.6. O <b>REVENDEDOR</b> não poderá passar 60 dias corridos com menos de 3 clientes ativos por risco de cancelamento desde contrato.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        5.7. Será contabilizado para a tabela do item 5.3 apenas contratos com licenças ativas.
      </div>
      <br />
      <div>
        <strong>6. DA VIGÊNCIA</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        6.1. Este Contrato entra em vigor na data do cadastro de parceiro efetuado no site www.programaengfood.com.br e vigerá por prazo
        indeterminado, facultando-se às partes rescindi-lo de pleno direito, sem ônus a qualquer tempo, mediante comunicação à outra parte.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        6.2. A rescisão deste Contrato não suspende as obrigações pactuadas entre o <b>REVENDEDOR</b> e seus clientes sendo que durante todo
        o período em que vigorar um ou mais serviços fechados pelo <b>REVENDEDOR</b>, todas as obrigações e direitos aqui pactuados
        permanecem vigentes, desde que o <b>REVENDEDOR</b> continue realizando os pagamentos objeto deste contrato, restando vedada a
        contratação para novos clientes.
      </div>
      <br />
      <div>
        <strong>7. DA CONFIDENCIALIDADE</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        7.1. As partes convencionam que toda e qualquer informação transferida entre elas em decorrência deste contrato, será considerada
        confidencial e privilegiada, e não será divulgada a terceiros sem o expresso consentimento de ambas.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        7.3. O <b>REVENDEDOR</b> se compromete a obter termo de confidencialidade de seus funcionários diretamente relacionados à execução
        dos serviços contratados pelos seus clientes.
      </div>
      <br />
      <div>
        <strong>8. CONDIÇÕES GERAIS</strong>
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        8.3 O presente Contrato não estabelece entre as Partes, qualquer vínculo de imagem, natureza societária, associativa ou empregatícia,
        e nem estabelece relação de representação comercial, ficando restritas as obrigações e direitos ao objeto do presente contrato.
      </div>
      <br />
      <div style={{ fontSize: 13 }}>
        8.1. Fica eleito o Foro Central da Comarca Maracanaú - Ceará para dirimir quaisquer questões relativas ao presente Contrato, com
        renúncia expressa de qualquer outro, por mais privilegiado que seja.
      </div>
      <br />
      <div>

      </div>
      <br />

    </div>
  )
}

const mapStateToProps = (state) => ({
  parceiro: state.parceiroReducer.parceiro?.parceiro,
  token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
  //saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContratoPage)