import React from 'react'
import { connect } from 'react-redux'
import './footer.css'
import { Row, Col, Typography, Space} from 'antd'
import { InstagramOutlined, YoutubeOutlined, FacebookOutlined } from '@ant-design/icons'

export const FooterCtrl = (props) => {
    return <div>
        <div className='bar-footer'>
            <Row style={{ margin: 40 }}>
                <Col xs={24} md={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text strong style={{ color: 'white', marginTop:15 }}>LOCALIZAÇÃO </Typography.Text>

                    <Typography.Text style={{ color: 'whitesmoke', fontSize: 13 }}>  Rua Delmiro Júnior 401 </Typography.Text>
                    <Typography.Text style={{ color: 'whitesmoke', fontSize: 13 }}> Maracanaú-CE</Typography.Text>
                    <Typography.Text style={{ color: 'whitesmoke', fontSize: 13 }}> 85 9 8835-8372 </Typography.Text>
                </Col>
                <Col xs={24} md={12} lg={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignContent: 'flex-start' }}>
                    <Typography.Text strong style={{ color: 'white',  marginTop:15 }}> LINKS ÚTEIS </Typography.Text>
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:5 }}
                        href="/atualizacoes">Atualizações</Typography.Link >
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:3 }}
                        href="http://manual.programaengfood.com.br" target="_blank">Manual
                    </Typography.Link >
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:3 }}
                        href="https://menuengfood.com.br" target="_blank">Menuengfood</Typography.Link >
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:3 }}
                        href="https://portal.menuengfood.com.br" target="_blank">Portal Menuengfood</Typography.Link >
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:3 }}
                        href="https://www.youtube.com/channel/UCjCfyh6Lr8P_xl5mKRmdn2g" target="_blank">Vídeos
                    </Typography.Link >
                    <Typography.Link style={{ color: 'whitesmoke', fontSize: 13, marginTop:3 }}
                        href="/parceiros/revenda">Revenda e Parceiros
                    </Typography.Link >
                </Col>
                <Col xs={24} md={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text strong style={{ color: 'whitesmoke', fontSize: 14, marginTop:15 }}> REDES SOCIAIS </Typography.Text>
                    <Space >
                        <Typography.Link style={{ color: 'whitesmoke', fontSize: 13 }}
                            href="https://www.instagram.com/programaengfood/" target="_blank">
                            <InstagramOutlined style={{ fontSize: 32 }} />
                        </Typography.Link>
                        <Typography.Link style={{ color: 'whitesmoke', fontSize: 13 }}
                            href="https://www.youtube.com/channel/UCjCfyh6Lr8P_xl5mKRmdn2g" target="_blank">
                            <YoutubeOutlined style={{ fontSize: 32 }} />
                        </Typography.Link>
                        <Typography.Link style={{ color: 'whitesmoke', fontSize: 13 }}
                            href="https://www.facebook.com/programaengfood/" target="_blank">
                            <FacebookOutlined style={{ fontSize: 32 }} />
                        </Typography.Link>
                    </Space>

                </Col>
                <Col xs={24} md={12} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>

                    <Typography.Text style={{ color: 'whitesmoke', fontSize: 13,
                width: '100%', marginTop:15 }}>"Cada sonho que você deixa pra trás, é um pedaço do seu futuro que deixa de existir."
                    </Typography.Text>
                    <Typography.Text strong
                        style={{ color: 'white', width: '100%', textAlign: 'right',fontSize: 14  }}>
                        STEVE JOBS
                    </Typography.Text>
                </Col>
            </Row>
        </div>
        <div className='bar-footer2'>
            <Row >
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', fontSize: 12 }}>
                    © 2022 PROGRAMA ENGFOOD - TODOS OS DIREITOS RESERVADOS
                </Col>
            </Row>
        </div>
    </div>
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FooterCtrl)