import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FooterCtrl from '../../../components/footer'
import NavbarCtrl from '../../../components/NavbarParceiro'
import { Row, Col, Input, Typography, Divider, Button, Space, Card, Modal } from 'antd'
import { isMobile } from 'react-device-detect'
import ContratoCtrl from '../../../components/parceiros/contratoCtrl'
import { useGA4React } from "ga-4-react";
import { useNavigate } from 'react-router-dom'

const { Meta } = Card;

export const Revenda = (props) => {

    const [status, setStatus] = useState('Todos')
    const [visivel, setVisivel] = useState(false)
    const [key, setKey] = useState('1')
    const navigate = useNavigate()

    const ga = useGA4React();
    //console.log(ga);

    useEffect(() => {
        // ga.pageview(window.location.pathname + window.location.search);
    }, [])

    return isMobile ? renderMobile() : renderDesktop()

    function renderMobile() {
        return <div>
            <NavbarCtrl />
            <div style={{
                backgroundColor: '#A020F0', display: 'flex', flexDirection: 'column'
            }}>
                <img alt='img' style={{ width: '100%' }} src={require('../../../images/painel-revenda-pessoas.png')} />

                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
                    flexDirection: 'column', margin: 50, color: 'white'
                }}>
                    <Typography.Title level={3} style={{ color: 'white' }}>
                        Seja um Revendedor Engfood.
                    </Typography.Title>
                    <Typography.Text style={{ color: 'white' }} >
                        Oferecemos simplesmente a melhor proposta de parceria do Brasil, gere receitas recorrente sem necessidade de
                        investimentos e desenvolvimento! <br/> Solução de marca branca para revendedores profissionais.
                    </Typography.Text>
                    <Button shape='round' type='primary' size='large' style={{ marginTop: 40 }}
                        onClick={() => navigate('/parceiros/login')}>Seja nosso parceiro</Button>
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, color: 'blue' }}>
                <h3 style={{ color: 'blue', marginLeft: 50, marginRight: 50 }}>Uma parceria de revenda perfeita para você.</h3>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img alt='img' style={{ width: '100%' }} src={require('../../../images/revenda-2.png')} />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'whitesmooke' }}>
                        <div style={{ margin: 50 }}>
                            <Space>
                                <img src={require('../../../images/white-label.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>White Label</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Adicione as suas cores, o seu logotipo.
                                            Venda o nosso produto e o seu serviço em conjunto, sob o nome da marca em que os seus clientes confiam</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/bills.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Alta lucratividade</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Margem de lucro de até 40% sobre o valor da licença.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/money.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Zero de investimento</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Não é necessário fazer qualquer tipo de investimento para ser revendedor.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/database.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Migração de banco de dados</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Migramos as informações de seus clientes em até 24h.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/portal.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Portal da Revenda</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Portal completo para gestão de sua carteira de clientes.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/central-de-atendimento.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Suporte 7/7</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Estamos prontos para lhe atender, não importa o dia da semana.</span>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'whitesmoke', padding: 20 }}>
                <div>
                    <h3 style={{ color: 'blue', display: 'flex', justifyContent: 'center' }}>Como funciona</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 20 }}>
                    <Card
                        hoverable>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                            <img src={require('../../../images/relationship.png')} />
                        </div>
                        <div><Typography.Title level={5} style={{ textAlign: 'center' }}>1. Torne-se parceiro</Typography.Title></div>
                        <div>
                            <Typography.Text style={{ color: 'gray' }}>
                                Cadastre-se grátis como revenda em nosso portal.
                            </Typography.Text>
                            {/*<Button type='link'  onClick={()=>  navigate('/parceiros')}>Clique aqui</Button> */}
                        </div>
                    </Card>

                    <Card
                        style={{ marginTop: 10 }}
                        hoverable>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                            <img src={require('../../../images/register.png')} />
                        </div>
                        <div><Typography.Title level={5} style={{ textAlign: 'center' }}>2. Cadastre seu cliente</Typography.Title></div>
                        <div>
                            <Typography.Text style={{ color: 'gray' }}>
                                Depois de logado em seu portal da revenda você cadastra seus clientes
                            </Typography.Text>
                        </div>
                    </Card>

                    <Card
                        style={{ marginTop: 10 }}
                        hoverable>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                            <img src={require('../../../images/monitor.png')} />
                        </div>
                        <div><Typography.Title level={5} style={{ textAlign: 'center' }}>3. Adquirir licença</Typography.Title></div>
                        <div>
                            <Typography.Text style={{ color: 'gray' }}>
                                Compre as licenças para seus cliente com desconto de 40% no plano mensal.
                            </Typography.Text>
                        </div>
                    </Card>


                    <Card
                        style={{ marginTop: 10 }}
                        hoverable>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                            <img src={require('../../../images/money.png')} height={64} />
                        </div>
                        <div><Typography.Title level={5} style={{ textAlign: 'center' }}>4. Receba o pagamento do cliente</Typography.Title></div>
                        <div>
                            <Typography.Text style={{ color: 'gray' }}>
                                Cobre diretamente o cliente pela licença e possíveis serviços realizados.
                            </Typography.Text>
                        </div>
                    </Card>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 20 }}>

                    <Button type='link' onClick={() => setVisivel(true)}>Conheça os termos de uso</Button>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', padding: 20, alignItems: 'center' }}>
                <div>Cadastre-se agora mesmo e comece a ganhar mais dinheiro com sua carteira de clientes</div>
                <br />
                <div>
                    <Button type='primary' shape='round' size='large'
                        onClick={() => navigate('/parceiros/login')}>Quero ser parceiro</Button>
                </div>
                <br />
            </div>


            <FooterCtrl />

            <Modal footer={null} width={isMobile ? '90%' : '70%'} visible={visivel}
                onOk={() => setVisivel(false)} onCancel={() => setVisivel(false)}><div>
                    <ContratoCtrl setStatus={setStatus} setKey={setKey} props={props} />
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                        <Button onClick={() => setVisivel(false)} type='primary' > Fechar </Button>
                    </div>

                </div>
            </Modal>

        </div>
    }


    function renderDesktop() {
        return (
            <div>
                <NavbarCtrl />
                <div style={{
                    height: 400, backgroundColor: '#A020F0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                }}>
                    <div>
                        <div style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
                            flexDirection: 'column', margin: 50, color: 'white'
                        }}>
                            <Typography.Title level={3} style={{ color: 'white' }}>
                                Seja um Revendedor Engfood White Label.
                            </Typography.Title>
                            <Typography.Text style={{ color: 'white' }} >
                                Oferecemos simplesmente a melhor proposta de parceria do Brasil, gere receitas recorrente sem necessidade de
                                investimentos e desenvolvimento!  <br/> Solução de marca branca para revendedores profissionais.
                            </Typography.Text>
                            <Button shape='round' type='primary' size='large' style={{ marginTop: 40 }}
                                onClick={() => navigate('/parceiros/login')}>Seja nosso parceiro</Button>
                        </div>
                    </div>
                    <img alt='img' style={{ height: 400 }} src={require('../../../images/painel-revenda-pessoas.png')} />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, color: 'blue' }}>
                    <h3 style={{ color: 'blue' }}>UMA PARCERIA DE REVENDA PERFEITA PARA VOCÊ.</h3>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <img alt='img' style={{ height: 400 }} src={require('../../../images/revenda-2.png')} />

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'whitesmooke' }}>
                            <div style={{ margin: 50 }}>
                                <Space>
                                    <img src={require('../../../images/white-label.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>White Label</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Adicione as suas cores, o seu logotipo.
                                                Venda o nosso produto e o seu serviço em conjunto, sob o nome da marca em que os seus clientes confiam</span>
                                        </div>
                                    </div>
                                </Space>
                                <Space style={{ marginTop: 20 }}>
                                    <img src={require('../../../images/bills.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>Alta lucratividade</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Margem de lucro de até 40% sobre o valor da licença.</span>
                                        </div>
                                    </div>
                                </Space>
                                <Space style={{ marginTop: 20 }}>
                                    <img src={require('../../../images/money.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>Zero de investimento</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Não é necessário fazer qualquer tipo de investimento para ser revendedor.</span>
                                        </div>
                                    </div>
                                </Space>
                                <Space style={{ marginTop: 20 }}>
                                    <img src={require('../../../images/database.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>Migração de banco de dados</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Migramos as informações de seus clientes em até 24h.</span>
                                        </div>
                                    </div>
                                </Space>
                                <Space style={{ marginTop: 20 }}>
                                    <img src={require('../../../images/portal.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>Portal da Revenda</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Portal completo para gestão de sua carteira de clientes.</span>
                                        </div>
                                    </div>
                                </Space>
                                <Space style={{ marginTop: 20 }}>
                                    <img src={require('../../../images/central-de-atendimento.png')} height={36} />
                                    <div>
                                        <div>
                                            <div>
                                                <strong>Suporte 7/7</strong>
                                            </div>
                                            <span style={{ fontSize: 14 }}>Estamos prontos para lhe atender, não importa o dia da semana.</span>
                                        </div>
                                    </div>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ backgroundColor: 'whitesmoke', padding: 50 }}>
                    <div>
                        <h3 style={{ color: 'blue', display: 'flex', justifyContent: 'center' }}>Como funciona</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: 50 }}>
                        <Card
                            hoverable
                            style={{ maxWidth: 250 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                                <img src={require('../../../images/relationship.png')} />
                            </div>
                            <div><Typography.Title level={5} style={{ textAlign: 'center' }}>1. Torne-se parceiro</Typography.Title></div>
                            <div>
                                <Typography.Text style={{ color: 'gray' }}>
                                    Cadastre-se grátis como revenda em nosso portal.
                                </Typography.Text>
                                {/*<Button type='link'  onClick={()=>  navigate('/parceiros')}>Clique aqui</Button> */}
                            </div>
                        </Card>

                        <Card
                            hoverable
                            style={{ maxWidth: 250 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                                <img src={require('../../../images/register.png')} />
                            </div>
                            <div><Typography.Title level={5} style={{ textAlign: 'center' }}>2. Cadastre seu cliente</Typography.Title></div>
                            <div>
                                <Typography.Text style={{ color: 'gray' }}>
                                    Depois de logado em seu portal da revenda você cadastra seus clientes
                                </Typography.Text>
                            </div>
                        </Card>

                        <Card
                            hoverable
                            style={{ maxWidth: 250 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                                <img src={require('../../../images/monitor.png')} />
                            </div>
                            <div><Typography.Title level={5} style={{ textAlign: 'center' }}>3. Adquirir licença</Typography.Title></div>
                            <div>
                                <Typography.Text style={{ color: 'gray' }}>
                                    Compre as licenças para seus cliente com desconto de 40% no plano mensal.
                                </Typography.Text>
                            </div>
                        </Card>


                        <Card
                            hoverable
                            style={{ maxWidth: 250 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 20 }}>
                                <img src={require('../../../images/money.png')} height={64} />
                            </div>
                            <div><Typography.Title level={5} style={{ textAlign: 'center' }}>4. Receba o pagamento do cliente</Typography.Title></div>
                            <div>
                                <Typography.Text style={{ color: 'gray' }}>
                                    Cobre diretamente o cliente pela licença e possíveis serviços realizados.
                                </Typography.Text>
                            </div>
                        </Card>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: 50 }}>

                        <Button type='link' onClick={() => setVisivel(true)}>Conheça os termos de uso</Button>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: 50 }}>
                    <div>Cadastre-se agora mesmo e comece a ganhar mais dinheiro com sua carteira de clientes</div>
                    <div>
                        <Button type='primary' shape='round' size='large'
                            onClick={() => navigate('/parceiros/login')}>Quero ser parceiro</Button>
                    </div>
                </div>


                <FooterCtrl />

                <Modal footer={null} width={isMobile ? '90%' : '70%'} visible={visivel}
                    onOk={() => setVisivel(false)} onCancel={() => setVisivel(false)}><div>
                        <ContratoCtrl setStatus={setStatus} setKey={setKey} props={props} />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                            <Button onClick={() => setVisivel(false)} type='primary' > Fechar </Button>
                        </div>

                    </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Revenda)