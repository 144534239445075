import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Col, Row, Button, Divider, Typography, Modal, Table, Space, Checkbox,
    message, Tag, DatePicker, InputNumber, Tabs
} from 'antd'
import {
    ArrowUpOutlined, ArrowDownOutlined
} from '@ant-design/icons'
import { api, BASEURL } from '../../service/api'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import '@inovua/reactdatagrid-community/index.css';
import { useNavigate } from 'react-router-dom'
import * as parceiroAction from '../../store/actions/parceiro.action'
import { isMobile, isDesktop } from 'react-device-detect';
import TransationDetail from './transationDetail'
import { KEYMP } from '../../Constantes'

const { Column } = Table
const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY';
const { TabPane } = Tabs;

const onChange = (key) => {
    // console.log(key);
};

export const TransacoesCtrl = (props) => {

    const { parceiro, saveParceiro, token } = props
    const [status, setStatus] = useState(props.status)
    const [searching, setSeaching] = useState('')
    const navigate = useNavigate()
    const [transationSeleted, setTransationSelected] = useState({})
    const [modalTrasationDetail, setModalTrasationDetail] = useState(false)

    const [modalCredito, setModalCredito] = useState(false)
    const [transacoes, setTransacoes] = useState([])
    const [range, setRange] = useState([dayjs().subtract(130, 'days'), dayjs()])
    const [total, setTotal] = useState(0)
    const [dataSaldo, setDataSaldo] = useState({})
    const [valueCredito, setValueCredito] = useState(60)
    const [loadding, setLoading] = useState(false)
    const [saldoHistorico, setSaldoHistorico] = useState([])

    useEffect(() => {
            
    }, [status, searching])


    useEffect(() => {
        getTransacoes(range)
        getSaldo()
        loadSaldoHistorico()
       //  console.log('CTRL Transações')
    }, [])

    useEffect(() => {
        somar()
    }, [transacoes])


    function somar() {
        try {
            var aux = 0;
            transacoes.map(item => {
                aux += parseFloat(item.total)
            })
            setTotal(aux)
        } catch (error) { }
    }


    async function getTransacoes(value) {
        try {
            // setLoadLicencas(true)
            const response = await api.get('parceiros/transacoes', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: value[0].format('YYYY-MM-DD'),
                    data2: value[1].format('YYYY-MM-DD'),
                    emailparceiro: parceiro.email
                }
            })
            setTransacoes(response.data)
            //  console.log(response.data)

        } catch (error) {
            console.log(error.message)
            if (error?.response.status === 401) {
                saveParceiro(null)
                navigate('/parceiros/login')
            }
            message.error(error.message)
        }
        // setLoadLicencas(false)
    }


    async function getTransacao(id) {
        try {
            // setLoadLicencas(true)
            const response = await api.get('pagamento', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    id: id
                }
            })
            setTransationSelected(response.data)
            console.log(response.data)

        } catch (error) {
            console.log(error.message)
            if (error?.response.status === 401) {
                saveParceiro(null)
                navigate('/parceiros/login')
            }
            message.error('Erro ao carregar dados da transação!\n'.concat(error.message))
            setModalTrasationDetail(false)
        }
        // setLoadLicencas(false)
    }


    async function getSaldo() {
        try {
            const response = await api.get('parceiros/saldo', {
                headers: {
                    'x-access-token': token,
                }
            })
            setDataSaldo(response.data)
        } catch (error) {
            console.log(error.message)
            if (error?.response?.status === 401) {
                saveParceiro(null)
                navigate('/parceiros/login')
            }
            message.error('Erro ao carregar dados da transação!\n'.concat(error.message))
            // setModalTrasationDetail(false)
        }
        // setLoadLicencas(false)
    }

    async function loadSaldoHistorico() {
        try {
            var response = await api.get('parceiros/saldo/historico', {
                headers: {
                    'x-access-token': token,
                }
            })
            setSaldoHistorico(response.data)
            //  console.log('Saldo', response.data)
        } catch (error) {
            // console.log(error.message)
            message.error('Erro ao carregar saldo!')
        }
    }


    async function pagar() {
        try {
            setLoading(true)
            var pedido = {
                email: parceiro.email,
                token: token
            }

            pedido.itens = [
                {
                    id: 20,
                    descricao: "Crédito Engfood",
                    meses: 0,
                    valor: valueCredito,
                    code: 'C20',
                    nome: 'Crédito Engfood',
                    dias: 0
                }
            ]
            const result = await api.post('/parceiro/credito', pedido, {
                headers: {
                    'x-access-token': token,
                }
            })
            // console.log("Pedido:", result.data)
            var aux = result.data
            setModalCredito(false)

            //NICIO MERCADO PAGO
            var mercadopago = require('mercadopago')
            mercadopago.configure({
                access_token: KEYMP
            });
            var preference = {
                auto_return: "approved"
            }
            var itens = [{
                title: "Crédito Engfood",
                unit_price: parseFloat(valueCredito),
                quantity: 1,
                currency_id: 'BRL',
                id: "C20"
            }]


            var name_sobrename = parceiro.nome.trim().split(' ');
            var telefones = ['', '']
            var telefones = parceiro.fone1.trim().split(' ')
            telefones[0] = telefones[0].replace('(', '').replace(')', '').replace('-', '')
            if (telefones.length > 1)
                telefones[1] = telefones[1].replace('(', '').replace(')', '').replace('-', '')
            else
                telefones[1] = telefones[0].replace('-', '')


            preference.payment_methods = {
                installments: 1,
                default_installments: 1
            }

            preference.items = itens;
            preference.payer = {
                name: name_sobrename[0],
                surname: name_sobrename.length > 1 ? name_sobrename[1] : name_sobrename[0],
                email: parceiro.email,
                phone: {
                    area_code: telefones[0],
                    number: parseInt(telefones[1])
                },

                identification: {
                    type: "CPF",
                    number: parceiro.cpf.replace('.', '').replace('-', '')
                },

                address: {
                    zip_code: '60000000',//dataToken[0].cep ? dataToken[0].cep.replace('-', '') : dataToken[0].cep.replace('-', ''),
                    street_name: parceiro.endereco,
                    // street_number: dataToken[0].numero
                }
            }

            preference.back_urls = {
                success: "https://programaengfood.com.br/Loja/conclusao",
                failure: "https://programaengfood.com.br/Loja/failure",
                pending: "https://programaengfood.com.br/Loja/pending"
            }
            preference.notification_url = BASEURL.concat("notification/retornosmp")
            preference.external_reference = aux.id.toString()

            try {

                let initPref = await api.post('/parceiros/preference', preference, {
                    headers: { 'x-access-token': token }
                })

                var url = "https://programaengfood.com/notificationsite/init/preference?uid=".concat(initPref.data.uid)
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.log('Erro na inicialização das configurações de pagamento!\n'.concat(err.message))
                message.error('Erro na inicialização das configurações de pagamento!\n'.concat(err.message));
            }


        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401) {
                    saveParceiro(null)
                    navigate('/')
                }
            } else {
                message.error(error.message)
            }
        }
        setLoading(false)
    }

    function renderTransacoes() {
        return <div>
            <div style={{ display: 'flex', flexDirection: 'row',
             justifyContent: 'space-between', marginBottom: 10 }}>
                <Space style={{ width: '100%' }}>
                    {
                        /*
                        <Search
                        placeholder="Nome fantasia"
                        allowClear
                        enterButton="Pesquisar"
                        onSearch={(value) => setSeaching(value)}
                         />
                        */
                    }
                    <RangePicker
                        allowClear={false}
                        value={range}
                        onChange={(value) => {
                            // console.log(value)
                            setRange(value)
                            getTransacoes(value)
                        }}
                        format={dateFormat}
                    />

                </Space>

                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    {parceiro?.id > -1 && <Button size='small' shape="circle"
                        type='primary' onClick={() => { setModalCredito(true) }}> + </Button>
                    }
                    <Typography.Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                        {isDesktop && <Space>  {"Saldo: "} {<Tag color={'blue'}>{"R$".concat(dataSaldo?.saldo ? dataSaldo?.saldo : 0)} </Tag>} </Space>}
                    </Typography.Text>

                </div>
                <div>

                </div>
            </div>
            <div>
                <Table dataSource={transacoes} pagination={false} size="small" showHeader={isDesktop}
                    footer={() => <div style={{ display: 'flex', flexDirection: 'row', 
                    justifyContent: 'space-between' }}>
                        {
                            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                                {<Col xs={12} lg={12}>
                                    <Space>
                                        <Typography.Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                                            {'Saldo: '} {<Tag color={'blue'}>{"R$".concat(dataSaldo?.saldo ? dataSaldo?.saldo : 0)} </Tag>}
                                        </Typography.Text>
                                    </Space>
                                </Col>
                                }
                                <Col xs={12} lg={12}
                                    style={{ display: 'flex', justifyContent: 'flex-end', fontSize: 11, fontWeight: 'bold' }}>
                                    Total R$  {total?.toFixed(2)}
                                </Col>
                            </Row>
                        }
                    </div>}>
                    <Column title={<strong>Transação</strong>} dataIndex={"codigo"} key={"codigo"} 
                    style={{ fontSize: 12 }}
                        render={(text, record, index) => {
                            return isDesktop ? <div >
                                <Button type='link' style={{ fontSize: 11 }}
                                    onClick={() => {
                                        //  setTransationSelected(record)
                                        setModalTrasationDetail(true)
                                        getTransacao(record.codigo)
                                    }}> {text} </Button>
                            </div> : <div style={{ fontSize: 11 }}>
                                <div>
                                    <strong> {text} </strong>
                                </div>
                                <div> {record.descricao} </div>
                                <div> {record.fantasia} </div>
                                <div> {record.email} </div>
                                <div> {record.total} </div>
                                <div><Tag color={record.status === 'Paga' ? 'blue' :
                                    record.status === 'Aguardando pagamento' ? 'orange' : 'error'}>{record.status}</Tag></div>
                            </div>

                        }} />
                    {isDesktop && <Column title={<strong>Data</strong>} dataIndex={"data"} key={"codigo"}
                        style={{ fontSize: 11 }}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 11 }}>{dayjs(text).format('DD/MM/YYYY')}</div>

                        }} />
                    }

                    {isDesktop && <Column title={<strong>Descrição</strong>} dataIndex={"descricao"} key={"codigo"}
                        style={{ fontSize: 11 }}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 11 }}>{text}</div>
                        }} />
                    }

                    {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"email"} key={"codigo"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 11 }}>{text}</div>

                        }} />
                    }
                    {isDesktop && <Column title={<strong>Valor</strong>} dataIndex={"total"} key={"codigo"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 11 }}>{text}</div>

                        }} />
                    }

                    {isDesktop && <Column title={<strong>Status</strong>} dataIndex={"status"} key={"codigo"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 11 }}> <Tag color={record.status === 'Paga' ? 'blue' :
                                record.status === 'Aguardando pagamento' ? 'orange' : 'error'}>
                                {record.status}
                            </Tag>
                            </div>
                        }} />
                    }
                </Table>
            </div>
        </div>
    }

    function renderHistoricoCredito() {
        return <div>
            <Table dataSource={saldoHistorico} size={"small"} showHeader={isDesktop}
                expandable={{
                    expandedRowRender: (record) => (
                        <div style={{ fontSize: 10, fontStyle: 'italic' }}>
                            <Table dataSource={record.details} size="small" showHeader={false}
                                pagination={false}>
                                {isMobile && <Column dataIndex={"fantasia"} key={"fantasia"}
                                style={{ fontSize: 11 }}
                                    render={(text, record, index) => {
                                        return <div style={{ fontSize: 11 }}>
                                            <div>
                                                {text}
                                            </div>
                                            <div>
                                                {record?.email}
                                            </div>
                                        </div>
                                    }}
                                />}
                                {isDesktop && <Column dataIndex={"fantasia"} key={"fantasia"}
                                 style={{ fontSize: 11 }}
                                    render={(text, record, index) => {
                                        return text
                                    }}
                                />}
                                {isDesktop && <Column dataIndex={"email"} key={"email"}
                                style={{ fontSize: 11 }}
                                    render={(text, record, index) => {
                                        return text
                                    }}
                                />
                                }
                            </Table>
                        </div>
                    ),
                    rowExpandable: (record) => record?.details.length > 0,
                }}>
                {isMobile && <Column style={{ fontSize: 11 }} title={"Data"} dataIndex={"data"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {dayjs(text).format('DD/MM/YYYY')}
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                                    R${record.valor}
                                    {
                                        parseFloat(record.valor) > 0 ? <ArrowUpOutlined style={{ color: 'green', marginRight: 3 }} /> :
                                            <ArrowDownOutlined style={{ color: 'red', marginRight: 3 }} />}
                                </div>

                            </div>
                            <div>{record.transacao}</div>
                            <div>{record.descricao}</div>

                            <div><strong>SALDO R$: {record.saldo}</strong></div>
                        </div>
                    }} />}
                {isDesktop && <Column style={{ fontSize: 11 }} title={"Data"} dataIndex={"data"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return dayjs(text).format('DD/MM/YYYY')
                    }} />}
                {isDesktop && <Column style={{ fontSize: 11 }} title={"transacao"} dataIndex={"transacao"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return text
                    }} />}
                {isDesktop && <Column style={{ fontSize: 11 }} title={"Descrição"} dataIndex={"descricao"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return text
                    }} />}
                {isDesktop && <Column style={{ fontSize: 11 }} title={"Valor"} dataIndex={"valor"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> {
                            parseFloat(text) > 0 ? <ArrowUpOutlined style={{ color: 'green', marginRight: 3 }} /> :
                                <ArrowDownOutlined style={{ color: 'red', marginRight: 3 }} />} {text}</div>
                    }} />}
                {isDesktop && <Column style={{ fontSize: 11 }} title={"Saldo"} dataIndex={"saldo"} key={"idrepresentantes_saldo"}
                    render={(text, record, index) => {
                        return text
                    }} />
                }
            </Table>
        </div>
    }

    return <div>
        <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Transações" key="1">
                {renderTransacoes()}
            </TabPane>
            <TabPane tab="Historico Crédito" key="2">
                {renderHistoricoCredito()}
            </TabPane>
        </Tabs>


        {
            <Modal visible={modalTrasationDetail}
                footer={null}
                destroyOnClose={true}
                afterClose={() => setTransationSelected({})}
                closable={true}
                onCancel={() => setModalTrasationDetail(false)}>
                <TransationDetail transacao={transationSeleted} setModalTrasationDetail={setModalTrasationDetail} />
            </Modal>
        }

        {
            <Modal visible={modalCredito}
                title={"Adicionar Crédito"}
                destroyOnClose={true}
                closable={true}
                okText={"Pagar"}
                onOk={() => pagar()}
                okButtonProps={{
                    loadding: loadding
                }}
                onCancel={() => setModalCredito(false)}>
                <div>
                    <Space>
                        {" Valor do Crédito R$ "}
                        <InputNumber style={{ marginRight: 5, marginLeft: 5 }}
                            min={10} max={1000}
                            value={valueCredito}
                            onChange={value => setValueCredito(value)}
                        />
                        {/* <Button type='primary' loading={loadding} onClick={() => pagar()}>Pagar</Button>*/}
                    </Space>
                    <Divider />
                    <Row >
                        <Col span={24}>
                            <Checkbox checked={true}>
                                <strong> Estou ciente e de acordo com os prazos de liberação </strong>
                            </Checkbox>
                        </Col>
                        <Col span={24} >
                            <span style={{ fontSize: 12, marginLeft: 10 }}>
                                - Boleto bancário: Até 2 dias úteis (Não é necessário enviar o comprovante).
                            </span>
                        </Col>
                        <Col span={24}>
                            <span style={{ fontSize: 12, marginLeft: 10 }}>
                                - Cartão de Crédito/Débito: Imediata ou em algumas horas após aprovação.
                            </span>
                        </Col>
                    </Row>
                </div>
            </Modal>
        }

        
    </div>

}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransacoesCtrl)