import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Navbar from '../components/Navbar'
import FooterCtrl from '../components/footer'
import '../App.css'
import '../css/main.css'
import '../css/princing.css'
import { Card, Row, Col, Typography, Space, Button, Table, Modal } from 'antd'
import mesasPNG from '../images/mesas.png'
import deliveryPNG from '../images/entregadelivery.jpg'
import balcaoPNG from '../images/balcao2.png'
import '../css/fontawesome-all.min.css'
import ferramentasPNG from '../images/ferramentas.png'

import managerPNG from '../images/manager.png'
import pctickets from '../images/pc-ticket.png'
import PDV from '../images/pdv.png'
import PDV2 from '../images/pdv2.png'


import appdelivery2PNG from '../images/appdelivery2.png'

import { CheckOutlined, QuestionOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { DownloadCtrl } from '../components/download';

import { useGA4React } from "ga-4-react";

const { Meta } = Card;
const { Column } = Table


const data = [
  { item: 'Integração ifood', descricao: 'Acabe com o retrabalho, receba pedidos diretamente no seu programa de gerenciamento.' },
  { item: 'Cardápio QRCode', descricao: 'Auto atendimento para seus clientes fazerem os pedidos na mesa sem o garçom.' },
  { item: 'Site e App Delivery', descricao: 'Tenha seu próprio app de delivery sem pagar taxas adicionais por isso e personalizado com sua marca.' },
  { item: 'Engfood Mobile', descricao: 'Efetue pedidos mesas utilizando Smartphone ou Tablet.' },
  { item: 'Controle de Produtos', descricao: 'Controle total do seu estoque, promoções, combos, insumos, complementos e observações.' },
  { item: 'Sistema de Permissões', descricao: 'Permissões de acesso personalizadas. Defina o que cada usuário pode fazer no programa.' },
  { item: 'Sem custos adicionais', descricao: 'No Engfood você tem acesso a todas as funcipnalidades do sistema sem precisar pagar a mais por isso.' },
  { item: 'Vários Computadores', descricao: 'Com apenas uma licença você pode utilizar em quantos computadores precisar dentro da mesma rede.' }
]


export const Home = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const navigate = useNavigate()

  const ga = useGA4React();
  // console.log(ga);

  useEffect(() => {
    // ga.pageview(window.location.pathname + window.location.search);
  }, [])


  return <div style={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
    <Navbar style={{ marginTop: 0 }} props={props} />

    {
      /*
<section>
      <a href='/planos'>
        {
          isDesktop ? <img src={require('../images/promotion/black-friday.png')}
            alt="black" style={{ width: '100%' }} /> :
            <img src={require('../images/promotion/black-friday-mobile.png')}
              alt="black" style={{ width: '100%' }} />
        }

      </a>
    </section>
      */
    }

    <section className='section1' >
      <img src={ferramentasPNG} alt="ferramentas" style={{ width: '94%', marginLeft: '3%', marginRight: '3%' }} />
    </section>
    <section className='section2'>
      <div style={{ margin: 20 }}>
        <Col style={{ marginTop: 40, marginBottom: 20 }} span={24}>
          <Typography.Text level={3} style={{ fontSize: '1.3em' }}>
            <strong> VÁRIAS FERRAMENTAS, </strong> UM ÚNICO MÓDULO
          </Typography.Text>
        </Col>


        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="Mesas" src={mesasPNG} />}>
              <Meta title="Mesas e Comandas"
                description="Aumente sua produtividade fazendo pedidos utilizando computadores ou celulares." />
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="Delivery" src={deliveryPNG} />}>
              <Meta title="Pedidos Delivery"
                description="Controle estado do pedido, pago, finalizado, qual entregador saiu com o pedido e mais." />
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              style={{ maxWidth: "80%" }}
              hoverable
              cover={<img alt="Balcão" src={balcaoPNG} />}>
              <Meta title="Pedidos Balcão" description="Identifique pelo número do pedido, nome do cliente ou número personalizado.m" />
            </Card>
          </Col>
        </Row>
      </div>
    </section>

    <section className='section3' style={{backgroundColor:'whitesmoke', paddingBottom:30}}>
      <Row >
        <Col span={24} style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography.Text level={3} style={{ fontSize: '1.3em', margin: 20, marginBottom: 20 }}>
            Plataforma de  <strong> Delivery Própria</strong>
          </Typography.Text>
        </Col>
        <Col xs={24} lg={14} style={{ display: 'flex', justifyContent: 'center' }}>
          <img alt='appdelivery' src={appdelivery2PNG}
            style={{ height: 400, marginLeft: '5%', marginRight: '5%' }} />
        </Col>
        <Col xs={24} lg={10}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text style={{ margin: 5 }}>
            Para que dividir o espaço com seus concorrentes se você pode ter um site e aplicativo de delivery só com a sua marca?
            Chega de pagar taxas e comissões a terceiros!
            Receba os pedidos feitos no seu site e aplicativo de delivery diretamente no programa Engfood, assim não perderá tempo em
            lança o pedido no seu sistema.
            Não cobramos comissão por pedido, você terá sua própria ferramenta de delivery com pedidos ilimitados.
            Quando você acostuma seus clientes a pedir delivery nos aplicativos de terceiros, você se torna dependente.
            Seja independente e promova sua marca!
            Elimine a necessidade de múltiplas linhas telefônicas e atendentes. Reduza seu custo de folha e aumente a satisfação de seus clientes.

          </Typography.Text>
        </Col>
      </Row>

    </section>



    <section className='section3'>
      <Row style={{ margin: 20 }}>
        <Col span={24} style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography.Text level={3} style={{ fontSize: '1.3em', marginBottom: 20 }}>
            <strong> Engfood Manager  </strong> Gestão Online
          </Typography.Text>
        </Col>

        <Col xs={24} lg={10}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text style={{ margin: 5 }}>
            Nem sempre é possível estar perto, acompanhado seu negócio, ficando difícil o seu gerencimento.
            Com o Engfood Manager você pode acompanhar os resultados de qualquer lugar, utilizando qualquer dispositivo.
            <br /><br /><br />
            Com o Engfood Manager você passar a ter diversos relatórios e gráficos, para acompanhar o desempenho do seu negócio.
          </Typography.Text>
        </Col>
        <Col xs={24} lg={14} style={{ display: 'flex', justifyContent: 'center' }}>
          <img alt='appdelivery' src={managerPNG}
            style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }} />
        </Col>
      </Row>

    </section>



    <section className='section3' style={{backgroundColor:'whitesmoke'}}>
      <Row >
        <Col span={24} style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography.Text level={3} style={{ fontSize: '1.3em', margin: 20, marginBottom: 20 }}>
            Sistema de <strong> PDV </strong>
          </Typography.Text>
        </Col>
        <Col xs={24} lg={14} style={{ display: 'flex', justifyContent: 'center' }}>
          <img alt='pdv' src={PDV2}
            style={{ height: 350, marginLeft: '5%', marginRight: '5%' }} />
        </Col>
        <Col xs={24} lg={10}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text style={{ margin: 5 }}>
            Agilize as vendas com um sistema de frente de caixa na sua loja, tenha acesso a PDV, Cadastro de Clientes,
            Produtos e Fornecedores. Conte também com recursos avançados: Controle de Estoque, Controle de Caixa,
            Emissão de NFC-e e muito mais!
            <br /><br />
            <ul>
              <li>
                Utilize impressoras de bobina para ganhar mais agilidade no momento da venda
              </li>
              <li>
                Faça vendas direta no caixa em poucos minutos de forma fácil e segura no sistema de frente de caixa do Programa Engfood
              </li>
              <li>
                Faça a abertura do caixa da sua loja em 1 clique, informando apenas o saldo inicial.
              </li>
            </ul>
          </Typography.Text>
        </Col>
      </Row>

    </section>




    <section className='section3'>
      <Row style={{ margin: 20 }}>
        <Col span={24} style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography.Text level={3} style={{ fontSize: '1.3em', marginBottom: 20 }}>
            <strong> Fichas  </strong> de Consumo
          </Typography.Text>
        </Col>

        <Col xs={24} lg={10}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text style={{ margin: 5 }}>
            O Programa Engfood possui o recurso de imprimir fichas de consumação. <br />
            Elas podem ser entregues no bar ou outro ponto para retirada do produto desejado.
            Esse recurso é muito utilizado em festas, boates, festas juninas e muitos outros tipos de eventos.
            Usando essa funcionalidade, o operador de caixa imprime uma ficha e a entrega ao cliente na hora da
            compra. O cliente leva a ficha até o local de retirada e faz a troca da ficha por um produto no bar,
            por exemplo.
            <br /><br /><br />
          </Typography.Text>
        </Col>
        <Col xs={24} lg={14} style={{ display: 'flex', justifyContent: 'center' }}>
          <img alt='tickets pc' src={pctickets}
            style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }} />
        </Col>
      </Row>

    </section>


    <section className='section2'>
      <Row justify='center'>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text level={3} style={{
            fontSize: '1.3em', margin: 20, marginBottom: 20,
            flexDirection: 'column',
            justifyContent: 'center', display: 'flex'
          }}>
            <strong> Pronto para se surpreender? Baixe gratuitamente agora! </strong>
            <span style={{ textAlign: 'center', justifyContent: 'center' }}>TESTE GRATUITAMENTE POR ATÉ 15 DIAS</span>
          </Typography.Text>

        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
          <Button type='primary' size='large' shape='round' danger
            onClick={() => setIsModalVisible(true)}>Baixar Agora</Button></Col>
      </Row>
    </section>








    <section>
      <Table
        style={{ margin: isDesktop ? 50 : 5 }}
        size="large"
        dataSource={data}
        pagination={false} >
        <Column title="OUTRAS FUNCIONALIDADES" dataIndex={"item"} key={"item"}
          render={(text, item, index2) => {
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text strong>{text}</Typography.Text>
              <Typography.Text>{item.descricao}</Typography.Text>
            </div>
          }} />
        <Column title={<Space>
          <img src={require('../images/engfood-round.png')} alt='logo' style={{ height: 48 }} />
          <strong>  ENGFOOD </strong>
        </Space>} dataIndex={"item"} key={"item"}
          width={200}
          render={(text, item, index2) => {
            return <div style={{
              display: 'flex', flexDirection: 'column',
              alignItems: 'center', justifyContent: 'center'
            }}>
              <CheckOutlined style={{ color: 'royalblue', fontSize: 32 }} />
            </div>
          }} />
        {isDesktop && <Column title="CONCORRENTES" dataIndex={"item"} key={"item"}
          width={200}
          render={(text, item, index2) => {
            return <div style={{
              display: 'flex', flexDirection: 'column',
              alignItems: 'center', justifyContent: 'center'
            }}>
              <QuestionOutlined style={{ color: 'gray', fontSize: 32 }} />
            </div>
          }} />
        }

      </Table>
    </section>



{/*
    <section style={{ background: '#8A2BE2' }}>
      <Row justify='center'>
        <Col sm={24} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ margin: 20 }}>
            <Typography.Title level={3} style={{ color: 'white' }}>Quer revender o software Engfood?</Typography.Title>
            <ul style={{ color: 'white' }}>
              <li>Suporte online 7/7</li>
              <li>Base de conhecimento</li>
              <li>Portal da Revenda</li>
              <li>Apoio ao marketing digital</li>
              <li>Migrações de banco de dados em até 48h</li>
            </ul>
            <div style={{ marginLeft: 40 }}>
              <Button size='large' type='primary' shape='round' danger
                onClick={() => navigate('/parceiros/revenda')}>Quero ser um revendedor</Button>
            </div>
          </div>


        </Col>
        <Col sm={24} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={require('../images/revendedor.png')} />
        </Col>
      </Row>
    </section>
      */}

    {/*
    clientes.length > 0 &&
      <section style={{margin:20}}>
        <Typography.Title level={4}>Alguns de nossos clientes</Typography.Title>
        <Space size={[8, 16]} wrap>
          {clientes.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <img src={item.url} height={40} key={index} />
          ))}
        </Space>

      </section>
      */
    }
    {
      /*
      clientes.length > 0 && <Carousel autoplay style={{ backgroundColor: 'red' }}>
        <div>
          <Space style={{ display: 'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center' }}>
            <img alt='logo' src={clientes[0].url} height={45} width={45} />
            <img alt='logo' src={clientes[1].url} height={45} width={45} />
          </Space>

        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
      </Carousel>
      */
    }

    <FooterCtrl />

    <Modal title="Download Programa Engfood"
      closable={true}
      width={600}
      footer={null}
      visible={isModalVisible}
      destroyOnClose={true}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}>
      <DownloadCtrl setIsModalVisible={setIsModalVisible} />
    </Modal>


  </div>








};

//export default Home;

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => ({
  // saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
