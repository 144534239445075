import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const EngfoodMobile = (props) => {

    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/engfood-mobile-2/');
    },[])

    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                <Typography.Title level={4}>
                    Engfood Mobile <br />
                    <span style={{ fontSize: 13, fontWeight: 'normal' }}>
                        Atualizado em 30/05/2020
                    </span>
                </Typography.Title>
                <br />
                <div style={{ color: '#696969', fontSize: 14 }}>
                    O Programa Engfood disponibiliza as opções de você efetuar pedidos pelo garçom ou até mesmo de seus clientes fazerem pedidos direto pelos celulares via QR Code disponibilizado nas mesas. Veja abaixo como efetuar essa configuração.
                </div>

                <br />
                <Typography.Title level={5}>Instalação</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Para instalar o Engfood Mobile é necessário possuir a versão Desktop 11.2.7 ou superior instalada em seu computador. Acesse o menu integrações/Mobile e clique em instalar e aguarde. Esse processo de instalação deve demorar alguns minutos e você pode acompanhar tudo pelo LOG de instalação.
                </div>
                <img src={require('../../images/manual/img0.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                <img src={require('../../images/manual/img2.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                <br />
                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="É necessário que seu computador esteja conectado à internet para efetuar essa configuração/instalação."
                    type="warning"
                    showIcon
                />
                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="Mesmo que tenha baixado e instalafo a versão Android direto da loja Google Play é necessário fazer a instação do Engfood Mobile em seu computador."
                    type="warning"
                    showIcon
                />
                <Alert
                    style={{ marginTop: 10 }}
                    message="Dica!"
                    description="É interessante realizar a instalação junto a um técnico de informática local, o qual possa lhe ajudar a fixar o IP do servidor. Tal passo é muito importante, uma vez que isso garante que seu endereço IP não mude e você não precise reimprimir, por exemplo, os displays de mesa que serão necessários para que o cliente faça a leitura do QrCode e visualize o cardápio."
                    type="warning"
                    showIcon
                />
                <br />


                <br />
                <Typography.Title level={5}>Comanda e Monitor Mobile</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Após concluida a instalação você poderá utilizar qualquer aparelho conectado a sua rede interna para fazer pedidos ou gerenciar os status dos produtos enviados para o setor de produção como por exemplo a cozinha.
                </div>
                <br />
                <img src={require('../../images/manual/comanda-mobile.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                <br />
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Ao clicar na URL exibida que para este exemplo foi http://192.168.30.195:3000 deve ser aberto em seu navegador a tela de login do sistema.
                </div>
                <br />
                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="A URL exibida vai depender da rede interna de cada estabelecimento, com isso cada computador terá uma URL diferente."
                    type="warning"
                    showIcon
                />
                <br />
                <Typography.Title level={5}>Cardápio QR Code na Mesa</Typography.Title>
                <br />
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Depois de instalado o Engfood Mobile e configurado com IP FIXO, é hora de imprimir o QR Code para fixar nas mesas. Dessa forma, seus clientes podem ler os códigos QR Code com os celulares e efetuarem os pedidos.
                </div>
                <br />
                <img src={require('../../images/manual/img1.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                <br />
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Acesse o menu Integrações/Mobile clique na opção configurar, selecione a aba Cardápio QR Code e selecione "Clique para imprimir os QR Code"
                </div>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <br />
                            <br />
                            Após sentar na mesa, o cliente poderá ler o QR Code disponibilizado para visualizar seu Cardápio e/ou fazer pedidos. Os pedidos serão automaticamente impressos nos setores de produção e balcão.
                            <br />
                            <br />
                            Quando o cliente iniciar visualização do cardápio, será impresso a senha de confirmação de pedidos no balcão. Essa senha deve ser entregue ao cliente para que ele consiga efetuar os pedidos, uma vez que ela será exigida na confirmação dos pedidos do cliente
                            <br />
                            <br />
                            É recomendado testar o novo fluxo de atendimento do seu negócio aos poucos. Inicialmente, em apenas uma ou duas mesas e ir acompanhando o feedback dos clientes enquanto continua alinhando e treinando sua equipe para que dominem as rotinas. Em seguida, é só aplicar a solução em um número maior de mesas.
                            <br />
                            <br />
                            <div>
                                Temos também uma arte que foi produzida para testar a solução. Se você desejar, pode fazer o
                                <a href='/download/arte.zip' target={"_blank"}> download aqui </a> (arquivo ZIP) e imprimir em uma
                                gráfica com as seguintes orientações: Papel Couchê 180G, Colorido, Fosco.
                            </div>
                        </div>
                        <img src={require('../../images/manual/mesa10.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: 300, marginTop:20 }} />
                    </div>
                </div>


            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 20 }}>
                <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
            </div>       <br /><br />

            <FooterCtrl />
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EngfoodMobile)