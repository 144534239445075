import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import NavbarCtrl from '../../components/Navbar'
import FooterCtrl from '../../components/footer'
import { Typography, Row, Col,  Space } from 'antd'
import { isDesktop } from 'react-device-detect'
import { BookOutlined } from '@ant-design/icons'

export const ManualPage = (props) => {


    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/');
    },[])


    return (
        <div>
            <NavbarCtrl />
            <div style={{ background: 'whitesmoke', marginTop: 0, display: 'flex', flexDirection: 'column' }}>
                <Typography.Title level={4} style={{ marginLeft: isDesktop ? 40 : 10, marginTop: 20 }}>
                    Manual Programa Engfood <br />
                    <span style={{ fontSize: 14, color: 'darkgray', fontWeight: 'normal' }}> Tutorias em artigos e videos. </span>
                </Typography.Title>

            </div>

            <div style={{ marginLeft: isDesktop ? 50 : 10, marginRight: isDesktop ? 50 : 10 }}>
                <Row gutter={[0, 0]}>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}> Aplicativos Mobile </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='/manual/app-site-delivery'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Meu aplicativo de Delivery</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='/manual/app-garcom'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Pedidos Engfood (App para garçons)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='/manual/engfood-mobile'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Engfood Mobile</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>

                                <Typography.Title level={5} style={{ color: 'royalblue' }}>  Ifood </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='/manual/integracao-ifood'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Integração com iFood</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}>  Impressoras </Typography.Title>
                            </Col>
                            <Col>
                                <Typography.Link href='/manual/instalar-impressora-generic-text'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>
                                            Como Instalar uma Impressora com Driver Genérico no Windows?
                                        </Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col>
                                <Typography.Link href='#'>
                                    <Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Imprimir produto no setor de produção</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}>  Instalação </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Configurar balança</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Eu preciso de internet para instalar o Programa?</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Instalação do Programa Engfood</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Pedidos Engfood(App para garçons)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='/manual/sistema-em-rede'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Utilizar o sistema em rede (2 ou + computadores)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}> Produtos </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Como cadastrar os complementos?</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Ficha Técnica de Produtos</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Imprimir produto no setor de produção</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}>  Vendas e Pedidos </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Fichas de Consumo (Tickets)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Taxas e Descontos</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Emitir Cupom Fiscal (NFC-e)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>EComo Utilizar Mapas (Google Maps)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Alterar Taxa de Serviços</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row style={{marginTop:20}}>
                            <Col span={24}>
                                <Typography.Title level={5} style={{ color: 'royalblue' }}>  NFC-e/SAT/MFE </Typography.Title>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Emitir Cupom Fiscal (NFC-e)</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                            <Col span={24}>
                                <Typography.Link href='#'>
                                    <Space>
                                        <BookOutlined />
                                        <Typography.Text style={{ fontSize: 14 }}>Configurar as formas de pagamento</Typography.Text>
                                    </Space>
                                </Typography.Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <br />
            <br />

            <FooterCtrl />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ManualPage)