import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const AppGarcom = (props) => {


    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/engfood-mobile-2/');
    },[])


    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                <Typography.Title level={4}>
                    Aplicativos de Pedidos (mesas/comandas) <br />
                    <span style={{ fontSize: 13, fontWeight: 'normal' }}>
                        Atualizado em 30/05/2020
                    </span>
                </Typography.Title>

                <br />
                <Typography.Title level={5}>Iniciando</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    O Programa Engfood disponibiliza um aplicativo gratuito integrado ao sistema para agilizar o sistema de pedidos realizados pelo garçom para mesas e comandas.
                    <br />
                    O aplicativo se encontra disponível na loja Google Play.
                    <br />
                    Para efetuar sua instalação acesse a loja Google Play pelo seu smartphone ou tablet Android e procure por Pedidos Engfood e efetue download e instalação.
                </div>
                <br /> <br />
                <Typography.Title level={5}>Configurando o aplicativo.</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                Para que o aplicativo funcione precisamos de algumas é necessário que você tenha um computador com o Programa Engfood instalado e conectado na mesma rede que seu dispositivo Android que possui o Pedidos Engfood.
                 <br />
                 É muito importante também que você configure seu computador para funcionar com IP fixo isso garante que ao ligar o computador ele não vai ficar alterando seu endereço na rede e permitindo assim a comunicação permanente com o app.
                 <br/>
                 Vamos primeiro descobrir o IP do computador que possui o Programa Engfood instalado, esse IP é exibido a tela principal do sistema como na imagem abaixo.
                 <br/>
                    <img src={require('../../images/manual/inicial.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br />
                    Agora abra o app Pedidos Engfood e clique em setup, informe o IP do computador e selecione o tipo de atendimento que será feito, mesas ou comandas, agora clique em salvar.
                    <br />
                    <img src={require('../../images/manual/pedidos_engfood.png')} alt="ativacao" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    <br />
                    O nome de usuário é qualquer um cadastrado no Engfood Desktop(computador) e sua respectiva senha, agora é só clicar em Login e começar a fazer os pedidos pelo aplicativo.

                </div><br />

                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="Para que o aplicativo funcione o computador tem que está sempre com o Programa Engfood aberto e conectado na mesma rede do dispositivo Android."
                    type="warning"
                    showIcon
                />
                <br />              

            </div>


            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 20 }}>
                <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
            </div>       <br /><br />

            <FooterCtrl />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AppGarcom)