import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Button, Typography, Modal, Table, Space, Select, Input, Tag } from 'antd'
import { api } from '../../../service/api'
import '@inovua/reactdatagrid-community/index.css';
import * as parceiroAction from '../../../store/actions/parceiro.action'
import { isDesktop } from 'react-device-detect';
import { RegisterCtrl } from '../../../components/register'
import ClienteCtrl from '../../../components/parceiros/clienteCtrl'
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const { Column } = Table
const { Search } = Input
const { Option } = Select

export const ClientesPage = (props) => {

  const navigate = useNavigate()

  const { parceiro, saveParceiro, token } = props
  const [status, setStatus] = useState(props?.status ? props.status : "Todos")
  const [searching, setSeaching] = useState('')

  const [modalRegister, setModalRegister] = useState(false)
  const [contratoSelecionado, setContratoSelecionado] = useState({})
  const [modalAccount, setModalAccount] = useState(false)



  useEffect(() => {
    //  console.log('props', props)
  }, [status, searching])


  async function loadParceiro() {
    try {
      //  setLoading(true)
      // setmsgError('')
      const result = await api.get('/parceiros', {
        headers: {
          'x-access-token': token,
        }
      })
      if (result.status === 200 && result.data?.token) {
        saveParceiro(result.data)
        // navigate('/parceiros')
      } else {
        // setmsgError('Erro ao carregar dados!')
      }

    } catch (error) {
      console.log('ERROR', error.message)
      //  setmsgError(error.message)
    }
    //  setLoading(false)
  }



  function getClienteFilter() {
    try {
      var dados = parceiro.clientes
      if (status !== 'Todos')
        dados = parceiro.clientes.filter((item) => item.situacao === status)
      if (searching.trim() !== '')
        dados = dados.filter((item) => item.fantasia.toLowerCase().indexOf(searching.toLowerCase()) > -1)
      return dados
    } catch (error) {
      return []
    }
  }


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Space>
          <Search
            placeholder="Nome fantasia"
            allowClear
            enterButton={isDesktop ? "Pesquisar" : 'OK'}

            onSearch={(value) => setSeaching(value)}
          />

          <Select value={status} onChange={(value) => setStatus(value)} style={{ width: 110 }}>
            <Option value={'Inativo'}>Inativos</Option>
            <Option value={'Ativo'}>Ativos</Option>
            <Option value={'Todos'}>Todos</Option>
          </Select>

        </Space>
        <div>
          {parceiro?.id > -1 && <Button type='primary' size='small' shape='circle'
            onClick={() => { setModalRegister(true) }}> + </Button>
          }
        </div>
      </div>
      <div>
        <Table
          dataSource={getClienteFilter()}
          pagination={false}
          size="small"
          showHeader={isDesktop ? true : false}
          footer={() => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
              <Col xs={12} lg={6}><Tag color={'blue'}><Typography.Text> Ativos: {getClienteFilter().filter(x => x.situacao === "Ativo").length}</Typography.Text> </Tag></Col>
              <Col xs={12} lg={6}><Tag color={'error'}><Typography.Text>Inativos: {getClienteFilter().filter(x => x.situacao !== "Ativo").length}</Typography.Text></Tag></Col>
              <Col xs={12} lg={6}><Typography.Text>Total: {getClienteFilter().length}</Typography.Text></Col>
              <Col xs={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}> </Col>
            </Row>

          </div>}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                navigate('/parceiros/cliente', {
                  state: {
                    contratoSelecionado: record
                  }
                })
              }, // click row
            };
          }}>
          <Column title={isDesktop ? <strong>Fantasia</strong> : ''}
            dataIndex={"fantasia"} key={"tok_email"}
            render={(text, record, index) => {
              return isDesktop ? <Button type='link' onClick={() => {
                // setContratoSelecionado(record)
                // setModalAccount(true)
                navigate('/parceiros/cliente', {
                  state: {
                    contratoSelecionado: record
                  }
                })
              }}> {text} </Button> : <div
                style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                <div style={{ color: 'royalblue', fontSize: 12 }} onClick={() => {
                  navigate('/parceiros/cliente', {})
                  // setContratoSelecionado(record)
                  //  setModalAccount(true)
                }}>

                  <strong style={{ textTransform: 'uppercase' }}> {text} </strong>
                </div>
                <div style={{ wordBreak: 'break-all', textTransform: 'lowercase' }}> {record.tok_email} </div>
                <div><Tag color={record.situacao === 'Ativo' ? 'blue' : 'error'}>{record.situacao}</Tag></div>
              </div>

            }} />
          {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"tok_email"} key={"tok_email"}
            render={(text, record, index) => {
              return <div style={{ fontSize: 12 }}>{text}</div>

            }} />
          }
          {isDesktop && <Column title={<strong>Situação</strong>} dataIndex={"situacao"} key={"tok_email"}
            render={(text, record, index) => {
              return <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                <RightOutlined style={{ color: 'gray' }} />
                <Tag style={{ fontSize: 12 }} color={text === 'Ativo' ? 'blue' : 'error'}>{text}</Tag>
              </div>
            }} />
          }
        </Table>
      </div>

      {
        <Modal visible={modalRegister}
          width={isDesktop ? '80%' : '100%'}
          afterClose={() => {
            {
              setContratoSelecionado({})
              loadParceiro()
            }
          }}
          footer={null}
          destroyOnClose={true}
          closable={true}
          onCancel={() => setModalRegister(false)}>
          <RegisterCtrl parceiroID={parceiro.id}
            setModalRegister={setModalRegister}
            cliente={contratoSelecionado} />
        </Modal>
      }

      {
        <Modal visible={modalAccount}
          width={isDesktop ? '80%' : '100%'}
          footer={null}
          destroyOnClose={true}
          afterClose={() => {
            setContratoSelecionado({})
            loadParceiro()
          }}
          closable={true}
          onCancel={() => setModalAccount(false)}>
          <ClienteCtrl parceiroID={parceiro.id}
            setModalAccount={setModalAccount}
            cliente={contratoSelecionado}
            setModalRegister={setModalRegister} />
        </Modal>
      }

    </div>
  )
}

const mapStateToProps = (state) => ({
  parceiro: state.parceiroReducer.parceiro?.parceiro,
  token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
  saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientesPage)