import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import mercadopagoImg from '../../images/mercadopago.png'
import { Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { api } from '../../service/api'
import { useNavigate } from 'react-router-dom'

const MP_ID = "23856490837356"
const CLIENT_SECRET = "h4TuM07DnzfltCpso7ftEQv2bGmStvH2uEV6jOcajcUrTk2Wf7IsGYiEmBEMsRyqLCNuCAn9OILfChM7S6eZ1HxicosSGDOc"


export const AuthorizationCode = (props) => {

    const { token } = props
    const navigate = useNavigate()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const path = urlParams.get('path');
        if (code) {
            gerarCredencial(code, path)
        } else {
            window.location.replace('https://portal.menuengfood.com.br/formas-de-pagamento')
            //   props.history.push('/formas-de-pagamento')
            // setNotification('Eu ao gerar credenciais!', 'error')
        }
        //  console.log(myParam)
    }, [])


    async function gerarCredencial(code, path) {
        try {

            const response = await axios.post('https://api.mercadopago.com/oauth/token',
                {
                    client_secret: CLIENT_SECRET,
                    client_id: MP_ID,
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: 'https://programaengfood.com.br',
                    code: code,

                })


            const response2 = await api.post("/api/v2.0/formas-de-pagamento/credential-mp",
                response.data, {
                headers: {
                    'x-access-token': token,
                }
            })


            //setNotification('As credenciais foram salva com sucesso', 'success')
            navigate('/formas-de-pagamento')

            /*
            var result = await api.post('/api/v2.0/formas-de-pagamento/mp_create_credencial',
                {

                }
                , {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        client_secret: CLIENT_SECRET,
                        client_id: MP_ID,
                        grant_type: 'authorization_code',
                        code: code,
                        redirect_uri: 'https://portal.menuengfood.com.br/formas-de-pagamento',
                        code: code,

                    }
                })
            setNotification('As credenciais foram salva com sucesso', 'success')
                */
        } catch (error) {
            console.log('error: ', error.message)
            // setNotification(error.message, 'error')
        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, textAlign: 'center' }}>
                <Image height={150} width={150}
                    preview={false}
                    alt="mercadopago" src={mercadopagoImg} />
                <i><LoadingOutlined /> Processando...</i>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.tokenReducer.token,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationCode)



