import React, {  useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Alert, Divider } from 'antd'
import Navbar from '../../components/Navbar'
import Footer from '../../components/footer'
import { useNavigate, useLocation } from 'react-router-dom'

export const ConclusaoPage = (props) => {

    const location = useLocation()
    const navigate = useNavigate()


    useEffect(() => {
       var split = location.search.replace('?','').split('&')
       console.log(split)
    }, [])


    return (
        <div>
            <Navbar />
            <section>
                <div style={{ margin: 40 }}>
                    <Alert
                        message="Seu pagamento está sendo processado."
                        description={<span>Seu pagamento está sendo processado por nossa equipe e assim que
                            confirmado pelo <b>Mercadopago</b>, você receberá informações via e-mail ou ligação
                            telefonica para resgatar sua compra.</span>}
                        type="info"
                        showIcon
                    />

                    <Divider />

                    <strong style={{ color: 'royalblue' }}>PRAZOS:</strong>
                    <div style={{ fontSize: 13 }}>
                        <b> Boleto Bancário: </b> Até 3 dias úteis, como o processo de validação é automático NÃO é necessário enviar o comprovante.
                    </div>
                    <div style={{ fontSize: 13 }}>
                        <b>Cartão de Débito/Crédito/PIX: </b> Liberação imediata ou em algumas horas após aprovação pela operadora, qualquer dúvida relacionada,
                        você também pode verificar junto a sua operadora de cartão.
                    </div>

                    <br /><br />
                    <Button type='link' onClick={() => navigate(-2)}
                        style={{ fontSize: 13 }}>Ir a página principal
                    </Button>

                </div>
            </section>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConclusaoPage)