import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Navbar from '../../components/Navbar'
import FooterCtrl from '../../components/footer'
import '../../App.css'
import {
    Card, Row, Col, Typography, Space, Button, Divider, Form,
    Tag, Table, Alert, Select, Modal, Upload, message
} from 'antd'
import { DownloadOutlined, EditOutlined, UploadOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import '../../css/fontawesome-all.min.css'
import * as userAction from '../../store/actions/user.action'
import * as moment from 'moment'
import { api, BASEURL, BASEURLRAIZ } from '../../service/api'
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom'
import { DownloadCtrl } from '../../components/download'
import { isDesktop } from 'react-device-detect'
import { KEYMP } from '../../Constantes'

const { Column } = Table
const { Option } = Select

export const AccountPage = (props) => {

    const navigate = useNavigate()
    const { user, saveUser, token } = props
    const [isModalApp, setIsModalApp] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [urlLogo, setUrlLogo] = useState(undefined)

    const [isModalNfce, setIsModalNfce] = useState(false)
    const [selected, setSelected] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        //  console.log("USER: ",user)
        load()
    }, [])

    async function load() {
        try {
            const result = await api.get('/restaurant', {
                headers: {
                    'x-access-token': token,
                }
            })
            saveUser(result.data)
        } catch (error) {
            console.log(error.message)
            navigate("/login")
            // NotificationManager.error(error.message, '', 5000, () => { });
        }
    }

    const props2 = {
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                console.log('Você só pode fazer upload de imagem JPG/PNG!')
                message.error('Você só pode fazer upload de imagem JPG/PNG!');
            }
            const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
            if (!isLt2M) {
                console.log('A imagem deve ser menor que 3MB!')
                message.error('A imagem deve ser menor que 3MB!');
            }
            console.log('beforeUpload', isJpgOrPng, isLt2M)
            return isJpgOrPng && isLt2M;
        },
        onChange: (info) => {
            console.log(info.fileList);
            if (info.file.status === 'uploading') {
                //  setLoading(true)
                return;
            }
            if (info.file.status === 'done') {
                // console.log('info', BASEURL.concat(info.fileList[0].response.filename))
                setUrlLogo(BASEURL.concat('logo/', info.fileList[0].response.filename))
                // message.info('Logomarca enviada com sucesso!');
                NotificationManager.info("Logomarca enviada com sucesso! Aguarde prazo de até 5 dias úteis para site e/ou app ficarem prontos!", '', 10000, () => { });
                setIsModalApp(false)
            }

            if (info.file.status === 'error') {
                NotificationManager.error(info.file.response.error, '', 10000, () => { });
            }

        },
    };


    function getTipoLicenca(dias) {
        var licenca = 'Mensal'
        switch (dias) {
            case 365: licenca = 'Anual'; break;
            case 180: licenca = 'Semestral'; break;
            case 90: licenca = 'Trimestral'; break;
            case 30: licenca = 'Mensal'; break;
            default: licenca = 'desconhecida';

        }
        return licenca;
    }

    function checkLicencavalida(item) {
        try {
            var atual = moment()
            var inicial = moment(item.data1)
            var final = moment(item.data2)

            var h1 = atual.diff(inicial, 'hours') // tem que ser maior >= 0
            var h2 = final.diff(atual, 'hours'); //tem que ser maior >= 0           

            if (h1 >= 0 && h2 >= 0) {
                item.canbaixar = true
                return <Tag color="#108ee9"> Ativa </Tag>
            }
            else if (h2 > 0) {
                item.canbaixar = true
                return <Tag color={'processing'}> Aguardando - essa licença inicia no dia {moment(item.data1).format('DD/MM/YYYY')}</Tag>;
            } else {
                item.canbaixar = false
                return <Tag color={'red'}> Inativa </Tag>;
            }
        } catch (error) {
            item.canbaixar = false
            return <Tag color={'red'}> Inválida </Tag>
        }
    }


    function getStatus(status) {
        if (status === 'Paga')
            return <Tag color={'success'}>{status}</Tag>
        else if (status === 'Cancelada')
            return <Tag color={'volcano'}>{status}</Tag>
        else if (status === 'Aguardando pagamento')
            return <Tag color={'default'}>{status}</Tag>
        else if (status === 'Devolvida')
            return <Tag color={'volcano'}>{status}</Tag>
        else if (status === 'Retenção Temporária')
            return <Tag color={'volcano'}>{status}</Tag>
        else
            return <Tag color={'default'}>{status}</Tag>
    }



    async function pagarFiscal(item, descricao, valor, dias, licencaid) {
        try {
            setLoading(true)
            var pedido = {
                email: item.tok_email,
                token: token
            }

            pedido.itens = [
                {
                    id: 30,
                    descricao: descricao,
                    meses: 0,
                    valor: valor,
                    code: 'F'.concat(dias),
                    nome: descricao,
                    dias: dias,
                    licencaid: licencaid,
                    email: item.tok_email
                }
            ]

            const result = await api.post('/parceiro/pedido-fiscal-manager', pedido, {
                headers: {
                    'x-access-token': token,
                }
            })
            var aux = result.data
            setIsModalNfce(false)

            //NICIO MERCADO PAGO
            var mercadopago = require('mercadopago')
            mercadopago.configure({
                access_token: KEYMP
            });
            var preference = {
                auto_return: "approved"
            }
            var itens = [{
                title: descricao,
                unit_price: valor,
                quantity: 1,
                currency_id: 'BRL',
                id: "F".concat(dias)
            }]


            var name_sobrename = user.responsavel.trim().split(' ');
            var telefones = ['', '']
            telefones = user.fone1.trim().split(' ')
            telefones[0] = telefones[0].replace('(', '').replace(')', '').replace('-', '')
            if (telefones.length > 1)
                telefones[1] = telefones[1].replace('(', '').replace(')', '').replace('-', '')
            else
                telefones[1] = telefones[0].replace('-', '')


            preference.payment_methods = {
                installments: 1,
                default_installments: 1
            }

            preference.items = itens;
            preference.payer = {
                name: name_sobrename[0],
                surname: name_sobrename.length > 1 ? name_sobrename[1] : name_sobrename[0],
                email: user.tok_email,
                phone: {
                    area_code: telefones[0],
                    number: parseInt(telefones[1])
                },

                identification: {
                    type: "CPF",
                    number: user.cpf.replace('.', '').replace('-', '')
                },

                address: {
                    zip_code: '60000000',//dataToken[0].cep ? dataToken[0].cep.replace('-', '') : dataToken[0].cep.replace('-', ''),
                    street_name: user.endereco,
                    // street_number: dataToken[0].numero
                }
            }

            preference.back_urls = {
                success: "https://programaengfood.com.br/Loja/conclusao",
                failure: "https://programaengfood.com.br/Loja/failure",
                pending: "https://programaengfood.com.br/Loja/pending"
            }
            preference.notification_url = BASEURL.concat("notification/retornosmpfiscalmanager")
            preference.external_reference = aux.id.toString()

            try {
                /*
                                let initPref = await api.post('/parceiros/preference', preference, {
                                    headers: { 'x-access-token': token }
                                })
                */
                const result = await api.post('/user/pedido', pedido, {
                    headers: {
                        'x-access-token': token,
                    }
                })
                var aux = JSON.parse(JSON.stringify(result.data))
                var url = "https://programaengfood.com.br/notificationsite/init?external_reference=".concat(aux.id)
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.log('Erro na inicialização das configurações de pagamento!\n'.concat(err.message))
                message.error('Erro na inicialização das configurações de pagamento!\n'.concat(err.message));
            }


        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401) {
                    //   saveParceiro(null)
                    // navigate('/')
                }
            } else {
                message.error(error.message)
            }
        }

        setLoading(false)
    }





    function renderApp() {
        if (user.appdelivery?.ativo === undefined) {
            return <Alert
                message={null}
                description="Você ainda não possui a plataforma de delivery configurada."
                type="info"
                showIcon
                action={
                    <Button shape='round' type='primary' onClick={() => setIsModalApp(true)}>
                        Solicitar plataforma
                    </Button>
                }

            />
        } else {
            return <Alert
                message={null}
                description={"Seu sistema de delivery App e Site estão..."}
                type={user.appdelivery?.ativo === 0 ? "warning" : "info"}
                showIcon
                action={
                    <Select
                        style={{ width: 150 }}
                        size='small'
                        value={user?.appdelivery?.ativo}
                        onChange={(e) => {
                            var item = JSON.parse(JSON.stringify(user.appdelivery))
                            item.ativo = e
                            updateApp(item)

                        }}>
                        <Option value={0}>Desativado</Option>
                        <Option value={1}>Ativado</Option>
                    </Select>
                }

            />
        }
    }


    async function updateApp(app) {
        try {
            const result = await api.put('/restaurant/app', app, {
                headers: {
                    'x-access-token': token,
                }
            })
            console.log(result)
            if (result.status === 200) {
                NotificationManager.info("Dados atualizados com successo", '', 3000, () => { });
                //  console.log(result.data)
                saveUser(result.data)
            }
        } catch (error) {
            NotificationManager.error(error.message, '', 5000, () => { });
        }
    }


    async function downloadLicense(englicenca) {
        try {
            console.log(englicenca)

            const result = await api.get("downloadLicenca?englicenca=".concat(englicenca), {
                headers: {
                    'x-access-token': token,
                }
            })
            if (result.status === 200) {
                const link = document.createElement('a');
                link.href = BASEURLRAIZ.concat(result.data);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // F:/express/engfoodsite/license/011/licenca engfood 1610.eng
            }
        } catch (error) {
            NotificationManager.error(error.message, '', 5000, () => { });
        }
    }

    function renderLicencas(item, index) {
        if (isDesktop) {
            return <div style={{ display: 'flex', flexDirection: 'column' }} key={'trans-'.concat(index)}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', width: 100
                        }}>
                            <img alt="licença" src={require('../../images/license_key.png')} />
                            <Tag color="#108ee9">{getTipoLicenca(item.dias)}</Tag>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }}>
                            <Space>
                                <Typography.Text>Validade: </Typography.Text>
                                <Typography.Text> {moment(item.data1).format('DD/MM/YYYY')} </Typography.Text>
                                <Typography.Text> até </Typography.Text>
                                <Typography.Text> {moment(item.data2).format('DD/MM/YYYY')} </Typography.Text>
                            </Space>
                            <Space>
                                Status:
                                {checkLicencavalida(item)}
                            </Space>
                            <Space>
                                <img alt="pc" src={require('../../images/display.png')} />
                                {item.fiscalmanager === 1 && <Tag color={'#108ee9'}>
                                    + Fiscal NFC-e/SAT
                                </Tag>}
                            </Space>
                            {(item.fiscalmanager !== 1 && item.canbaixar) && <Space>
                                <Button icon={<img alt='nfce' style={{ marginRight: 5 }}
                                    src={require('../../images/danfe.png')} height={16} />}
                                    style={{ marginTop: 5 }} type='primary' danger
                                    onClick={() => {
                                        setSelected(item)
                                        setIsModalNfce(true)
                                    }}> Quero emitir NFC-e</Button>
                            </Space>
                            }
                        </div>

                    </div>
                    <div>
                        {
                            item.canbaixar && <Button type='default' size='large'
                                onClick={() => downloadLicense(item.transacao)}
                                icon={<DownloadOutlined style={{ fontSize: 20 }} />}>
                                Baixar licença
                            </Button>
                        }
                    </div>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 9 }} />
            </div>
        } else {
            return <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                <Space>
                    <img alt="licença" src={require('../../images/license_key.png')} />
                    <Tag color="#108ee9">{getTipoLicenca(item.dias)}</Tag>
                </Space>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>
                    <Typography.Text>Validade: </Typography.Text>
                    <Space>
                        <Typography.Text> {moment(item.data1).format('DD/MM/YYYY')} </Typography.Text>
                        <Typography.Text> até </Typography.Text>
                        <Typography.Text> {moment(item.data2).format('DD/MM/YYYY')} </Typography.Text>
                    </Space>
                    Status:
                    <Space>
                        {checkLicencavalida(item)}
                    </Space>
                    <Space>
                        <img alt="pc" src={require('../../images/display.png')} />
                        {item.fiscalmanager === 1 && <Tag color={'#108ee9'}>
                            + Fiscal NFC-e/SAT
                        </Tag>}
                    </Space>
                </div>


                <div>
                    {
                        item.canbaixar && <Button type='default' size='large'
                            onClick={() => downloadLicense(item.transacao)}
                            icon={<DownloadOutlined style={{ fontSize: 20 }} />}>
                            Baixar licença
                        </Button>
                    }

                </div>

                <Divider style={{ marginTop: 10, marginBottom: 9 }} />
            </div>
        }
    }

    function renderTablePagamento() {
        if (isDesktop) {
            return <Table
                pagination={false}
                dataSource={user?.pagamentos}
                rowKey={'codigo'}
                size="small">
                <Column title="Data" dataIndex={"data"} key={"data"}
                    render={(text, item, index2) => {
                        return moment(text).format('DD/MM/YYYY')
                    }} />
                <Column title="Transação" dataIndex={"codigo"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Descrição" dataIndex={"descricao"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Valor" dataIndex={"total"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Status" dataIndex={"status"} key={"codigo"}
                    render={(text, item, index2) => {
                        return getStatus(text)
                    }} />
            </Table>
        } else {
            return <Table
                pagination={false}
                dataSource={user?.pagamentos}
                size="small">
                <Column title="Dados" dataIndex={"data"} key={"data"}
                    render={(text, item, index2) => {
                        return <div>
                            <div>
                                {moment(text).format('DD/MM/YYYY')}
                            </div>
                            <div>
                                {item.codigo}
                            </div>
                            <div>
                                {item.descricao}
                            </div>
                            <div>
                                {item.total}
                            </div>
                            <div>
                                {getStatus(item.status)}
                            </div>
                        </div>
                    }} />
            </Table>
        }
    }



    return <div style={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
        <Navbar style={{ marginTop: 0 }} props={props} />
        {user && <div>
            <Row style={{ margin: isDesktop ? 50 : 0 }}>
                <Col span={24}>
                    <Card title={<strong> ESTABELECIMENTO </strong>}
                        extra={<Button type='default' size="large"
                            icon={<EditOutlined style={{ fontSize: 20 }} />} style={{ minWidth: 170 }}
                            onClick={() => {
                                navigate('/register')
                            }}>Alterar dados</Button>}
                        style={{ backgroundColor: 'whitesmoke' }}>
                        <Row gutter={[4, 2]}>
                            <Col xs={24} lg={4}>
                                Fantasia:
                            </Col>
                            <Col xs={24} lg={20}>
                                <Typography.Text style={{ color: 'royalblue' }}>  {user.fantasia} </Typography.Text>
                            </Col>
                            <Col xs={24} lg={4}>
                                Razão Social:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.razao_social}
                            </Col>
                            <Col xs={24} lg={4}>
                                Telefone Principal:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.fone1}
                            </Col>
                            <Col xs={24} lg={4}>
                                CNPJ:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cnpj}
                            </Col>
                            <Col xs={24} lg={4}>
                                Local:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cidade.concat(' - ', user.estado)}
                            </Col>
                            <Col xs={24} lg={4}>
                                E-mail:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.tok_email}
                            </Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col span={24}><strong>DADOS DO RESPONSÁVEL</strong></Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col xs={24} lg={4}>
                                Nome:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.responsavel}
                            </Col>
                            <Col xs={24} lg={4}>
                                CPF:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cpf}
                            </Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col span={24}><strong>PLATAFORMA DELIVERY </strong>{'(Site e App)'}</Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col xs={24} lg={24}>
                                {renderApp()}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                <Button type='primary'
                    style={{ backgroundColor: 'green', borderWidth: 0, marginTop: isDesktop ? 0 : 30 }} size="large" shape='round' icon={
                        <ShoppingCartOutlined style={{ fontSize: 25 }} />
                    } onClick={() => navigate('/planos')}>Comprar Licença Agora</Button>
            </div>


            <Row style={{ margin: isDesktop ? 50 : 0, marginTop: 0 }}>
                <Col span={24}>
                    <Card title={<strong> SUAS LICENÇAS </strong>}
                        style={{ backgroundColor: 'whitesmoke' }}>
                        {
                            user.licencas.map((item, index) => {
                                return renderLicencas(item, index)
                            })
                        }
                    </Card>
                </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                <Button type='primary'
                    style={{ backgroundColor: 'green', borderWidth: 0, marginTop: isDesktop ? 0 : 30 }} size="large" shape='round' icon={
                        <ShoppingCartOutlined style={{ fontSize: 25 }} />
                    } onClick={() => navigate('/planos')}>Comprar Licença Agora</Button>
            </div>

            <Row style={{ margin: isDesktop ? 50 : 0, marginTop: 0, marginBottom: 50 }}>
                <Col span={24}>
                    <Card title={<strong> SEUS PEDIDOS </strong>} style={{ backgroundColor: 'whitesmoke' }}>
                        {renderTablePagamento()}
                    </Card>
                </Col>
            </Row>


            <Space style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 30 }}>
                <Typography.Title level={4} style={{ color: 'royalblue', }}>  Download do programa Engfood </Typography.Title>
                <span>  Clique para baixar </span>
                <Button
                    icon={<DownloadOutlined style={{ fontSize: 25 }} />}
                    type='primary' size='large' shape='round' danger
                    onClick={() => setIsModalVisible(true)}>Download Agora</Button>
            </Space>
        </div>
        }
        <FooterCtrl />

        <Modal title="Solicitação de Site e App"
            closable={true}
            width={'90%'}
            footer={null}
            visible={isModalApp}
            destroyOnClose={true}
            onCancel={() => setIsModalApp(false)}>
            <div>
                <Space direction="vertical" style={{ width: '100%' }} size="large">

                    <Alert
                        message="Atenção"
                        description={<div>
                            <p>Devido a recentes mudanças na loja da Apple(App Store) os aplicativos para iOS devem ser enviados pela própria conta do estabelecimento.
                                Com isso você deve criar uma conta e pagar a taxa correspondente a Apple para publicar seu aplicativo na App Store.
                                <hr />
                                <p class="mb-0">Para a loja Google Play(Plataforma Android) não é preciso criar conta nem mesmo pagar qualquer taxa.
                                    Os aplicativos serão
                                    postados pelo próprio Programa Engfood sem nenhum custo adicional e totalmente personalizado com sua marca.</p>
                                <hr />
                                <p>Lembramos que essas novas regras e diretrizes não são da Programa Engfood e sim da própria Apple(App Store).<br />Para mais detalhes acesse
                                    <a href="https://developer.apple.com/app-store/review/guidelines/" target="_blank"> App Store Review Guidelines</a></p>

                            </p>
                        </div>
                        }
                        type="success"
                        showIcon
                    />

                    <Typography.Text>
                        Envie sua logomarca no formato PNG ou JPG.
                    </Typography.Text>
                    <Upload
                        {...props2}
                        headers={
                            {
                                'x-access-token': token,
                            }
                        }
                        name="file"
                        action={BASEURL.concat('uploads/uploadImageLogo')}
                        listType="picture"
                        maxCount={1}>
                        <Button icon={<UploadOutlined />}>Enviar</Button>
                    </Upload>
                </Space>
            </div>

        </Modal>


        <Modal title="Download Programa Engfood"
            closable={true}
            width={600}
            footer={null}
            visible={isModalVisible}
            destroyOnClose={true}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}>
            <DownloadCtrl setIsModalVisible={setIsModalVisible} />
        </Modal>


        <Modal title="Emissão de NFC-e/SAT"
            closable={true}
            footer={null}
            width={isDesktop ? '80%' : '100%'}
            visible={isModalNfce}
            destroyOnClose={true}
            onCancel={() => setIsModalNfce(false)}>
            <div style={{ fontSize: 13 }}>
                <h2>Fiscal NFC-e/SAT</h2>
                <div>
                    <p>
                        <b style={{ color: 'orangered' }}>Leia com ATENÇÃO antes de clicar em Comprar NFC-e!</b>
                    </p>
                    <br />
                    <p>No estado de <b>São Paulo</b><br />
                        Ainda não disponível (Não efetue a compra desse módulo)</p>
                    <br />
                    <p>No estado do <b>Ceará</b><br />
                        Ainda não disponível (Em fase de testes, não efetuar a compra, em breve disponível)</p>
                    <br />
                    <p>Nos demais <b>estados</b><br />
                        <ul>
                            <li>Possuir Certificado Digital modelo A1</li>
                            <li>Obter o Código de Segurança do Contribuinte (CSC) na SEFAZ</li>
                            <li>Confirmar se o modelo NFC-e está disponível no seu estado, com seu contador ou na SEFAZ</li>
                        </ul>
                    </p>
                    <p style={{ color: "forestgreen" }}>O preenchimento dos dados fiscais de cada produto é de responsabilidade do cliente.</p>
                    <hr />
                    <p style={{ color: "forestgreen", fontWeight: 600 }}>
                        {'Ao comprar a licença para emitir NFC-e ele terá a mesma válidade da sua licença atual, no caso será válida até '
                            .concat(moment(selected?.data2).format('DD/MM/YYYY'))}
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button type="primary" size='large' onClick={() => {
                            var atual = moment()
                            var final = moment(selected?.data2)
                            var dias = final.diff(atual, 'days')
                            // console.log('Valor por dia: ', dias, parceiro.valueFiscal / 30, dias * parceiro.valueFiscal / 30)
                            //  console.log(selected)

                            pagarFiscal(selected, "Fiscal Manager ".concat(dias, ' dias'),
                                dias * 50 / 30,
                                dias, selected.lic_id)

                        }}>
                            Comprar NFC-e
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>


    </div>
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user?.user,
    token: state.userReducer.user?.token
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)