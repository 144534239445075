import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { api } from '../../../service/api'
import { Table, Tag, DatePicker, Space, Button } from 'antd'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import './pedidosMenuengfood.css'
import { isMobile } from 'react-device-detect'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY';

export const PedidosMenuengfood = (props) => {

    const { parceiro, token } = props
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [range, setRange] = useState([dayjs(), dayjs()])
    const [total, setTotal] = useState(0)
    const [totalOnline, setTotalOnline] = useState(0)
    const [filter, setFilter] = useState([])

    useEffect(() => {
        load(range)
    }, [])


    function totais(ar) {
        try {
            var _total = 0
            let _filter = []
            var _total = 0
            var _online = 0
            ar.map(item => {
                _total = _total + parseFloat(item.valor)
                if (item.transaction_id > 0)
                    _online = _online + parseFloat(item.valor)
                if (_filter.findIndex(value => item.path === value.value) < 0)
                    _filter.push(
                        {
                            text: item.fantasia,
                            value: item.path,
                        })
            })
            setTotal(_total)
            setFilter(_filter)
            setTotalOnline(_online)
        } catch (error) {

        }
    }

    async function load(value) {

        try {

            setLoading(true)
            const result = await api.get('/parceiro/pedidos-menuengfood', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    data1: value[0].format('YYYY-MM-DD').concat(' 00:00:00'),
                    data2: value[1].format('YYYY-MM-DD').concat(' 23:59:59')
                }
            })
            setData(result.data)
            totais(result.data)
            //  totais(result.data)

        } catch (error) {
            console.log(error.message)
        }

        setLoading(false)

    }


    function renderTagStatus(text) {
        var status = 'Não confirmado'
        var cor = 'orange'
        switch (text) {
            case 1:
                cor = 'blue'
                status = 'Confirmado'
                break;
            case 2:
                cor = 'magenta'
                status = 'Cancelado'
                break;

            case 3:
                cor = 'orange'
                status = 'Cancelado'
                break;

            case 4:
                cor = '#f50'
                status = 'Em entrega'
                break;

            case 5:
                cor = 'purple'
                status = 'Pronto retirar'
                break;

            case 10:
                cor = '#87d068'
                status = 'Finalizado'
                break;

            default:
                break;
        }
        return <Tag color={cor} style={{ fontSize: '0.8em' }}> {status}</Tag>
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
                <Space style={{ marginBottom: 3 }} direction='horizontal'>
                    <RangePicker
                        size='small'
                        allowClear={false}
                        value={range}
                        onChange={(value) => {
                            // console.log(value)
                            setRange(value)
                            load(value)
                        }}
                        format={dateFormat}
                    />

                    <Button type='primary' size='small' onClick={() => load(range)}>atualizar</Button>
                </Space>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '0.8em' }}>

                    <Space direction={'horizontal'} size={'small'}>
                        <div>
                            Pedidos: <b>{data.length} </b>
                        </div>
                        <div>
                            Confirmado: <b>{total.toFixed(2)}</b>
                        </div>
                        <div>
                            Pago Online: <b>{totalOnline.toFixed(2)}</b>
                            <i style={{ fontSize: '0.8em', marginLeft: 3 }}>(
                                {(totalOnline * 0.01).toFixed(2)}
                                )</i>
                        </div>
                    </Space>
                </div>

            </div>

            <Table loading={loading}
                dataSource={data}
                bordered
                size='small'
                pagination={false}
                className='my-table-fontsize'
                rowKey={'pedid'}
                key={'pedid'}
            >
                <Table.Column
                    responsive={['lg']}
                    title={"Pedido"} key={"pedid"} dataIndex={'pedid'}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}>
                            <span style={{ color: 'green' }}>BR</span>{text} </div>
                    }} />

                <Table.Column
                    responsive={['lg']}
                    title={"Data"} key={"pedid"} dataIndex={'datahora'}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}> {dayjs(record.dataserver).format('DD/MM/YYYY HH:mm')} </div>
                    }} />

                <Table.Column
                    responsive={['lg']}
                    title={"Estabelecimento"}
                    key={"pedid"}
                    dataIndex={'fantasia'}
                    filters={filter}
                    onFilter={(value, record) => record.path.startsWith(value)}
                    filterSearch={true}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}> {text} </div>
                    }} />


                <Table.Column
                    responsive={['lg']}
                    title={"Valor"} key={"pedid"} dataIndex={'valor'}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}> R${text.toFixed(2)} </div>
                    }} />
                <Table.Column
                    responsive={['lg']}
                    title={"Status"} key={"pedid"} dataIndex={'status'}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}> {renderTagStatus(text)} </div>
                    }} />

                <Table.Column
                    responsive={['lg']}
                    title={"Pago online"} key={"pedid"} dataIndex={'transaction_id'}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: '0.8em' }}>
                            <Tag style={{ fontSize: '0.8em' }}
                                color={text === 0 ? 'red' : 'blue'} >
                                {text === 0 ? 'Não' : 'Sim'}
                            </Tag>
                        </div>
                    }} />

                {
                    isMobile && <Table.Column
                        title={"Estabelecimento"}
                        key={"pedid"}
                        dataIndex={'fantasia'}
                        filters={filter}
                        onFilter={(value, record) => record.path.startsWith(value)}
                        filterSearch={true}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: '0.8em', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Space direction='horizontal'>
                                        <div style={{ fontSize: '0.8em' }}>
                                            <span style={{ color: 'green' }}>BR</span>{record.pedid}
                                        </div>

                                        <div style={{ fontSize: '0.8em' }}>
                                            {record.fantasia}
                                        </div>
                                    </Space>
                                    <div style={{ fontSize: '0.8em' }}> R${record.valor.toFixed(2)} </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Space direction='horizontal'>
                                        <div style={{ fontSize: '0.8em' }}>
                                            {dayjs(record.dataserver).format('DD/MM/YYYY HH:mm')}
                                        </div>
                                        <div style={{ fontSize: '0.8em' }}> {renderTagStatus(record.status)} </div>
                                        <div style={{ fontSize: '0.8em' }}>
                                            <Tag style={{ fontSize: '0.8em' }}
                                                color={record.transaction_id === 0 ? 'red' : 'blue'} >
                                                {record.transaction_id === 0 ? 'Pag online: Não' : 'Pag online: Sim'}
                                            </Tag>
                                        </div>
                                    </Space>
                                </div>

                            </div>
                        }} />
                }

            </Table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PedidosMenuengfood)