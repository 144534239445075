import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Nav,
  NavLink,
  Bars,
  NavMenu
} from './NavbarElements';
import {
  Popover, Typography, Divider, Button, Affix, Space, Modal, Drawer
} from 'antd'
import * as userAction from '../../store/actions/user.action'
import { UserOutlined, CloseCircleOutlined } from '@ant-design/icons'
import LoginCtrl from '../login'
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { DownloadCtrl } from '../download';



export const Navbar = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  //const [isModalVisibleLogin, setIsModalVisibleLogin] = useState(false)
  const { user, saveUser } = props
  const [responsavel, setResponsavel] = useState('')
  const [isPop, setIsPop] = useState(false)
  const [isPopMobile, setIsPopMobile] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (user) {
        var res = user.user.responsavel.split(' ')
        setResponsavel("Olá, ".concat(res[0]))
      }
    } catch (error) { }
  }, [user])

  return (
    <div>
      <Affix offsetTop={0} style={{ marginTop: 0 }}>
        <Nav>
          <NavLink to='/'>
            <Space style={{ display: 'flex', alignItems: 'center' }}>
              <img src={require('../../images/engfood-round.png')} alt='logo' style={{ height: 48 }} />
              <Typography.Title style={{ color: 'white' }} level={isMobile ? 4 : 3}>Programa Engfood</Typography.Title>
            </Space>
          </NavLink>

          <Bars onClick={() => setIsPopMobile(!isPopMobile)} />

          <NavMenu>
            <NavLink to='/' activeStyle>
              Inicio
            </NavLink>
            <NavLink to='/planos' activeStyle>
              Planos
            </NavLink>
            <NavLink to={''} onClick={() => setIsModalVisible(true)} style={{ color: 'white' }}>
              Download
            </NavLink>
            {
              !user ? <Button style={{ marginLeft: 10 }} type='primary'
                onClick={() => navigate('/login')}>
                Área do Cliente
              </Button>
                : <Popover
                  style={{ position: 'fixed' }}
                  placement={'bottom'}
                  content={<div style={{
                    width: 130, display: 'flex', flexDirection: 'column',
                    alignItems: 'flex-start', justifyContent: 'flex-start',
                  }}>

                    <NavLink to="/account" activeStyle style={{ color: 'royalblue' }}>
                      Minha conta
                    </NavLink>

                    <NavLink to="/manager" activeStyle style={{ color: 'royalblue', marginTop: 5 }}>
                      Manager
                    </NavLink>

                    <Button type='text' block style={{ textAlign: 'left', color: 'royalblue' }}
                      onClick={() => {
                        navigate('/')
                        saveUser(null)
                      }}>Sair
                    </Button>
                  </div>}
                  title={null}
                  trigger="click"
                  visible={isPop}
                  onVisibleChange={() => setIsPop(!isPop)}>
                  <Space>
                    <Button type='dashed' onClick={() => setIsPop(true)}>
                      <UserOutlined />
                      <Typography.Text >
                        {responsavel}
                      </Typography.Text>
                    </Button>
                  </Space>
                </Popover>
            }

          </NavMenu>

        </Nav>
      </Affix>

      <Modal title="Download Programa Engfood"
        closable={true}
        width={600}
        footer={null}
        visible={isModalVisible}
        destroyOnClose={true}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}>
        <DownloadCtrl setIsModalVisible={setIsModalVisible} />
      </Modal>

{
  /*
      <Modal
        title={null}
        closable={true}
        footer={null}
        width={400}
        visible={isModalVisibleLogin}
        destroyOnClose={true}
        onOk={() => setIsModalVisibleLogin(false)}
        onCancel={() => setIsModalVisibleLogin(false)}>
        <LoginCtrl setIsModalVisibleLogin={setIsModalVisibleLogin} />
      </Modal>
*/
}
      <Drawer
        width={'100%'}
        closable={false}
        title={
          <Space style={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../../images/engfood-round.png')} alt='logo' style={{ height: 48 }} />
            <Typography.Title style={{ color: '#000' }} level={4}>Programa Engfood</Typography.Title>
          </Space>
        }
        placement="right"
        extra={<CloseCircleOutlined
          onClick={() => setIsPopMobile(false)}
          style={{ fontSize: 30, color: 'red' }} />}

        onClose={() => setIsPopMobile(false)} visible={isPopMobile}>

        <Space direction='vertical' style={{
          display: 'flex', justifyContent: 'center'
        }}>
          <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
            onClick={() => { navigate('/') }}>Início
          </Button>
          <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
            onClick={() => { navigate('/planos') }}>Planos
          </Button>
          <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
            onClick={() => {
              setIsPopMobile(false)
              setIsModalVisible(true)
            }}>Download
          </Button>
          <Divider />

          {
            !user ? <Button size='large' style={{ width: '100%' }} type='primary'
              onClick={() => {
                setIsPopMobile(false)
                navigate('/login')
              }}>
              Área do Cliente
            </Button>
              :
              <Space direction='vertical' style={{
                display: 'flex', justifyContent: 'center'
              }}>
                <Space style={{ display: 'flex', alignItems: 'center' }}>
                  <UserOutlined style={{ fontSize: 20 }} />
                  <Typography.Text >
                    <strong> {responsavel} </strong>
                  </Typography.Text>
                </Space>

                <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
                  onClick={() => {
                    navigate('/account')
                    setIsPopMobile(false)
                  }}>Minha Conta
                </Button>

                <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
                  onClick={() => {
                    setIsPopMobile(false)
                    navigate('/account/manager',{user:user})
                   }}>Manager
                </Button>

                <Button type='link' size='large' style={{ fontSize: 20, width: '100%' }}
                  onClick={() => { 
                    navigate('/')
                    saveUser(null)
                   }}>Sair
                </Button>

              </Space>


          }


        </Space>

      </Drawer>

    </div>
  );
};



const mapStateToProps = (state) => ({
  user: state.userReducer.user
})

const mapDispatchToProps = dispatch => ({
  saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
