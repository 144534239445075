import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const SistemaEmRede = (props) => {

    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/utilizar-o-sistema-em-rede/');
    },[])

    const navigate = useNavigate()

    return <div>
        <Navbar />
        <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
            <Typography.Title level={4}>
                Utilizar o sistema em rede <br />
                <span style={{ fontSize: 13, fontWeight: 'normal' }}>
                    Atualizado em 07/08/2022
                </span>
            </Typography.Title>

            <Alert
                message="Informação"
                description="Manual referente a verão 11 ou superior do Programa Engfood."
                type="info"
                showIcon
            />

            <br />

            <div style={{ fontSize: 13 }}>
                <div>Para utilizar o Programa Engfood em mais de 1 computador é necessário
                    conecta-los na mesma rede, não há necessidade de internet para essa funcionalidade.</div>
                <br />
                <div>
                    Outra configuração necessária é colocar o computador principal(servidor) com IP fixo,
                    veja <a href='https://www.softdownload.com.br/como-configurar-um-ip-fixo-estatico-windows-7-8-10.html' target={'_blank'}> nesse tutorial </a> de como efetuar a configuração.
                </div>
                <br />
                <div>
                    <strong> Passo 1  </strong>– No 2 computador acesse Configurações/Sistema clique na opção computador e altere o campo computador para 2 como na imagem abaixo.
                    <br />
                    <img alt='desktop' src={require('../../images/manual/sistema-em-rede2.png')} style={{ width: '100%', maxWidth: 800 }} />
                    <br />
                    Essa configuração é importante pois caso contrário o caixa será compartilhado entre os computadores e você poderá terá problemas na hora do fechamento do caixa.
                </div>
                <br />
                <div>
                    <strong> Passo 2 </strong> – Ainda no segundo computador acesse a opção Banco de Dados e digite o IP do computador principal no campo Host e clique em salvar.
                    <br />
                    <img alt='desktop' src={require('../../images/manual/sistema-em-rede3.png')} style={{ width: '100%', maxWidth: 800 }} />
                    <br />
                    No exemplo acima o IP do servidor é 192.168.30.196, se tudo estiver correto o sistema será fechado e reiniciará já conectado ao servidor.
                </div>


                <br />
                <div>
                    Atenção! Para que os computadores possam acessar o banco de dados é necessário que o servidor esteja sempre ligado e conectado na mesma rede domestica.
                    <br />
                    Para adicionar outros computadores repita os passos 1 e 2 alterando sempre o número do computador no passo 1 para 3,4,5 e assim sucessivamente.
                </div>

                <br />
            </div>

        </div>




        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 20 }}>
            <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
        </div>       <br /><br />

        <FooterCtrl />

    </div>

}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SistemaEmRede)