import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Button, Input, Checkbox } from 'antd'
import { validateEmail } from '../../service/util';
import { api } from '../../service/api'

export const DownloadCtrl = (props) => {

    const [email, setEmail] = useState('')

    async function download() {
        try {
            const result = await api.post('site/download', {email:email})
           // console.log(result)
            props.setIsModalVisible(false)
            
            const link = document.createElement('a');
            link.href = result.data.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            

        } catch (error) {

        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Input type={'email'} placeholder="Informe seu e-mail"
                value={email} onChange={(e) => setEmail(e.target.value)}
            />
            <Button type='primary' shape='round' size='large'
                disabled={!validateEmail(email)}
                block onClick={() => {

                    download()

                }} style={{ marginTop: 10 }}>Download agora</Button>
            <Checkbox checked={true} style={{ fontSize: 11, display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                Aceito receber e-mails de informações do Programa Engfood.<Button type='link' style={{ fontSize: 11 }}>Política de privacidade. </Button>
            </Checkbox>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCtrl)