
import { combineReducers } from 'redux'
import userReducer from './user.reducer'
import parceiroReducer from './parceiro.reducer';

const rootReducer = combineReducers({
    userReducer,
    parceiroReducer
})

export default rootReducer;