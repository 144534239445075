import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Tabs, Row, Col, Input, Select, message, Tag, Modal, Tooltip } from 'antd'
import InputMask from 'react-input-mask'
import { api } from '../../service/api'
import { isDesktop } from 'react-device-detect'
import ClienteCtrl from './clienteCtrl'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';

const { TabPane } = Tabs
const { Option } = Select
const { Column } = Table

const onChange = (key) => {
    // console.log(key);
};

export const RevendedorCtrl = (props) => {

    const { token, parceiro } = props
    const [user, setUser] = useState(props.revendedor)
    const [loading, setLoading] = useState(false)
    const [contratoSelecionado, setContratoSelecionado] = useState({})
    const [modalAccount, setModalAccount] = useState(false)

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            setLoading(true)
            user.clientes = []
            const response = await api.get('parceiro/load',
                {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        id: user?.id
                    }
                })
            setUser(response.data)

        } catch (error) {
            console.log(error.message)
            if (error?.response) {
                message.error(error?.response.statusText)

            } else {
                console.log(error.message)
                message.error(error.message)
            }
        }
        setLoading(false)
    }

    function renderRevendedor() {
        return <div style={{ fontSize: 14 }}>
            <Row gutter={[4, 2]}>
                <Col span={24} style={{ marginTop: 10, marginBottom: 10, color: 'royalblue' }}>
                    <strong>DADOS PARA CONTATO</strong></Col>
                <Col xs={24} lg={4}>
                    Nome <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.nome} />
                </Col>

                <Col xs={24} lg={4}>
                    E-mail <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.email} disabled={true} maxLength={255} />
                </Col>
                <Col xs={24} lg={4}>
                    CPF <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <InputMask id="cpf" name="cpf" placeholder='CPF'
                        mask={'999.999.999-99'}
                        value={user?.cpf}
                        children={() => <Input />}>
                    </InputMask>
                </Col>
                <Col xs={24} lg={4}>
                    Celular <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <InputMask id="fone1" name="fone1" placeholder='Celular'
                        mask={'99 99999-9999'}
                        value={user?.fone1}
                        children={() => <Input />}>
                    </InputMask>
                </Col>

                <Col span={24} style={{ marginTop: 10, marginBottom: 10, color: 'royalblue' }}><strong>DADOS COMERCIAIS</strong></Col>

                <Col xs={24} lg={4}>
                    Fantasia:
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.fantasia} />
                </Col>
                <Col xs={24} lg={4}>
                    Razão Social:
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.razaosocial} />
                </Col>
                <Col xs={24} lg={4}>
                    CNPJ:
                </Col>
                <Col xs={24} lg={20}>
                    <InputMask id="cnpj" name="cnpj" placeholder='CNPJ'
                        mask={'99.999.999/9999-99'}
                        value={user?.cnpj}
                        children={() => <Input />}>
                    </InputMask>
                </Col>

                <Col xs={24} lg={4}>
                    Endereço <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.endereco} />
                </Col>
                <Col xs={24} lg={4}>
                    Bairro <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.bairro} />
                </Col>
                <Col xs={24} lg={4}>
                    Cidade <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Input value={user?.cidade} />
                </Col>
                <Col xs={24} lg={4}>
                    Estado <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col xs={24} lg={20}>
                    <Select
                        id="uf" name="uf"
                        defaultValue={'CE'}
                        value={user?.uf} style={{ width: '100%' }}>
                        <Option value={'AC'}>AC</Option>
                        <Option value={'AL'}>AL</Option>
                        <Option value={"AP"}>AP</Option>
                        <Option value={"AM"}>AM</Option>
                        <Option value={"BA"}>BA</Option>
                        <Option value={"CE"}>CE</Option>
                        <Option value={"DF"}>DF</Option>
                        <Option value={"ES"}>ES</Option>
                        <Option value={"GO"}>GO</Option>
                        <Option value={"MA"}>MA</Option>
                        <Option value={"MT"}>MT</Option>
                        <Option value={"MS"}>MS</Option>
                        <Option value={"MG"}>MG</Option>
                        <Option value={"PA"}>PA</Option>
                        <Option value={"PB"}>PB </Option>
                        <Option value={"PR"}>PR</Option>
                        <Option value={"PE"}>PE</Option>
                        <Option value={"PI"}>PI</Option>
                        <Option value={"RJ"}>RJ</Option>
                        <Option value={"RN"}>RN</Option>
                        <Option value={"RS"}>RS</Option>
                        <Option value={"RO"}>RO</Option>
                        <Option value={"RR"}>RR</Option>
                        <Option value={"SC"}>SC</Option>
                        <Option value={"SP"}>SP</Option>
                        <Option value={"SE"}>SE</Option>
                        <Option value={"TO"}>TO</Option>
                    </Select>
                </Col>


            </Row>




        </div>
    }


    function renderClientes() {
        if (user?.clientes)
            return <Table dataSource={user.clientes} pagination={false} size="small"
                showHeader={isDesktop ? true : false}>
                <Column title={isDesktop ? <strong>Fantasia</strong> : ''}
                    dataIndex={"fantasia"} key={"tok_email"}
                    render={(text, record, index) => {
                        return isDesktop ? <Button type='link' onClick={() => {
                            setContratoSelecionado(record)
                            setModalAccount(true)
                        }}> {text} </Button> : <div
                            style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                            <div style={{ color: 'royalblue', fontSize: 12 }} onClick={() => {
                                setContratoSelecionado(record)
                                setModalAccount(true)
                            }}>

                                <strong style={{ textTransform: 'uppercase' }}> {text} </strong>
                            </div>
                            <div style={{ wordBreak: 'break-all', textTransform: 'lowercase' }}> {record.tok_email} </div>
                            <div><Tag color={record.situacao === 'Ativo' ? 'blue' : 'error'}>{record.situacao}</Tag></div>
                        </div>

                    }} />
                {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"tok_email"} key={"tok_email"}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: 12 }}>{text}</div>

                    }} />
                }
                {isDesktop && <Column title={<strong>Situação</strong>} dataIndex={"situacao"} key={"tok_email"}
                    render={(text, record, index) => {
                        return <div style={{ fontSize: 12 }}> <Tag style={{ fontSize: 12 }} color={text === 'Ativo' ? 'blue' : 'error'}>{text}</Tag>
                        </div>
                    }} />
                }
            </Table>
        else return <div>loadding....</div>
    }

    function renderLicencas() {
        if (!user?.licencas)
            return <div> loadding....</div>
        return <Table dataSource={user.licencas} size="small" pagination={false}
            showHeader={isDesktop ? true : false}>
            <Column title={isDesktop ? <strong style={{ textTransform: 'uppercase' }}>Fantasia</strong> : ''} dataIndex={"fantasia"} key={"fantasia"}
                render={(text, record, index) => {
                    return isDesktop ? <div style={{ fontSize: 11 }}><span style={{ color: 'royalblue', fontWeight: 'bold' }}>{text}</span>
                        {record.fiscal === 1 &&
                            <Tooltip title="Cliente emite Cupom Fiscal" color={'cyan'} key={'cyan'}>
                                <img src={require('../../images/nfce.png')} height={18} />
                            </Tooltip>
                        }
                    </div> : <div style={{ fontSize: 11 }}>
                        <div><strong> {text} </strong></div>
                        <div> {record.email} </div>
                        <div>Vencimento:  {dayjs(record.data2).format("DD/MM/YYYY")} </div>
                    </div>

                }} />

            {isDesktop && <Column title={<strong>Cidade</strong>} dataIndex={"cidade"} key={"cidade"}
                render={(text, record, index) => {
                    return <div style={{ fontSize: 11 }}>{text}</div>

                }} />
            }


            {isDesktop && <Column title={<strong>E-mail</strong>} dataIndex={"tok_email"} key={"tok_email"}
                render={(text, record, index) => {
                    return <div style={{ fontSize: 11 }}>{text}</div>

                }} />
            }

            {isDesktop && <Column title={<strong>Vencimento</strong>} dataIndex={"data2"} key={"tok_email"}
                render={(text, record, index) => {
                    return <div style={{ fontSize: 11 }}>{dayjs(text).format('DD/MM/YYYY')}</div>

                }} />
            }
        </Table>
    }


    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={onChange}>
                <TabPane tab="Dados do Revendedor" key="1">
                    {renderRevendedor()}
                </TabPane>
                <TabPane tab="Clientes" key="2">
                    {renderClientes()}
                </TabPane>
                <TabPane tab="Licenças Expirando" key="3">
                    {renderLicencas()}
                </TabPane>
            </Tabs>

            <Modal visible={modalAccount}
                width={isDesktop ? '80%' : '100%'}
                footer={null}
                destroyOnClose={true}
                afterClose={() => { }}
                closable={true}
                onCancel={() => setModalAccount(false)}>
                <ClienteCtrl parceiroID={parceiro.id}
                    setModalAccount={setModalAccount} cliente={contratoSelecionado}
                    setModalRegister={() => { }} />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    // saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RevendedorCtrl)