import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Divider } from 'antd'
import * as parceiroAction from '../../store/actions/parceiro.action'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';

export const TransationDetail = (props) => {

    const transacao = props.transacao

    useEffect(() => {
        //  getTransacao(transacao.codigo)
    }, [])
    /*
        async function getTransacao(id) {
            try {
                // setLoadLicencas(true)
                const response = await api.get('pagamento', {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        id: id
                    }
                })
                setTransationSelected(response.data)
                console.log(response.data)
    
            } catch (error) {
                console.log(error.message)
                if (error?.response.status === 401) {
                    saveParceiro(null)
                    navigate('/parceiros/login')
                }
                message.error('Erro ao carregar dados da transação!\n'.concat(error.message))
                setModalTrasationDetail(false)
            }
            // setLoadLicencas(false)
        }
    */
    return (
        <div>
            <Row>
                <Col span={24}><b>DETALHES DA TRANSAÇÃO</b></Col>
                <Col span={24}><Divider style={{ marginBottom: 8, marginTop: 8 }} /></Col>
                <Col xs={24} lg={6}>Transação</Col>
                <Col xs={24} lg={18}>{transacao.id}</Col>
                <Col xs={24} lg={6}>Data</Col>
                <Col xs={24} lg={18}>{dayjs(transacao?.date_created).format('DD/MM/YYYY')}</Col>
                <Col xs={24} lg={6}>Parcelas</Col>
                <Col xs={24} lg={18}>{transacao.installments}x </Col>
                <Col xs={24} lg={6}>Status</Col>
                <Col xs={24} lg={18}>{transacao.status}</Col>
                <Col xs={24} lg={6}>Total</Col>
                <Col xs={24} lg={18}>R$ {parseFloat(transacao?.transaction_amount).toFixed(2)}</Col>
                <Col xs={24} lg={24}>Itens</Col>
                {
                    transacao?.additional_info?.items?.map((item, index) => {
                        return <Col xs={24} lg={24}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div>
                                    {"".concat(item.quantity, " x ", item.title)}
                                </div>
                                <div>R$ {parseFloat(item.unit_price).toFixed(2)}</div>
                            </div>

                        </Col>
                    })
                }

            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransationDetail)