import {createStore} from 'redux'
import rootReducer from './reduces/root.reducer'
import {encryptTransform} from 'redux-persist-transform-encrypt';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'



const encryptor=encryptTransform({
    secretKey: 'KEYCGUSTAVOROCHAKEY',
    onError: function (error) {
      // Handle the error.
    },
  })

const persistConfig = {
    key:'appengfoodsitereactjs',
    transforms:[encryptor],
    storage
    
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer);
const persistor = persistStore(store)

export {store, persistor}

//storeSynchronize(store)