import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../../../App.css'
import {
    Card, Row, Col, Typography, Space, Button, Divider, DatePicker,
    Tag, Table, Alert, Select, Modal, Upload, message, InputNumber, Checkbox,
    Breadcrumb
} from 'antd'
import { DownloadOutlined, EditOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import '../../../css/fontawesome-all.min.css'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import { api, BASEURL, BASEURLRAIZ } from '../../../service/api'
import { NotificationManager } from 'react-notifications';
import { useNavigate, useLocation } from 'react-router-dom'
import { DownloadCtrl } from '../../../components/download'
import { isDesktop } from 'react-device-detect'
import { BASE_URL_SITE, KEYMP } from '../../../Constantes'


const { Column } = Table
const { Option } = Select


const URL_RETORNOS = {
    success: BASE_URL_SITE.concat("Loja/conclusao"),
    failure: BASE_URL_SITE.concat("Loja/failure"),
    pending: BASE_URL_SITE.concat("Loja/failure")
}

export const ParceiroCliente = (props) => {

    const { state } = useLocation();
    const navigate = useNavigate()
    const { token, parceiro } = props
    const [user, setUser] = useState(state.contratoSelecionado)
    const [isModalApp, setIsModalApp] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [urlLogo, setUrlLogo] = useState(undefined)
    const [isModalBuyVisible, setIsModalBuyVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nfce, setNfce] = useState(state.contratoSelecionado?.fiscal)
    const [isModalNfce, setIsModalNfce] = useState(false)
    const [selected, setSelected] = useState(undefined)
    const [licencaSelected, setLicencaSelected] = useState(undefined)
    const [licencaModal, setLicencaModal] = useState(false)
    const [prorrogarLicenca, setProrrogarLicenca] = useState(false)
    const [loadCliente, setLoadCliente] = useState(false)

    useEffect(() => {
        //   console.log("USER", props)
        getCliente()
    }, [])





    async function getCliente() {
        try {
            setLoadCliente(true)
            const result = await api.get('/parceiro/cliente', {
                headers: {
                    'x-access-token': token,
                },
                params: {
                    tokenid: user.tokenid
                }
            })
            setUser(result.data)

        } catch (error) {
            console.log("error: ", error.message)
            message.error(error.message)
        }
        setLoadCliente(false)
    }


    async function pagarFiscal(item, descricao, valor, dias, licencaid) {
        try {
            setLoading(true)
            var pedido = {
                email: parceiro.email,
                token: token
            }

            pedido.itens = [
                {
                    id: 30,
                    descricao: descricao,
                    meses: 0,
                    valor: valor,
                    code: 'F'.concat(dias),
                    nome: descricao,
                    dias: dias,
                    licencaid: licencaid,
                    email: item.tok_email
                }
            ]

            const result = await api.post('/parceiro/pedido-fiscal-manager', pedido, {
                headers: {
                    'x-access-token': token,
                }
            })
            var aux = result.data
            setIsModalNfce(false)

           
            var preference = {
                auto_return: "approved"
            }
            var itens = [{
                title: descricao,
                unit_price: valor,
                quantity: 1,
                currency_id: 'BRL',
                id: "F".concat(dias)
            }]


            var name_sobrename = parceiro.nome.trim().split(' ');
            var telefones = ['', '']
            var telefones = parceiro.fone1.trim().split(' ')
            telefones[0] = telefones[0].replace('(', '').replace(')', '').replace('-', '')
            if (telefones.length > 1)
                telefones[1] = telefones[1].replace('(', '').replace(')', '').replace('-', '')
            else
                telefones[1] = telefones[0].replace('-', '')


            preference.payment_methods = {
                installments: 1,
                default_installments: 1
            }

            preference.items = itens;
            preference.payer = {
                name: name_sobrename[0],
                surname: name_sobrename.length > 1 ? name_sobrename[1] : name_sobrename[0],
                email: parceiro.email,
                phone: {
                    area_code: telefones[0],
                    number: parseInt(telefones[1])
                },

                identification: {
                    type: "CPF",
                    number: parceiro.cpf.replace('.', '').replace('-', '')
                },

                address: {
                    zip_code: '60000000',//dataToken[0].cep ? dataToken[0].cep.replace('-', '') : dataToken[0].cep.replace('-', ''),
                    street_name: parceiro.endereco,
                    // street_number: dataToken[0].numero
                }
            }

            preference.back_urls = {
                success: "https://programaengfood.com.br/Loja/conclusao",
                failure: "https://programaengfood.com.br/Loja/failure",
                pending: "https://programaengfood.com.br/Loja/pending"
            }
            preference.notification_url = BASEURL.concat("notification/retornosmpfiscalmanager")
            preference.external_reference = aux.id.toString()

            try {
                console.log('aqui')

                let initPref = await api.post('/parceiros/preference', preference, {
                    headers: { 'x-access-token': token }
                })



                var url = "https://programaengfood.com.br/notificationsite/init/preference?uid=".concat(initPref.data.uid)
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                /*
                const requestOptions = {
                    idempotencyKey: idempotencyKey,
                };
                payment.create({ preference, requestOptions }).then(console.log).catch(console.log);
                */
            } catch (err) {
                console.log('Erro na inicialização das configurações de pagamento!\n'.concat(err.message))
                message.error('Erro na inicialização das configurações de pagamento!\n'.concat(err.message));
            }


        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401) {
                    //   saveParceiro(null)
                    // navigate('/')
                }
            } else {
                message.error(error.message)
            }
        }
        setLoading(false)
    }


    async function pagar() {
        try {
            console.log('pagar')
            setLoading(true)

            var transacao = {
                token: parceiro.email,
                value: nfce === 0 ? parceiro.valueLicenca : (parseFloat(parceiro.valueLicenca) +
                    parseFloat(parceiro.valueFiscal)),
                itens: [{
                    item_descricao: 'Plano Mensal',
                    item_code: "P1",
                    item_valor: parceiro.valueLicenca,
                    item_token: user.tok_email
                }]
            }
            if (nfce === 1) {
                transacao.itens.push({
                    item_descricao: 'Fiscal NFCe',
                    item_code: "F1",
                    item_valor: parceiro.valueFiscal,
                    item_token: user.tok_email
                })
            }
            const response = await api.post('loja/payment/parceiro', transacao, {
                headers: {
                    'x-access-token': token,
                }
            })
            transacao.id = response.data.id

            /*
            var mercadopago = require('mercadopago')
            mercadopago.configure({
                access_token: KEYMP
            });
*/

            var preference = {
                auto_return: "approved"
            }

            preference.transaction_amount = transacao.value
            preference.description = "Licença Engfood"

            var itens = []
            for (const item of transacao.itens) {
                itens.push({
                    title: item.item_descricao,
                    unit_price: parseFloat(item.item_valor),
                    quantity: 1,
                    currency_id: 'BRL',
                    id: item.item_code
                })
            }

            var name_sobrename = parceiro.nome.trim().split(' ');
            var telefones = ['', '']
            if (parceiro.fone1) {
                var telefones = parceiro.fone1.trim().split(' ')
                telefones[0] = telefones[0].replace('(', '').replace(')', '').replace('-', '')
                if (telefones.length > 1)
                    telefones[1] = telefones[1].replace('(', '').replace(')', '').replace('-', '')
                else
                    telefones[1] = telefones[0].replace('-', '')
            }

            preference.payment_methods = {
                installments: 1,
                default_installments: 1
            }

            preference.items = itens;
            preference.payer = {
                name: name_sobrename[0],
                surname: name_sobrename.length > 1 ? name_sobrename[1] : name_sobrename[0],
                email: parceiro.email,
                phone: {
                    area_code: telefones[0],
                    number: parseInt(telefones[1])
                },
                identification: {
                    type: "CPF",
                    number: parceiro?.cpf.replace('.', '').replace('-', '')
                },
                address: {
                    zip_code: parceiro.cep ? parceiro.cep?.replace('-', '') : parceiro.cep?.replace('-', ''),
                    street_name: parceiro.endereco,
                    // street_number: dataToken[0].numero
                }
            }

            preference.back_urls = URL_RETORNOS
            preference.notification_url = BASEURL.concat("notification/retornosmp") //NOTIFICATION_URL
            preference.external_reference = response.data.id.toString()



            try {

                let initPref = await api.post('/parceiros/preference', preference, {
                    headers: { 'x-access-token': token }
                })
                
                var url = "https://programaengfood.com.br/notificationsite/init/preference?uid=".concat(initPref.data.uid)
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                


                /*
                                const requestOptions = {
                                    idempotencyKey: idempotencyKey,
                                };
                                
                                payment.create({ preference, requestOptions }).then(console.log).catch(console.log);
                */


            } catch (err) {
                console.log('Erro na inicialização das configurações de pagamento!\n'.concat(err.message))
                message.error('Erro na inicialização das configurações de pagamento!\n'.concat(err.message));
            }

        } catch (error) {
            if (error?.response) {
                message.error(error?.response.statusText)
                if (error?.response.status === 401) {
                    //  saveUser(null)
                    // navigate('/')
                }
            } else {
                message.error(error.message)
            }
        }
        setLoading(false)
    }


    const props2 = {
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                console.log('Você só pode fazer upload de imagem JPG/PNG!')
                message.error('Você só pode fazer upload de imagem JPG/PNG!');
            }
            const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
            if (!isLt2M) {
                console.log('A imagem deve ser menor que 3MB!')
                message.error('A imagem deve ser menor que 3MB!');
            }
            console.log('beforeUpload', isJpgOrPng, isLt2M)
            return isJpgOrPng && isLt2M;
        },
        onChange: (info) => {
            console.log(info.fileList);
            if (info.file.status === 'uploading') {
                //  setLoading(true)
                return;
            }
            if (info.file.status === 'done') {
                // console.log('info', BASEURL.concat(info.fileList[0].response.filename))
                setUrlLogo(BASEURL.concat('logo/', info.fileList[0].response.filename))
                // message.info('Logomarca enviada com sucesso!');
                NotificationManager.info("Logomarca enviada com sucesso! Aguarde prazo de até 5 dias úteis para site e/ou app ficarem prontos!", '', 10000, () => { });
                setIsModalApp(false)
            }

            if (info.file.status === 'error') {
                NotificationManager.error(info.file.response.error, '', 10000, () => { });
            }

        },
    };


    function getTipoLicenca(dias) {
        var licenca = 'Mensal'
        switch (dias) {
            case 365: licenca = 'Anual'; break;
            case 180: licenca = 'Semestral'; break;
            case 90: licenca = 'Trimestral'; break;
            case 30: licenca = 'Mensal'; break;
            default: licenca = 'desconhecida';

        }
        return licenca;
    }




    function checkLicencavalida(item) {
        try {
            var atual = dayjs()
            var inicial = dayjs(item.data1)
            var final = dayjs(item.data2)

            var h1 = atual.diff(inicial, 'hours') // tem que ser maior >= 0
            var h2 = final.diff(atual, 'hours'); //tem que ser maior >= 0           

            if (h1 >= 0 && h2 >= 0) {
                item.canbaixar = true
                return <Tag color="#108ee9"> Ativa </Tag>
            }
            else if (h2 > 0) {
                item.canbaixar = true
                return <Tag color={'processing'}> Aguardando - essa licença inicia no dia {dayjs(item.data1).format('DD/MM/YYYY')}</Tag>;
            } else {
                item.canbaixar = false
                return <Tag color={'red'}> Inativa </Tag>;
            }
        } catch (error) {
            item.canbaixar = false
            return <Tag color={'red'}> Inválida </Tag>
        }
    }


    function getStatus(status) {
        if (status === 'Paga')
            return <Tag color={'blue'}>{status}</Tag>
        else if (status === 'Cancelada')
            return <Tag color={'volcano'}>{status}</Tag>
        else if (status === 'Aguardando pagamento')
            return <Tag color={'default'}>{status}</Tag>
        else if (status === 'Devolvida')
            return <Tag color={'volcano'}>{status}</Tag>
        else if (status === 'Retenção Temporária')
            return <Tag color={'volcano'}>{status}</Tag>
        else
            return <Tag color={'default'}>{status}</Tag>
    }


    function renderApp() {
        if (user.appdelivery?.ativo === undefined) {
            return <Alert
                style={{ margin: isDesktop ? 0 : -15 }}
                message={null}
                description="Você ainda não possui a plataforma de delivery configurada."
                type="info"
                showIcon
                action={
                    <Button shape='round' type='primary' onClick={() => setIsModalApp(true)}>
                        Solicitar plataforma
                    </Button>
                }

            />
        } else {
            return <Alert
                style={{ margin: isDesktop ? 0 : -15 }}
                message={null}
                description={<div>
                    <div>Seu sistema de delivery App e Site estão...</div>
                    <div>
                        {user.appdelivery?.ativo === 1 && <a href={user.appdelivery?.url} target={"_blank"}> Clique para acessar sua loja </a>}
                    </div>
                </div>}
                type={user.appdelivery?.ativo === 0 ? "warning" : "info"}
                showIcon
                action={
                    <Select
                        style={{ width: 150 }}
                        size='small'
                        value={user?.appdelivery?.ativo}
                        onChange={(e) => {
                            var item = JSON.parse(JSON.stringify(user.appdelivery))
                            item.ativo = e
                            updateApp(item)

                        }}>
                        <Option value={0}>Desativado</Option>
                        <Option value={1}>Ativado</Option>
                    </Select>
                }

            />
        }
    }


    async function updateApp(app) {
        try {
            const result = await api.put('/restaurant/app/parceiro', app, {
                headers: {
                    'x-access-token': token,
                }
            })
            setUser(result.data)
            NotificationManager.info("Dados atualizados com successo", '', 3000, () => { });
        } catch (error) {
            NotificationManager.error(error.message, '', 5000, () => { });
        }
    }


    async function updateEngblock(value) {
        try {
            const result = await api.put('/licenca/engblock', {
                token: user.tok_email,
                engblock: value
            }, {
                headers: {
                    'x-access-token': token,
                }
            })
            user.engblock = value
            setUser({ ...user })
            NotificationManager.info("Dados atualizados com successo", '', 3000, () => { });
        } catch (error) {
            NotificationManager.error(error.message, '', 5000, () => { });
        }
    }


    async function downloadLicense(englicenca) {
        try {

            const result = await api.get("downloadLicenca/parceiro?englicenca=".concat(englicenca), {
                headers: {
                    'x-access-token': token,
                }
            })
            if (result.status === 200) {
                const link = document.createElement('a');
                link.href = BASEURLRAIZ.concat(result.data);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // F:/express/engfoodsite/license/011/licenca engfood 1610.eng
            }
        } catch (error) {
            NotificationManager.error(error.message, '', 5000, () => { });
        }
    }


    function renderLicencas(item, index) {
        let isProrrogar = false
        const dias = dayjs().diff(dayjs(item.data2), 'days')
        if (dias > -1 && dias < 3)
            isProrrogar = true

        if (isDesktop) {
            return <div style={{ display: 'flex', flexDirection: 'column' }} key={'license-'.concat(index)} >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}
                        onClick={() => {
                            if (parceiro.id === 0) {
                                setLicencaSelected(item)
                                setLicencaModal(true)
                            }
                        }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center', width: 100
                        }}>
                            <img alt="licença" src={require('../../../images/license_key.png')} />
                            <Tag color="#108ee9">{getTipoLicenca(item.dias)}</Tag>
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }}>
                            <Space>
                                <Typography.Text>Validade: </Typography.Text>
                                <Typography.Text> {dayjs(item.data1).format('DD/MM/YYYY')} </Typography.Text>
                                <Typography.Text> até </Typography.Text>
                                <Typography.Text> {dayjs(item.data2).format('DD/MM/YYYY')} </Typography.Text>
                                {
                                    item.cortesia === 1 && <Tag color='orange'>Prorrogação de licença</Tag>
                                }
                            </Space>
                            <Space onClick={() => {
                                if (parceiro.id === 0) {
                                    setLicencaSelected(item)
                                    setLicencaModal(true)
                                }
                            }}>
                                Status:
                                {checkLicencavalida(item)}
                            </Space>
                            <Space>
                                <img alt="pc" src={require('../../../images/display.png')} />
                                {item.fiscalmanager === 1 && <Tag color={'#108ee9'}>
                                    + Fiscal NFC-e/SAT
                                </Tag>}
                            </Space>
                            {(item.fiscalmanager !== 1 && item.canbaixar) && <Space>
                                <Button icon={<img alt='nfce' style={{ marginRight: 5 }}
                                    src={require('../../../images/danfe.png')} height={16} />}
                                    style={{ marginTop: 5 }} type='primary' danger
                                    onClick={() => {
                                        setSelected(item)
                                        setIsModalNfce(true)
                                    }}> Quero emitir NFC-e</Button>
                            </Space>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            item.canbaixar && <Button type='default'
                                onClick={() => downloadLicense(item.transacao)}
                                icon={<DownloadOutlined style={{ fontSize: 20 }} />}>
                                Baixar licença
                            </Button>
                        }

                        {isProrrogar === true && <Button type='primary' size='small' danger style={{ marginTop: 3 }}
                            onClick={() => setProrrogarLicenca(true)}>
                            Prorrogar licença
                        </Button>
                        }
                    </div>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 9 }} />
            </div>
        } else {
            return <div style={{ display: 'flex', flexDirection: 'column' }} key={'license-'.concat(index)}>
                <Space>
                    <img alt="licença" src={require('../../../images/license_key.png')} />
                    <Tag color="#108ee9">{getTipoLicenca(item.dias)}</Tag>
                </Space>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>
                    <Typography.Text>Validade: </Typography.Text>
                    <Space>
                        <Typography.Text> {dayjs(item.data1).format('DD/MM/YYYY')} </Typography.Text>
                        <Typography.Text> até </Typography.Text>
                        <Typography.Text> {dayjs(item.data2).format('DD/MM/YYYY')} </Typography.Text>
                    </Space>
                    Status:
                    <Space onClick={() => {
                        if (parceiro.id === 0)
                            setLicencaSelected(item)
                    }}>
                        {checkLicencavalida(item)}
                    </Space>
                    <Space>
                        <img alt="pc" src={require('../../../images/display.png')} />
                        {item.fiscalmanager === 1 && <Tag color={'#108ee9'}>
                            + Fiscal NFC-e/SAT
                        </Tag>}
                    </Space>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        item.canbaixar && <Button type='default' size='large'
                            onClick={() => downloadLicense(item.transacao)}
                            icon={<DownloadOutlined style={{ fontSize: 20 }} />}>
                            Baixar licença
                        </Button>
                    }

                    {isProrrogar === true && <Button type='primary' size='small' danger style={{ marginTop: 3 }}
                        onClick={() => setProrrogarLicenca(true)}>
                        Prorrogar licença
                    </Button>
                    }
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 9 }} />
            </div>
        }
    }

    function renderTablePagamento() {
        if (isDesktop) {
            return <Table
                pagination={false}
                key={"codigo"}
                dataSource={user?.pagamentos}
                size="small">
                <Column title="Data" dataIndex={"data"} key={"data"}
                    render={(text, item, index2) => {
                        return dayjs(text).format('DD/MM/YYYY')
                    }} />
                <Column title="Transação" dataIndex={"codigo"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Descrição" dataIndex={"descricao"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Valor" dataIndex={"total"} key={"codigo"}
                    render={(text, item, index2) => {
                        return text
                    }} />
                <Column title="Status" dataIndex={"status"} key={"codigo"}
                    render={(text, item, index2) => {
                        return getStatus(text)
                    }} />
            </Table>
        } else {
            return <Table
                pagination={false}
                dataSource={user?.pagamentos}
                size="small">
                <Column title="Dados" dataIndex={"data"} key={"data"}
                    render={(text, item, index2) => {
                        return <div>
                            <div>
                                {dayjs(text).format('DD/MM/YYYY')}
                            </div>
                            <div>
                                {item.codigo}
                            </div>
                            <div>
                                {item.descricao}
                            </div>
                            <div>
                                {item.total}
                            </div>
                            <div>
                                {getStatus(item.status)}
                            </div>
                        </div>
                    }} />
            </Table>
        }
    }


    async function salvarLicenca() {
        try {
            setLoading(true)
            const result = await api.post('licenca', licencaSelected, {
                headers: { 'x-access-token': token }

            })
            setLicencaModal(false)
            message.success('Licença salva com sucesso!')
            getCliente()
        } catch (error) {
            message.error(error.message)
        }
        setLoading(false)
    }


    async function saveProrrogarLicenca() {
        try {
            setLoading(true)
            const result = await api.post('licenca/prorrogar', {
                email: user.tok_email,
                origem: 0
            }, {
                headers: { 'x-access-token': token }

            })
            setProrrogarLicenca(false)
            message.success('Licença salva com sucesso!')
            getCliente()
        } catch (error) {
            message.error('Não foi possível gerar prorrogação de licença! '.concat(error.message))
        }
        setProrrogarLicenca(false)
    }



    return <div style={{ display: 'flex', flexDirection: 'column' }}>

        {user && <div>
            <Row style={{ fontSize: 11 }}>
                <Col span={24}>
                    <Card title={<strong> ESTABELECIMENTO </strong>}
                        extra={<Button type='default' size="large"
                            icon={<EditOutlined style={{ fontSize: 20 }} />}
                            style={{ minWidth: isDesktop ? 170 : 80 }}
                            onClick={() => {
                                props.setModalRegister(true)
                            }}>{isDesktop ? "Alterar dados" : 'Alterar'}</Button>}
                        style={{ backgroundColor: 'whitesmoke' }}>
                        <Row gutter={[4, 2]}>
                            <Col xs={24} lg={4}>
                                Fantasia:
                            </Col>
                            <Col xs={24} lg={20}>
                                <Typography.Text style={{ color: 'royalblue' }}>  {user.fantasia} </Typography.Text>
                            </Col>
                            <Col xs={24} lg={4}>
                                Razão Social:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.razao_social}
                            </Col>
                            <Col xs={24} lg={4}>
                                Telefone Principal:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.fone1}
                            </Col>
                            <Col xs={24} lg={4}>
                                CNPJ:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cnpj}
                            </Col>
                            <Col xs={24} lg={4}>
                                Local:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cidade.concat(' - ', user.estado)}
                            </Col>
                            <Col xs={24} lg={4}>
                                E-mail:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.tok_email}
                            </Col>
                            <Col xs={24} lg={4}>
                                Bloqueado:
                            </Col>
                            <Col xs={24} lg={20}>
                                <Select
                                    style={{ width: 150 }}
                                    size='small'
                                    value={user?.engblock}
                                    onChange={(e) => {
                                        //  var item = JSON.parse(JSON.stringify(user.appdelivery))                                       
                                        updateEngblock(e)
                                    }}>
                                    <Option value={0}>Não</Option>
                                    <Option value={1}><span style={{ color: 'red' }}>Sim</span></Option>
                                </Select>
                            </Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col span={24}><strong>DADOS DO RESPONSÁVEL</strong></Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col xs={24} lg={4}>
                                Nome:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.responsavel}
                            </Col>
                            <Col xs={24} lg={4}>
                                CPF:
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.cpf}
                            </Col>
                            <Col xs={24} lg={4}>
                                Senha
                            </Col>
                            <Col xs={24} lg={20}>
                                {user.pwd}
                            </Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col span={24}><strong>PLATAFORMA DELIVERY </strong>{'(Site e App)'}</Col>
                            <Col span={24}>
                                <Divider style={{ marginLeft: 0, marginRight: 0, marginBottom: 4, marginTop: 4 }} />
                            </Col>
                            <Col xs={24} lg={24}>
                                {renderApp()}
                            </Col>


                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row style={{ margin: 0, marginTop: 10 }}>
                <Col span={24}>
                    <Card title={<div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <strong> SUAS LICENÇAS </strong>
                        <Space direction={isDesktop ? 'horizontal' : 'vertical'}>

                            <Button type='primary'
                                onClick={() => {
                                    if (parceiro?.id === 0) {
                                        setLicencaSelected({
                                            lic_id: 0,
                                            transacao: '',
                                            data1: dayjs().format('YYYY-MM-DD'),
                                            data2: dayjs().add(30, 'days').format('YYYY-MM-DD'),
                                            fiscalmanager: 0,
                                            email: user.tok_email,
                                            dias: 30,
                                            origin: 1,
                                            cortesia: 0
                                        })
                                        setLicencaModal(true)
                                    } else {
                                        setIsModalBuyVisible(true)
                                    }
                                }}>Nova Licença</Button>
                        </Space>
                    </div>}
                        style={{ backgroundColor: 'whitesmoke', fontSize: 11 }}>
                        {loadCliente && <Space direction='horizontal'>
                            <LoadingOutlined />
                            <strong> Loading... </strong></Space>}
                        {
                            user?.licencas?.map((item, index) => {
                                return renderLicencas(item, index)
                            })
                        }
                    </Card>
                </Col>
            </Row>



            <Row style={{ margin: 0, marginTop: 0, marginBottom: 10 }}>
                <Col span={24}>
                    <Card title={<strong> SEUS PEDIDOS </strong>} style={{ backgroundColor: 'whitesmoke' }}>
                        {
                            renderTablePagamento()
                        }
                    </Card>
                </Col>
            </Row>
        </div>
        }

        <Modal title="Solicitação de Site e App"
            closable={true}
            width={isDesktop ? '90%' : '100%'}
            footer={null}
            visible={isModalApp}
            destroyOnClose={true}
            onCancel={() => setIsModalApp(false)}>
            <div>
                <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <Alert
                        message="Atenção"
                        description={<div>
                            <p>Devido a recentes mudanças na loja da Apple(App Store) os aplicativos para iOS devem ser enviados pela própria conta do estabelecimento.
                                Com isso você deve criar uma conta e pagar a taxa correspondente a Apple para publicar seu aplicativo na App Store.
                                <hr />
                                <p class="mb-0">Para a loja Google Play(Plataforma Android) não é preciso criar conta nem mesmo pagar qualquer taxa.
                                    Os aplicativos serão
                                    postados pelo próprio Programa Engfood sem nenhum custo adicional e totalmente personalizado com sua marca.</p>
                                <hr />
                                <p>Lembramos que essas novas regras e diretrizes não são da Programa Engfood e sim da própria Apple(App Store).<br />Para mais detalhes acesse
                                    <a href="https://developer.apple.com/app-store/review/guidelines/" target="_blank"> App Store Review Guidelines</a></p>

                            </p>
                        </div>
                        }
                        type="success"
                        showIcon
                    />

                    <Typography.Text>
                        Envie sua logomarca no formato PNG ou JPG.
                    </Typography.Text>
                    <Upload
                        {...props2}
                        headers={
                            {
                                'x-access-token': token,
                            }
                        }
                        name="file"
                        showUploadList={false}
                        action={BASEURL.concat('uploads/uploadImageLogoParceiro?tokenid=', user.tokenid)}
                        listType="picture"
                        maxCount={1}>
                        <Button icon={<UploadOutlined />}>Enviar</Button>
                    </Upload>
                </Space>
            </div>

        </Modal>


        <Modal title="Download Programa Engfood"
            closable={true}
            width={600}
            footer={null}
            visible={isModalVisible}
            destroyOnClose={true}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}>
            <DownloadCtrl setIsModalVisible={setIsModalVisible} />
        </Modal>



        <Modal open={isModalBuyVisible}
            title={"Adicionar Licença"}
            destroyOnClose={true}
            closable={true}
            okText={"Pagar"}
            onOk={() => pagar()}
            okButtonProps={{
                loadding: loading
            }}
            onCancel={() => setIsModalBuyVisible(false)}>
            <div>
                <Row gutter={[8, 8]}>
                    <Col span={12}
                        style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                        {"Licença Mensal "}</Col>
                    <Col span={12}>
                        <InputNumber style={{ marginRight: 5, marginLeft: 5, width: 150 }}
                            min={10} max={1000}
                            formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={parceiro.valueLicenca} />
                    </Col>
                    <Col span={12}
                        style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                        {" Emitir NFCe "}</Col>
                    <Col span={12} >
                        <Select value={nfce} style={{ marginRight: 5, marginLeft: 5, width: 150 }}
                            onChange={(value) => setNfce(value)}>
                            <Select.Option value={0}>Não</Select.Option>
                            <Select.Option value={1}>{"+R$".concat(parceiro.valueFiscal)}</Select.Option>
                        </Select>
                    </Col>
                </Row>

                <Divider />
                <Row>
                    <Col span={24}>
                        <Checkbox checked={true}>
                            <strong> Estou ciente e de acordo com os prazos de liberação </strong>
                        </Checkbox>
                    </Col>
                    <Col span={24} >
                        <span style={{ fontSize: 12, marginLeft: 10 }}>
                            - Boleto bancário: Até 2 dias úteis (Não é necessário enviar o comprovante).
                        </span>
                    </Col>
                    <Col span={24}>
                        <span style={{ fontSize: 12, marginLeft: 10 }}>
                            - Cartão de Crédito/Débito: Imediata ou em algumas horas após aprovação.
                        </span>
                    </Col>
                </Row>
            </div>
        </Modal>


        <Modal title="Emissão de NFC-e/SAT"
            closable={true}
            footer={null}
            width={isDesktop ? '80%' : '100%'}
            visible={isModalNfce}
            destroyOnClose={true}
            onCancel={() => setIsModalNfce(false)}>
            <div style={{ fontSize: 13 }}>
                <h2>Fiscal NFC-e/SAT</h2>
                <div>
                    <p>
                        <b style={{ color: 'orangered' }}>Leia com ATENÇÃO antes de clicar em Comprar NFC-e!</b>
                    </p>
                    <br />
                    <p>No estado de <b>São Paulo</b><br />
                        Ainda não disponível (Não efetue a compra desse módulo)</p>
                    <br />
                    <p>No estado do <b>Ceará</b><br />
                        Ainda não disponível (Em fase de testes, não efetuar a compra, em breve disponível)</p>
                    <br />
                    <p>Nos demais <b>estados</b><br />
                        <ul>
                            <li>Possuir Certificado Digital modelo A1</li>
                            <li>Obter o Código de Segurança do Contribuinte (CSC) na SEFAZ</li>
                            <li>Confirmar se o modelo NFC-e está disponível no seu estado, com seu contador ou na SEFAZ</li>
                        </ul>
                    </p>
                    <p style={{ color: "forestgreen" }}>O preenchimento dos dados fiscais de cada produto é de responsabilidade do cliente.</p>
                    <hr />
                    <p style={{ color: "forestgreen", fontWeight: 600 }}>
                        {'Ao comprar a licença para emitir NFC-e ele terá a mesma válidade da sua licença atual, no caso será válida até '
                            .concat(dayjs(selected?.data2).format('DD/MM/YYYY'))}
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button type="primary" size='large' onClick={() => {
                            var atual = dayjs()
                            var final = dayjs(selected?.data2)
                            var dias = final.diff(atual, 'days')
                            // console.log('Valor por dia: ', dias, parceiro.valueFiscal / 30, dias * parceiro.valueFiscal / 30)
                            console.log(selected)

                            pagarFiscal(selected, "Fiscal Manager ".concat(dias, ' dias'),
                                dias * parceiro.valueFiscal / 30,
                                dias, selected.lic_id)

                        }}>
                            Comprar NFC-e
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal title="Licença Engfood"
            closable={true}
            footer={null}
            afterClose={() => setLicencaSelected(undefined)}
            width={isDesktop ? 600 : '100%'}
            visible={licencaModal}
            destroyOnClose={true}
            onCancel={() => setLicencaModal(false)}>
            <Space direction='vertical'>
                <Row gutter={[8, 8]}>
                    <Col span={6}>Início
                    </Col>
                    <Col span={18}>
                        <DatePicker
                            allowClear={false}
                            format={"DD/MM/YYYY"}
                            value={dayjs(licencaSelected?.data1)}
                            onChange={(date, dateString) => {
                                // console.log(date, dateString);
                                var licenca = Object.assign({ ...licencaSelected })
                                licenca.data1 = date.format('YYYY-MM-DD')
                                licenca.data2 = dayjs(licenca.data1).add(licenca?.dias, 'days')
                                    .format('YYYY-MM-DD')
                                // console.log(licenca.data2 )
                                setLicencaSelected({ ...licenca })
                            }} />
                    </Col>
                    <Col span={6}>
                        Dias
                    </Col>
                    <Col span={18}>
                        <Select defaultValue={30} style={{ width: 150 }}
                            value={licencaSelected?.dias}
                            onChange={(value) => {
                                var licenca = Object.assign(licencaSelected)
                                licenca.dias = value
                                licenca.data2 = dayjs(licenca?.data1).add(value, 'days')
                                    .format('YYYY-MM-DD')
                                setLicencaSelected({ ...licenca })
                            }
                            }>
                            <Option value={30}>30 (mensal)</Option>
                            <Option value={90}>90 (trimestral)</Option>
                            <Option value={180}>180 (semestral)</Option>
                            <Option value={365}>365 (anual)</Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        Término
                    </Col>
                    <Col span={18}>
                        <DatePicker
                            disabled
                            format={"DD/MM/YYYY"}
                            value={dayjs(licencaSelected?.data2)}
                        />
                    </Col>
                    <Col span={6}>Fiscal</Col>
                    <Col span={18}>
                        <Select defaultValue={0}
                            value={licencaSelected?.fiscalmanager}
                            onChange={(value) => {
                                setLicencaSelected({ ...licencaSelected, fiscalmanager: value })
                            }
                            }>
                            <Option value={0}>Não</Option>
                            <Option value={1}>Sim</Option>
                        </Select>
                    </Col>
                    <Col span={6}>Transação</Col>
                    <Col span={18}>{licencaSelected?.transacao}</Col>
                    <Col span={24}><Divider /></Col>
                    <Col span={24} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Space>
                            <Button onClick={() => setLicencaModal(false)}>Voltar</Button>
                            <Button type='primary'
                                onClick={() => { salvarLicenca() }}>Salvar</Button>
                        </Space>
                    </Col>
                </Row>
            </Space>

        </Modal>


        <Modal title="Prorrogar Licença Engfood"
            closable={true}
            footer={null}
            afterClose={() => { }}
            width={isDesktop ? 600 : '100%'}
            visible={prorrogarLicenca}
            destroyOnClose={true}
            onCancel={() => setProrrogarLicenca(false)}>
            <div>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Alert message="Só é possível prorrogar a licença caso não tenha nenhuma prorrogação ativa. Ao adquirir nova licença os 5 dias de prorrogação serão descontados." />
                    </Col>
                    <Col span={24} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <Space>
                            <Button onClick={() => setProrrogarLicenca(false)}>Voltar</Button>
                            <Button type='primary'
                                onClick={() => { saveProrrogarLicenca() }}>Prorrogar</Button>
                        </Space>
                    </Col>
                </Row>

            </div>

        </Modal>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button type='primary'
                onClick={() => navigate('/parceiros/clientes')}>Voltar</Button>
        </div>

    </div>
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    //  saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ParceiroCliente)