import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Nav,
  NavLink,
  Bars,
  NavMenu
} from '../Navbar/NavbarElements';
import {
  Popover, Input, Typography,Button, Affix, Space, Modal, Checkbox} from 'antd'
import { validateEmail } from '../../service/util';
import * as parceiroAction from '../../store/actions/parceiro.action'
import { UserOutlined } from '@ant-design/icons'


import { useNavigate } from 'react-router-dom';





export const Navbar = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [email, setEmail] = useState('')
  const { parceiro, saveParceiro } = props
  const [responsavel, setResponsavel] = useState('')
  const [isPop, setIsPop] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (parceiro) {
        var res = parceiro?.parceiro.nome.split(' ')
        setResponsavel("Olá, ".concat(res[0]))
      }
    } catch (error) { }
  }, [parceiro])

  return (
    <div>
      <Affix offsetTop={0} style={{ marginTop: 0 }}>
        <Nav style={{backgroundColor:'#020242'}}>
          <NavLink to='/'>
            <Space style={{ display: 'flex', alignItems: 'center' }}>
              <img src={require('../../images/engfood-round.png')} alt='logo' style={{ height: 48 }} />
              <Typography.Title style={{ color: 'white' }} level={3}>Programa Engfood</Typography.Title>
            </Space>
          </NavLink>
          <Bars />
          <NavMenu>
            <NavLink to='/' activeStyle>
              Inicio
            </NavLink>
            
            <NavLink to={''} onClick={() => setIsModalVisible(true)} style={{ color: 'white' }}>
              Download
            </NavLink>
            {
              !parceiro ? <Button style={{ marginLeft: 10 }} type='primary'
                onClick={() => navigate('/parceiros/login')}>
                Área do Revendedor
              </Button>
                : <Popover
                  style={{ position: 'fixed' }}
                  placement={'bottom'}
                  content={<div style={{
                    width: 130, display: 'flex', flexDirection: 'column',
                    alignItems: 'flex-start', justifyContent: 'flex-start',
                  }}>

                    <NavLink to="/parceiros" activeStyle style={{ color: 'royalblue' }}>
                      Minha conta
                    </NavLink>

                    <Button type='text' block style={{ textAlign: 'left', color: 'royalblue' }}
                      onClick={() => {
                        navigate('/')
                        saveParceiro(null)
                      }}>Sair
                    </Button>
                  </div>}
                  title={null}
                  trigger="click"
                  visible={isPop}
                  onVisibleChange={() => setIsPop(!isPop)}>
                  <Space>
                    <Button type='dashed' onClick={() => setIsPop(true)}>
                      <UserOutlined />
                      <Typography.Text >
                        {responsavel}
                      </Typography.Text>
                    </Button>
                  </Space>
                </Popover>
            }

          </NavMenu>

        </Nav>
      </Affix>

      
      <Modal title="Download Programa Engfood"
        closable={true}
        width={600}
        footer={null}
        visible={isModalVisible}
        destroyOnClose={true}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input type={'email'} placeholder="Informe seu e-mail"
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
          <Button type='primary' shape='round' size='large'
            disabled={!validateEmail(email)}
            block onClick={() => { }} style={{ marginTop: 10 }}>Download agora</Button>
          <Checkbox checked={true} style={{ fontSize: 11, display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            Aceito receber e-mails de informações do Programa Engfood.<Button type='link' style={{ fontSize: 11 }}>Política de privacidade. </Button>
          </Checkbox>
        </div>
      </Modal>


      

    </div>
  );
};



const mapStateToProps = (state) => ({
  parceiro: state.parceiroReducer.parceiro
})

const mapDispatchToProps = dispatch => ({
  saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)


