import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FooterCtrl from '../../../components/footer'
import { Typography, Button, Space, Divider, Affix, Drawer } from 'antd'
import * as parceiroAction from '../../../store/actions/parceiro.action'
import {
    MenuOutlined, MailOutlined, FacebookOutlined, InstagramOutlined,
    GlobalOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect';
import '../../parceiros/parceiros.css'
import logoPNG from '../../../images/engfood-round.png'
import { Layout, Menu } from 'antd';
import DashboardCtrl from '../../../components/parceiros/dashboardCtrl'
import ClientesCtrl from '../../../components/parceiros/clientesCtrl'
import ContratoCtrl from '../../../components/parceiros/contratoCtrl'
import DadosCtrl from '../../../components/parceiros/dadosCtrl'
//import LicencasCtrl from '../../../components/parceiros/licencasCtrl'
import TransacoesCtrl from '../../../components/parceiros/transacoesCtrl'
import TokensCtrl from '../../../components/parceiros/tokensCtrl'
import RevendedoresCtrl from '../../../components/parceiros/revendedoresCtrl'
import PaymentsCtrl from '../../../components/parceiros/paymentsCtrl'
import * as moment from 'moment'
import DownloadsCtrl from '../../../components/parceiros/downloads'
import { Routes, Route, Outlet, Link } from "react-router-dom";
import ManProdutos from '../../../components/manager/ManProdutos'

const { Header, Content, Sider } = Layout;


export const ManagerPage = (props) => {

    const navigate = useNavigate()
    const { token, user } = props
    const [collapsed, setCollapsed] = useState(false);
    const [status, setStatus] = useState('Todos')
    const [key, setKey] = useState('1')
    const [visibleDrawer, setVisibleDrawer] = useState(false)


    function renderCtrl() {


        return <ManProdutos setStatus={setStatus} setKey={setKey} />

        switch (key) {
            case '1':
                return <DashboardCtrl setStatus={setStatus} setKey={setKey} />
            case '2':
                return <ClientesCtrl status={status} />
            // case '3':
            //     return <LicencasCtrl />
            case '4':
                return <DadosCtrl />
            case '5':
                return <ContratoCtrl setStatus={setStatus} setKey={setKey} />
            case '6':
                return <DashboardCtrl setStatus={setStatus} setKey={setKey} />
            case '7':
                return <TransacoesCtrl setStatus={setStatus} setKey={setKey} />
            case '8':
                return <TokensCtrl setStatus={setStatus} setKey={setKey} />
            case '9':
                return <RevendedoresCtrl setStatus={setStatus} setKey={setKey} />
            case '10':
                return <PaymentsCtrl setStatus={setStatus} setKey={setKey} />
            case '11':
                return <DownloadsCtrl setStatus={setStatus} setKey={setKey} />
            default:
                return <DashboardCtrl setStatus={setStatus} setKey={setKey} />
        }
    }

    function renderIcons() {
        return <div style={{ fontSize: 12, marginRight: 0, alignItems: 'center' }}>
            <a href='/' target={'_blank'}>
                <GlobalOutlined style={{ marginRight: 10, fontSize: 22 }} />
            </a>
            <a href='https://www.facebook.com/programaengfood' target={'_blank'}>
                <FacebookOutlined style={{ marginRight: 10, fontSize: 22 }} />
            </a>
            <a href='https://www.instagram.com/programaengfood/' target={'_blank'}>
                <InstagramOutlined style={{ marginRight: 20, fontSize: 22 }} />
            </a>

            {
                isDesktop && <React.Fragment>
                    <MailOutlined style={{ marginRight: 4 }} />
                    <Typography.Text style={{ marginRight: 15 }}>
                        {user?.tok_email}
                    </Typography.Text>
                </React.Fragment>
            }
        </div>
    }


   


    function renderDesktop() {
        return (<React.Fragment>            
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div style={{
                        display: 'flex', justifyContent: 'center',
                        marginTop: 20, flexDirection: 'column', alignItems: 'center'
                    }} >
                        <img src={logoPNG} height={60} />
                        {!collapsed && <Typography.Text style={{ color: 'white', fontSize: '0.7em' }}>{user?.fantasia}</Typography.Text>}

                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={key}
                        onClick={(value) => {
                            if (value.key === '20') {

                                navigate('/account')
                            } else if (value.key === '19') {
                                const link = document.createElement('a');
                                link.target = "_blank"
                                link.href = 'http://manual.programaengfood.com.br';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                            else {
                                setStatus('Todos')
                                setKey(value.key)
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: 1,
                                icon: <img src={require('../../../images/business-report.png')} height={24} />,
                                label: 'Dashboard',
                            },
                            {
                                key: 2,
                                icon: <img src={require('../../../images/employees.png')} height={24} />,
                                label: 'Clientes',
                            },
                            {
                                key: 4,
                                icon: <img src={require('../../../images/folder.png')} height={24} />,
                                label: 'Produtos',
                            },
                            {
                                key: 5,
                                icon: <img src={require('../../../images/doc.png')} height={24} />,
                                label: 'Estoque Crítico',
                            },
                            {
                                key: 7,
                                icon: <img src={require('../../../images/online-transfer.png')} height={24} />,
                                label: 'Caixas',
                            },
                            user?.id === 0 && {
                                key: 8,
                                icon: <img src={require('../../../images/key.png')} height={24} />,
                                label: 'Movimentos',
                            },
                            user?.id === 0 && {
                                key: 9,
                                icon: <img src={require('../../../images/salesman.png')} height={24} />,
                                label: 'Revendedores',
                            },
                            user?.id === 0 && {
                                key: 10,
                                icon: <img src={require('../../../images/cashless-payment.png')} height={24} />,
                                label: 'Pagamentos',
                            },
                            {
                                key: 11,
                                icon: <img src={require('../../../images/baixar.png')} height={24} />,
                                label: 'Downloads',
                            },
                            {
                                key: 19,
                                icon: <img src={require('../../../images/manual.png')} height={24} />,
                                label: 'Tutoriais',
                            },
                            {
                                key: 20,
                                icon: <img src={require('../../../images/logout.png')} height={24} />,
                                label: 'Sair',
                            },
                        ]}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            padding: 0
                        }}>

                        <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <MenuOutlined style={{ marginLeft: 10 }} onClick={() => setCollapsed(!collapsed)} />
                            {renderIcons()}
                        </Space>


                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 500,
                        }}>
                        {renderCtrl()}
                    </Content>
                </Layout>
            </Layout>
            <FooterCtrl />
        </React.Fragment>
        );
    }


    function renderMobile() {
        return <div style={{ margin: 0 }}>
            <Affix offsetTop={0}>
                <div style={{
                    display: 'flex', justifyContent: 'space-between',
                    minHeight: 50, backgroundColor: 'white', alignItems: 'center'
                }}>
                    <Space>
                        <MenuOutlined style={{ marginLeft: 10 }}
                            onClick={() => setVisibleDrawer(!visibleDrawer)} />
                        <img src={logoPNG} height={30} />
                        <Typography.Text strong>Revendedor</Typography.Text>
                    </Space>

                    {
                        renderIcons()
                    }
                </div>
            </Affix>
            <Divider style={{ marginTop: 10 }} />
            <Layout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 10,
                        minHeight: 500,
                    }}>
                    {renderCtrl()}
                </Content>
            </Layout>

            <Drawer
                title={<Space>
                    <img src={logoPNG} height={30} />
                    <div>
                        <Typography.Text strong>Revendedor </Typography.Text>
                    </div>
                </Space>}
                placement={'left'}
                width={'60%'}
                onClose={() => setVisibleDrawer(false)}
                visible={visibleDrawer}>
                <Space direction='vertical' style={{ marginLeft: -15 }}>
                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/business-report.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('1')
                        }}>Dashboard</Button>
                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/employees.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('2')
                        }}>Clientes</Button>
                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/folder.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('4')
                        }}>Seus Dados</Button>
                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/doc.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('5')
                        }}>Contrato</Button>
                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/online-transfer.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('7')
                        }}>Transações</Button>
                    {user?.id === 0 && <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/key.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('8')
                        }}>Tokens</Button>}
                    {user?.id === 0 && <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/salesman.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('9')
                        }}>Revendedores</Button>}
                    {user?.id === 0 && <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/cashless-payment.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('10')
                        }}>Pagamentos</Button>}

                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/baixar.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            setStatus('Todos')
                            setKey('11')
                        }}>Downloads</Button>

                    <Button type='link' icon={<img style={{ marginRight: 10 }} src={require('../../../images/manual.png')} height={24} />}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = 'http://manual.programaengfood.com.br';
                            link.target = "_blank"
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}>Tutoriais</Button>

                    <Button type='link' icon={<img style={{ marginRight: 10 }}
                        src={require('../../../images/logout.png')} height={24} />}
                        onClick={() => {
                            setVisibleDrawer(false)
                            navigate('/account')
                        }}>Sair</Button>
                </Space>
            </Drawer>

        </div>


    }


    return isDesktop ? renderDesktop() : renderMobile()

}



function Home() {
    return (
        <div>
            <h2>Home</h2>
        </div>
    );
}

function About() {
    return (
        <div>
            <h2>About</h2>
        </div>
    );
}

function Dashboard() {
    return (
        <div>
            <h2>Dashboard</h2>
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}


const mapStateToProps = (state) => ({
    user: state.userReducer.user?.user,
    token: state.userReducer.user?.token
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPage)