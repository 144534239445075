import React from 'react'
import { connect } from 'react-redux'
import NavbarCtrl from '../../components/Navbar'
import FooterCtrl from '../../components/footer'

export const PoliticaPrivacidadePage = (props) => {


    return (
        <div>
             <NavbarCtrl />
            <div class="container" style={{marginTop:40}}>
                <h2>Política de Privacidade</h2>
                <p>Utilizamos cookies para melhorar o desempenho e proporcionar a melhor experiência em nosso site/portal.</p>
                <p>
                    A política de privacidade do App busca definir regras para os usuários do  Aplicativo. Como usuário do Facebook,
                    você está sujeito, também, à política de privacidade do Facebook. Verifique as configurações de privacidade da sua conta para mais informações.
                </p>
                <h4>Coletando Informações Sobre Você</h4>
                <p>
                    Para que a experiência de uso do aplicativo seja personalizada, o aplicativo recolhe algumas informações sobre o usuário.
                    Estas informações também são usadas para monitorar o desempenho e desenvolver melhorias para o aplicativo. O Programa Engfood garante o sigilo e a
                    segurança destas informações, porém, poderá fornecer informações nos seguintes casos: (i) para cumprimento de solicitação de Autoridades Policiais,
                    bem como do Poder Judiciário e/ou para cumprimento de determinações de qualquer ente da Administração Pública, Direta, Indireta; e (ii)
                    para as empresas subsidiárias, filiadas, coligadas, controladas, controladoras ou demais empresas pertencentes ao Programa Engfood com intuito de
                    levar a conhecimento do usuário os produtos e/ou serviços das referidas empresas e/ou organizações, caso autorizado pelo usuário.
                </p>
                <h4>Nossos Princípios</h4>
                <p>
                    Faremos os melhores esforços para proteger a segurança dos dados dos usuários dos nossos aplicativos.
                    <br /><br/>
                    Respeitaremos a sua privacidade. Você poderá receber e-mails com newsletter somente do Programa Engfood, e somente se
                    tiver feito essa opção durante o seu cadastro.
                </p>
                <h4>Dividindo suas Informações conosco</h4>
                <p>
                    Quando você acessa o aplicativo pela primeira vez, você vai ver a tela de permissão do Facebook, que vai informá-lo sobre quais dados poderão ser
                    acessados pelo Programa Engfood. Se não concordar, você não poderá usar o aplicativo.

                    Ao aceitar, você concorda que o Programa Engfood acesse seus dados pessoais (nome, foto do perfil, gênero e outras informações compartilhadas
                    publicamente conforme suas configurações), além de sua rede de amigos e informações sobre seu uso do aplicativo como, por exemplo,
                    os artigos que você está lendo.

                    Se você interagir com os dados do aplicativo, por exemplo, fazendo uma avaliação, esta informação poderá ser vista por seus amigos. Se você decidir por
                    não compartilhar a avaliação de um destinos, atração ou cia aérea com a sua rede, poderá desmarcar a opção “publicar no facebook” que estará
                    logo acima do botão enviar no formulário de avaliação.

                    Comentários ou quaisquer outros conteúdos publicados em resposta aos posts e aos conteúdos do Programa Engfood no aplicativo para
                    Facebook não serão monitorados pela nossa equipe e estarão sujeitos aos Termos e Condições de Uso do Facebook.
                </p>
                <h4>Cancelando o Aplicativo</h4>
                <p>
                    Para cancelar seu registro em nosso aplicativo, você deverá desinstalar o app.
                    Ou você pode cancelar o aplicativo acessando-o diretamente na sua página do Facebook em "configurações de conta".

                    Uma vez que você tenha removido o aplicativo, nós não mais teremos permissão para acessar suas informações do Facebook e as suas
                    informações serão removidas de nosso aplicativo. Se você selecionar um artigo nosso no Facebook depois do seu cancelamento você será direcionado.
                </p>

                <h4>Informações Legais e como nos Contatar:</h4>
                <p>
                    Este instrumento será regido e interpretado de acordo com a legislação brasileira, sendo eleito o Foro da Comarca de Fortaleza, CE,
                    para questões a ele relativas, com renúncia expressa a qualquer outro.
                    Se você tiver quaisquer dúvidas, por favor, faça contato conosco por meio do site
                </p>

                <a href="https://www.programaengfood.com.br">www.programaengfood.com.br</a>
                <br /><br /><br /><br />

            </div>
            <FooterCtrl/>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PoliticaPrivacidadePage)