import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FooterCtrl from '../../../components/footer'
import NavbarCtrl from '../../../components/NavbarParceiro'
import {Row, Col, Input, Typography, Divider, Button, Space, Card} from 'antd'
import { api } from '../../../service/api'
import { useNavigate } from 'react-router-dom';
import * as parceiroAction from '../../../store/actions/parceiro.action'


export const LoginPage = (props) => {

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [msgError, setmsgError] = useState('')
    const { parceiro, saveParceiro } = props
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        saveParceiro(undefined)
      //  if (parceiro)
      //      navigate('/parceiros')

    }, [])



    async function login() {
        try {
            setLoading(true)
            setmsgError('')
            const result = await api.post('/parceiros/login', { email: email, senha: senha })
            if (result.status === 200 && result.data?.token) {
                saveParceiro(result.data)
                navigate('/parceiros')
            } else {
                setmsgError('E-mail e/ou senha incorretos!')
            }

        } catch (error) {
            console.log('ERROR', error.message)
            setmsgError(error.message)
        }
        setLoading(false)
    }

    return (
        <div>
            <NavbarCtrl />
            <Row>
                <Col xs={24} lg={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'whitesmooke' }}>
                        <div style={{ maxWidth: 500, margin: 50 }}>
                        <Space>
                                <img src={require('../../../images/white-label.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>White Label</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Adicione as suas cores, o seu logotipo. 
                                        Venda o nosso produto e o seu serviço em conjunto, sob o nome da marca em que os seus clientes confiam</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/bills.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Alta lucratividade</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Margem de lucro de até 40% sobre o valor da licença.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/money.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Zero de investimento</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Não é necessário fazer qualquer tipo de investimento para ser revendedor.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/database.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Migração de banco de dados</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Migramos as informações de seus clientes em até 24h.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/portal.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Portal da Revenda</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Portal completo para gestão de sua carteira de clientes.</span>
                                    </div>
                                </div>
                            </Space>
                            <Space style={{ marginTop: 20 }}>
                                <img src={require('../../../images/central-de-atendimento.png')} height={36} />
                                <div>
                                    <div>
                                        <div>
                                            <strong>Suporte 7/7</strong>
                                        </div>
                                        <span style={{ fontSize: 14 }}>Estamos prontos para lhe atender, não importa o dia da semana.</span>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'whitesmooke' }}>
                        <Card style={{ maxWidth: 420, margin: 50 }}>
                            <Row style={{ display: 'flex', justifyContent: 'center', maxWidth: 400 }}>
                                <Col span={24}>
                                    <strong>ÁREA DO REVENDEDOR</strong>
                                    <Divider style={{ marginTop: 5 }} />
                                </Col>
                                <Col span={24}>
                                    <Typography.Text style={{ color: 'red' }}>{msgError && msgError}</Typography.Text>
                                </Col>
                                <Col span={24}>E-mail</Col>
                                <Col span={24}>
                                    <Input value={email} onChange={e => setEmail(e.target.value)} />
                                </Col>
                                <Col span={24} style={{marginTop:5}}>Senha</Col>
                                <Col span={24}>
                                    <Input.Password value={senha} onChange={e => setSenha(e.target.value)} />
                                </Col>
                                <Col span={24}>
                                    <div style={{
                                        color: 'royalblue', display: 'flex',
                                        flexDirection: 'row-reverse', marginTop: 8, marginBottom: 8
                                    }}>
                                        {
                                            /* <Button type='link'>Não lembra a senha?</Button> */
                                        }
                                        
                                    </div>
                                </Col>
                                <Col span={24} style={{marginTop:5}}>
                                    <Button type='primary' block loading={loading}
                                        onClick={() => login()}>Login</Button>
                                </Col>
                                <Col span={24}><Divider /></Col>
                                <Col span={24}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        Quer se tornar um parceiro? <Button type='link'
                                            onClick={() => navigate('/parceiros/registro')}>Criar conta</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>
            </Row>
            <FooterCtrl />

           

        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)