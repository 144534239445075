import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const InstalarImpressoraGeneric = (props) => {

    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/como-instalar-uma-impressora-com-driver-generico-no-windows/');
    },[])

    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                <div id="eckb-article-content-header">
                    <br /><br />
                    <h4 class="eckb-article-title kb-article-id" id="84">
                        <strong>Como Instalar uma Impressora com Driver Genérico no Windows</strong>
                    </h4></div><div id="eckb-article-content-body">
                    <div id="kb-article-content">
                        <p>Instalando e configurando a impressora no Window</p>
                        <p>01- Entre em iniciar &#8211; Dispositivos e Impressoras</p>
                        <p><img class="alignnone size-large"
                            src={require('../../images/manual/1w7.jpg')} style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>02- Clique em ADICIONAR UMA IMPRESSORA</p>
                        <p>03- Clique em ADICIONAR UMA IMPRESSORA LOCAL</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/2w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>04- Clique em GENERIC E GENERIC / TEXT ONLY E AVANÇAR<img class="alignnone size-large"
                            src={require("../../images/manual/4w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>05- Digite um nome para a impressora e clique em Avançar.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/5w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>06- Marque a opção Compartilhar Impressora e clique em Avançar.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/6w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p><strong>Impressora instalada agora vamos a configuração do tamanho do papel.</strong></p>
                        <p>07- Clique com botão direito sobre o desenho da impressora.</p>
                        <p>08- Clique em Preferências de impressão.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/7w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>09 &#8211; Clique em Avançado.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/8w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>10- Clique em Tamanho do Papel e selecione LEDGER e clique em OK.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/9w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }}/></p>
                        <p>11- Novamente clique com o botão direito sobre o desenho da impressora e na opção Propriedades da Impressora.</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/10w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>12- Clique na aba Comandos de Impressora.</p>
                        <p>13-  No campo iniciar trabalho de impressão digite: &lt;1B&gt;C&lt;01&gt;</p>
                        <p><img class="alignnone size-large" src={require("../../images/manual/11w7.jpg")}  style={{ objectFit: 'cover', maxWidth: '100%' }} /></p>
                        <p>Confira se seguiu todos os passos acima, clique em OK, entre no <strong>Programa Engfood</strong> e faça o teste.</p>
                        <p>&nbsp;</p>
                    </div>
                </div><div id="eckb-article-content-footer"></div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 20 }}>
                <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
            </div>       <br /><br />

            <FooterCtrl />

        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InstalarImpressoraGeneric)