import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Button, Tab } from 'antd'
import { api } from '../../service/api'
import { useNavigate } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';

const { Column } = Table

export const ManProdutos = (props) => {

  const { user, saveUser, token } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    load()
  }, [])


  async function load() {
    try {
      setLoading(true)
      const response = await api.post('manager/produtos', { tipos: 0, produtoNome: '%%', isInsumo: 0 }, {
        headers: {
          'x-access-token': token,
        }
      })
      console.log(response.data)
      setData(response.data)
    } catch (error) {
      NotificationManager.error(error.message, '', 5000, () => { });

    }
    setLoading(false)
  }

  return (
    <div>
      <Table dataSource={data} size="small" rowKey={"produtoID"} loading={loading}>
        <Column title="Nome" dataIndex={"produtoNome"} key={"produtoID"}
          render={(text, item, index) => {
            return text
          }} />
          <Column title="Categoria" dataIndex={"menuDescricao"} key={"produtoID"}
          render={(text, item, index) => {
            return text
          }} />
        <Column title="Est. controlado" dataIndex={"estoqueControlado"} key={"produtoID"}
          render={(text, item, index) => {
            return text === 0 ? 'Não' : 'Sim'
          }} />
        <Column title="Estoque" dataIndex={"produtoEstoque"} key={"produtoID"}
          render={(text, item, index) => {
            return text
          }} />
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user?.user,
  token: state.userReducer.user?.token
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ManProdutos)