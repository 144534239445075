import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Space, Steps, Typography, Table, Tag, Row, Col, Input, Checkbox, message, Alert, Divider } from 'antd'
import Navbar from '../../components/Navbar'
import Footer from '../../components/footer'
import { CheckOutlined, DeleteOutlined, PlusOutlined, DollarOutlined } from '@ant-design/icons'
import nfce from '../../images/nfce.png'
import licencekey from '../../images/license_key.png'
import android from '../../images/android.png'
import site from '../../images/site.png'
import engfoodMobile from '../../images/engfood-mobile.png'
import iosmobile from '../../images/ios.png'
import { useNavigate, useLocation } from 'react-router-dom'
import pciPNG from '../../images/pci.png'
import mpformaspagamentoPNG from '../../images/mp-formas-pagamento.png'
import * as userAction from '../../store/actions/user.action'
import { api, BASEURL, BASEURLRAIZ } from '../../service/api'


export const Failure = (props) => {


    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <section>
                <div style={{ margin: 40 }}>
                    <Alert
                        message="Pagamento não autorizado."
                        description={<span>Seu pagamento não foi autorizado pelo <strong> Mercadopago </strong>.</span>}
                        type="warning"
                        showIcon
                    />

                    <Divider />

                    <strong style={{ color: 'royalblue' }}>PRAZOS:</strong>
                    <div style={{ fontSize: 13 }}>
                        <b> Boleto Bancário: </b> Até 3 dias úteis, como o processo de validação é automático NÃO é necessário enviar o comprovante.
                    </div>
                    <div style={{ fontSize: 13 }}>
                        <b>Cartão de Débito/Crédito/PIX: </b> Liberação imediata ou em algumas horas após aprovação pela operadora, qualquer dúvida relacionada,
                        você também pode verificar junto a sua operadora de cartão.
                    </div>

                    <br /><br />
                    <Button type='link' onClick={() => navigate(-2)}
                        style={{ fontSize: 13 }}>Ir a página principal
                    </Button>

                </div>
            </section>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Failure)