import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Button, Typography, Modal, Table, Space, message, Input } from 'antd'
import { api } from '../../../service/api'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import '@inovua/reactdatagrid-community/index.css';
import { useNavigate } from 'react-router-dom'
import * as parceiroAction from '../../../store/actions/parceiro.action'
import { isDesktop } from 'react-device-detect';
import ClienteCtrl from '../../../components/parceiros/clienteCtrl'
import RevendedorCtrl from '../../../components/parceiros/revendedorCtrl'

const { Column } = Table
const { Search } = Input

export const ParceiroRevendedores = (props) => {
    const [data, setData] = useState([])
    const { parceiro, saveParceiro, token } = props
    const [status, setStatus] = useState(props.status)
    const [searching, setSeaching] = useState('')
    const navigate = useNavigate()
    const [contratoSelecionado, setContratoSelecionado] = useState({})
    const [modalAccount, setModalAccount] = useState(false)
    const [revendedor, setRevendedor] = useState(undefined)


    useEffect(() => {
        //  console.log('parceiros', parceiro.clientes)
    }, [status, searching])

    useEffect(() => {
        load()
    }, [])

    async function load() {
        try {
            // setLoadLicencas(true)
            const response = await api.get('parceiros/all', {
                headers: {
                    'x-access-token': token,
                }
            })
            setData(response.data)
        } catch (error) {
            console.log(error.message)
            if (error?.response.status === 401) {
                saveParceiro(null)
                navigate('/parceiros/login')
            }
            message.error(error.message)
        }
    }

    function getClienteFilter() {
        var dados = data
        if (searching.trim() !== '')
            dados = dados.filter((item) => item.fantasia.toLowerCase().indexOf(searching.toLowerCase()) > -1)
        return dados
    }


    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                <Space>
                    <Search
                        placeholder="Nome fantasia"
                        allowClear
                        enterButton={isDesktop ? "Pesquisar" : 'OK'}
                        onSearch={(value) => setSeaching(value)}
                    />
                </Space>
            </div>
            <div>
                <Table dataSource={getClienteFilter()} pagination={false}
                    size="small" showHeader={isDesktop ? true : false}
                    footer={() => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Row gutter={[8, 8]} style={{ width: '100%' }}>
                            <Col xs={12} lg={6}><Typography.Text>Total: {getClienteFilter().length}</Typography.Text></Col>
                            <Col xs={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}> </Col>
                        </Row>

                    </div>}>
                    {isDesktop && <Column title={isDesktop ? <strong>Fantasia</strong> : ''}
                        dataIndex={"fantasia"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <Button size='small' type='link' onClick={() => {
                                setRevendedor(record)
                            }} style={{ fontSize: 12 }}> {text} </Button>

                        }} />
                    }
                    {isDesktop && <Column title={<strong>Nome</strong>}
                        dataIndex={"nome"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column title={<strong>E-mail</strong>}
                        dataIndex={"email"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column width={130} title={<strong>Telefone</strong>}
                        dataIndex={"fone1"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column title={<strong>UF</strong>}
                        dataIndex={"uf"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{text}</div>
                        }} />
                    }
                    {isDesktop && <Column width={100} title={<strong>Data</strong>}
                        dataIndex={"createAt"} key={"tok_email"}
                        render={(text, record, index) => {
                            return <div style={{ fontSize: 12 }}>{dayjs(text).format('DD/MM/YYYY')}</div>
                        }} />
                    }
                    {
                        !isDesktop && <Column
                            render={(text, record, index) => {
                                return <div
                                    style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                                    <div style={{ color: 'royalblue', fontSize: 12 }} onClick={() => {
                                        // setContratoSelecionado(record)
                                        //  setModalAccount(true)
                                    }}>
                                        <strong style={{ textTransform: 'uppercase' }}> {record.fantasia} </strong>
                                    </div>
                                    <div style={{ fontSize: 12 }}><Button type='link' size='small' onClick={() => setRevendedor(record)}>{record.nome}</Button> </div>
                                    <div style={{ wordBreak: 'break-all', textTransform: 'lowercase' }}> {record.email} </div>
                                    <div style={{ fontSize: 12 }}>{record.fone1}</div>
                                    <div style={{ fontSize: 12 }}>{record.uf}</div>
                                    <div style={{ fontSize: 12 }}>{dayjs(record.createAt).format('DD/MM/YYYY')}</div>
                                </div>
                            }} />

                    }

                </Table>
            </div>
            {
                <Modal visible={modalAccount}
                    width={isDesktop ? '80%' : '100%'}
                    footer={null}
                    destroyOnClose={true}
                    closable={true}
                    onCancel={() => setModalAccount(false)}>
                    <ClienteCtrl parceiroID={parceiro.id}
                        setModalAccount={setModalAccount} cliente={contratoSelecionado} />
                </Modal>
            }


            <Modal visible={revendedor}
                width={isDesktop ? '80%' : '100%'}
                footer={null}
                destroyOnClose={true}
                afterClose={() => setRevendedor(undefined)}
                closable={true}
                onCancel={() => setRevendedor(undefined)}>
                <RevendedorCtrl setRevendedor={setRevendedor} revendedor={revendedor}/>
            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro?.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (parceiro) => dispatch(parceiroAction.save_parceiro(parceiro)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ParceiroRevendedores)