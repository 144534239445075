import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import { Typography, Alert, Button } from 'antd'
import Navbar from '../../components/Navbar'
import { FooterCtrl } from '../../components/footer'
import { useNavigate } from 'react-router-dom'

export const MeuAppDelivery = (props) => {


    useEffect(()=>{
        window.location.replace('http://manual.programaengfood.com.br/site-e-app-de-delivery/');
    },[])

    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 20 }}>
                <Typography.Title level={4}>
                    APLICATIVO E/OU SIE DE DELIVERY <br />
                    <span style={{ fontSize: 13, fontWeight: 'normal' }}>
                        Atualizado em 30/05/2020
                    </span>
                </Typography.Title>


                <Alert
                    message="Informação"
                    description="Manual referente a verão 11 ou superior do Programa Engfood."
                    type="info"
                    showIcon
                />

                <br />
                <Typography.Title level={5}>Iniciando</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    As configurações para o aplicativo/site de delivery devem ser efetuadas pelo menu Configurações/Appdelivery em seu programa Engfood Desktop(Computador) ou
                    pelo <a href='https://portal.menuengfood.com.br' target={'_blank'}> Portal Menuengfood </a>.
                </div>
                <br /> <br />
                <Typography.Title level={5}>Habilitar Recebimento de Pedidos</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Se ao verificar na sua área de cliente que o seu app já encontra-se disponível ou você recebeu e-mail ou ligação
                    telefonica da nosso equipe de suporte você deve configurar seu computador para sincronizar os dados com seu app de
                    delivey, para isso acesse o menu Configurações/AppDelivery e opção Ativação.
                    Agora você poderá cadastrar as categorias, produtos, taxas etc. <br />
                    <img src={require('../../images/manual/app-ativacao.png')} alt="ativacao" style={{objectFit:'cover', maxWidth:'100%'}}/>
                    <br />
                    Após clicar para sincronizar o app com o seu Programa Engfood você deve visualizar e mensagem abaixo.
                    <br />
                    <img src={require('../../images/manual/app-ativacao-2.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                    <br />

                </div><br /><br />
                <Typography.Title level={5}>Cadastro de Categorias</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Os produtos disponíveis que seu cliente vê no app de delivery são agrupados pelas suas respectivas categorias, para
                    configurar as categorias no app acesse o menu configurações / categorias , clique em adicionar categoria e selecione uma
                    categoria já cadastrada na sua versão desktop(computador) do programa engfood adicionar e a mesma será cadastrada com
                    disponível no app.<br />
                    <img src={require('../../images/manual/app-categorias-2.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                    <br />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Ao cadastrar uma categoria como indisponível ela não será exibida no app de delivery nem seus produtos vinculados."
                        type="warning"
                        showIcon
                    />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Dica!"
                        description="Depois de cadastrada a categoria você poderá alterar seu status ou limitar por horário a mesma dentro do aplicativo/site de delivery."
                        type="success"
                        showIcon
                    />

                </div><br /><br />

                <Typography.Title level={5}>Cadastro de Produtos</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Os produtos disponíveis que seu cliente vê no app de delivery são agrupados pelas suas respectivas categorias, para cadastrar
                    um novo produto no app acesse o menu configurações / appdelivery / produtos , clique em adicionar produto no canto superior
                    direito da sua tela, selecione um produto já cadastrado na sua versão desktop(computador) do programa engfood, clique em
                    adicionar, será exibida uma tela de confirmação em qual categoria o produto deve ser vinculado, informe a categoria correta e
                    clique em ok.
                    <br />
                    <img src={require('../../images/manual/app-produtos.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                    <br />
                    <strong> # Configurar Grupos </strong>
                    <br />
                    Grupo são opção que o cliente pode selecionar ao adicionar um produto ao seu pedido, por exemplo posso ter um grupo onde
                    posso adcionar complementos ao produto do cliente como mais queijo, mais salame, mais azeitonas também poderia ter um grupo
                    onde selecionaria o tipo de pão de um sanduiche por exemplo.
                    <br />
                    <img src={require('../../images/manual/app-produtos-edit.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                    <br />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Caso o produto cadastrado não tenha uma categoria disponível o mesmo não será exibido no cardápio do app."
                        type="warning"
                        showIcon
                    />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Dica!"
                        description="Após seu cadastro, você poderá alterar o preço, complementos, combos, etc através do menu configurações > App delivery."
                        type="success"
                        showIcon
                    />
                </div><br /><br />

                <Typography.Title level={5}>Formas de Pagamento</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Acesse o menu Configurações / Appdelivery / Formas de Pagamento e ative as formas de pagamento aceitas pelo estabelecimento.
                    <br />
                    <img src={require('../../images/manual/app-formas-pagamento.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                </div><br /><br />


                <Typography.Title level={5}>Horários de Funcionamento</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Acesse o menu Configurações / App delivery / Funcionamento e defina quantos turnos existem no dia e ajuste os horários de funcionamento conforme a necessidade. Clique em Salvar .
                    <br />
                    <strong># Configurando funcionamento após as 00:00 horas </strong><br />
                    Na imagem abaixo o estabelecimento funciona na sexta de 18:00 às 1:00 horas do sábado..
                    <br />
                    <img src={require('../../images/manual/app-horarios.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/>
                </div><br /><br />


                <Typography.Title level={5}>Bairros Atendidos</Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Acesse o menu configurações / Bairros clique em adicionar bairro, selecione um bairro já cadastrada na sua versão desktop (computador) e clique em adicionar para salvar no seu app.
                    <br />
                    <img src={require('../../images/manual/app-bairros.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/><br />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Os nomes dos bairros devem ser cadastrados corretamente para que os pedidos possam ser efetuados pelo aplicativo de delivery. Os nomes dos bairros devem coincidirem com os nomes cadastrados na base de dados dos Correios."
                        type="warning"
                        showIcon
                    />
                </div><br /><br />

                <Typography.Title level={5}>Tempo de Entrega e pedido minímo </Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Acesse o menu Configurações / Appdelivery / configurações para alterar o tempo de entrega dos pedidos para os clientes.
                    Você também poderá informar um valor mínimo para que um pedido possa ser realizado.
                    <br />
                    <img src={require('../../images/manual/app-config.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/><br />

                </div><br /><br />


                <Typography.Title level={5}>Recebendo Pedidos </Typography.Title>
                <div style={{ color: '#696969', fontSize: 14 }}>
                    Ao receber um pedido, o Programa Engfood abrirá a tela de pedidos do alicativo seguido de um aviso sonoro. Clique no pedido e em Atender .<br />
                    Ao confirmar um pedido o mesmo será inserido em sua tela de pedidos delivery e enviados para os setores de produção(impressoras ou Engfood Cozinha) automaticamente.
                    <br />
                    <img src={require('../../images/manual/app-pedido.png')} alt="ativacao"  style={{objectFit:'cover', maxWidth:'100%'}}/><br />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Quando um pedido é realizado pelo cliente, o restaurante tem 10 MINUTOS para confirmar o pedido. Caso não confirme o pedido, ele será automaticamente cancelado!
                        Pedidos cancelados não devem ser entregues, pois o cliente não estará esperando por ele."
                        type="warning"
                        showIcon
                    />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Dica!"
                        description="Pode ser que, por algum motivo, o estabelecimento não possa atender um certo pedido. Caso o pedido ainda não esteja cancelado e o estabelecimento queira dar alguma satisfação ao cliente, explicando o motivo do cancelamento, clique em confirmar na tela de pedidos pendentes para ir a tela de detalhes do pedido, depois clique em cancelar será exibida uma tela para você informar uma justificativa. O cliente receberá a notificação de cancelamento e a justificativa no próprio celular ou tablet."
                        type="success"
                        showIcon
                    />
                    <Alert
                        style={{ marginTop: 10 }}
                        message="Importante!"
                        description="Para receber os pedidos realizados pelo App de Delivery é necessário possuir internet no computador onde o Programa Engfood está instalado."
                        type="warning"
                        showIcon
                    /><br />
                    Os pedidos delivery devem ser processados por apenas um computador, você deve definir qual computador receberá os pedidos dentro da sua rede interna.
                    <br />
                    Acesse o menu configurações/sistema/computador e habilitar a opção Pedidos via app delivery, salve e reinicie o Programa Engfood para carregar as novas configurações.
                    <br />
                    Após reiniciar a programa você já estará apto a receber os pedidos no computador.

                </div><br /><br />

                <Typography.Title level={5}>Evite Prejuízos</Typography.Title>
                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="Pedidos com valores elevados devem ser confirmados com o cliente ao telefone, antes da preparação, a fim de evitar prejuízos. Determine um valor de referência para que seu atendente tenha como padrão.
                    Exemplo: para pedidos acima de R$ 150, deve-se ligar para o cliente e confirmar o pedido, assim como os dados de contato e entrega."
                    type="warning"
                    showIcon
                />
                <br />
                <Alert
                    style={{ marginTop: 10 }}
                    message="Importante!"
                    description="Quando receber um pedido de um novo cliente, entre em contato com ele a fim de confirmar o pedido e todos os dados de entrega. Além de evitar prejuízos, essa ação aumenta a chance de recompra do cliente em seu restaurante."
                    type="warning"
                    showIcon
                />

            </div>


            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight:20 }}>
                <Button type='link' onClick={() => navigate(-1)}>{" << Voltar"}</Button>
            </div>       <br /><br />

            <FooterCtrl />
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MeuAppDelivery)