import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FooterCtrl from '../../components/footer'
import { Typography, Space, Divider, Affix, Drawer } from 'antd'
import * as parceiroAction from '../../store/actions/parceiro.action'
import {
    MenuOutlined, MailOutlined, FacebookOutlined, InstagramOutlined,
    GlobalOutlined
} from '@ant-design/icons'
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom'
import { isDesktop } from 'react-device-detect';

import logoPNG from '../../images/engfood-round.png'
import { Breadcrumb, Layout, Menu } from 'antd';
const { Header, Content, Footer } = Layout;

export const ManagerOutlet = (props) => {
    return (<React.Fragment>
        <Layout>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <div className="demo-logo" />

            </Header>
            <Content
                className="site-layout"
                style={{
                    padding: '0 50px',
                }}>

                <div
                    style={{
                        padding: 24,
                        minHeight: 380
                    }}
                >
                    <Outlet />
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >

            </Footer>
        </Layout>

    </React.Fragment>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerOutlet)