import React from 'react';
import './App.css';
//import 'antd/dist/antd.css';
//@import '~antd/dist/antd.css';

import { Provider } from 'react-redux'
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'

import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Rotas from './Rotas'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  // export const App = (props) => {

  return (<React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Rotas />
      </PersistGate>
    </Provider>
    <NotificationContainer />
  </React.Fragment>
  );
}

export default App;
