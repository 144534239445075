import axios from 'axios';

const baseUrl = 'https://programaengfood.com.br/api/v1.0/';
//const baseUrl = 'http://localhost/api/v1.0/';

export const api = axios.create({baseURL:baseUrl});
//export default api;
const baseUrlraiz = 'https://programaengfood.com.br/';
//const baseUrlraiz = 'http://localhost/';

const baseUrlViaCep = 'https://viacep.com.br/ws/';
export const apiviacep = axios.create({baseURL:baseUrlViaCep});
//export default viacep;

//PAGAR.ME export const ENCRYPT_KEY =   "ek_test_kSsao2ZIDvQwezLlAAJOYda7RGKJqY"; 
//export const ENCRYPT_KEY =   "ek_live_CLweNIoV6kVsfsmxjZ5VucBXi1e0Iz";

//MERCADOPAGO
//Credencias antes do erro de invelid token do mercado pago em 24/05/2024
//export const MP_PUBLIC_KEY = 'APP_USR-928324a9-5946-4b16-8e00-25d1c1956546'

//25/05/2024 alterada
export const MP_PUBLIC_KEY = 'APP_USR-71546c57-14e9-47b7-8e5d-17c2399fcf08'

export const BASEURL = baseUrl
export const BASEURLRAIZ = baseUrlraiz



