import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import FooterCtrl from '../../../components/footer'
import { Typography, Space, Divider, Affix, Drawer } from 'antd'
import * as parceiroAction from '../../../store/actions/parceiro.action'
import {
    MenuOutlined, MailOutlined, FacebookOutlined, InstagramOutlined,
    GlobalOutlined
} from '@ant-design/icons'
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom'
import { isDesktop } from 'react-device-detect';
import '../parceiros.css'
import logoPNG from '../../../images/engfood-round.png'
import { Layout, Menu } from 'antd';


const pedidos_menuengfood = '/parceiros/pedidos-menuengfood'
const PARCEIROS = '/parceiros'
const PARCEIROS_CLIENTES = '/parceiros/clientes'
const PARCEIROS_DADOS = '/parceiros/dados'
const PARCEIROS_CONTATO = '/parceiros/contrato'
const PARCEIROS_TRANSACTION = '/parceiros/transacoes'
const PARCEIROS_TOKENS = '/parceiros/tokens'
const PARCEIROS_DOWNLOADS = '/parceiros/downloads'
const PARCEIROS_PAGAMENTOS ='/parceiros/pagamentos'


const { Header, Content, Sider } = Layout;


export const ParceirosOutlet = (props) => {

    const location = useLocation()
    const navigate = useNavigate()
    const [register, setRegister] = useState(props.parceiro?.parceiro)
    const { token, saveParceiro } = props
    const [collapsed, setCollapsed] = useState(false);
    const [status, setStatus] = useState('Todos')
    const [key, setKey] = useState('1')
    const [visibleDrawer, setVisibleDrawer] = useState(false)

    useEffect(() => {
       // console.log(location)
        setKeySelection()
    }, [])

    function setKeySelection() {
        switch (location.pathname) {
            case pedidos_menuengfood:
                setKey('12'); break;
            case PARCEIROS:
                setKey('1'); break;
            case PARCEIROS_CLIENTES:
                setKey('2'); break;
            case PARCEIROS_CONTATO:
                setKey('5'); break;
            case PARCEIROS_DADOS:
                setKey('4'); break;
            case PARCEIROS_DOWNLOADS:
                setKey('11'); break;
            case PARCEIROS_TOKENS:
                setKey('8'); break;
            case PARCEIROS_TRANSACTION:
                setKey('7'); break;
                case PARCEIROS_PAGAMENTOS:
                setKey('10'); break;
        }
    }


    function renderIcons() {
        return <div style={{ fontSize: 12, marginRight: 0, alignItems: 'center' }}>
            <a href='/' target={'_blank'}>
                <GlobalOutlined style={{ marginRight: 10, fontSize: 22 }} />
            </a>
            <a href='https://www.facebook.com/programaengfood' target={'_blank'}>
                <FacebookOutlined style={{ marginRight: 10, fontSize: 22 }} />
            </a>
            <a href='https://www.instagram.com/programaengfood/' target={'_blank'}>
                <InstagramOutlined style={{ marginRight: 20, fontSize: 22 }} />
            </a>

            {
                isDesktop && <React.Fragment>
                    <MailOutlined style={{ marginRight: 4 }} />
                    <Typography.Text style={{ marginRight: 15 }}>
                        {register?.email}
                    </Typography.Text>
                </React.Fragment>
            }
        </div>
    }



    function renderDesktop() {
        return (<React.Fragment>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div style={{
                        display: 'flex', justifyContent: 'center',
                        marginTop: 20, flexDirection: 'column', alignItems: 'center'
                    }} >
                        <img src={logoPNG} height={60} />
                        {!collapsed && <Typography.Text style={{ color: 'white' }}>Programa Engfood</Typography.Text>}
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={key}
                        onClick={(value) => {
                            if (value.key === '20') {
                                saveParceiro(undefined)
                                navigate('/parceiros/login')
                            } else if (value.key === '19') {
                                const link = document.createElement('a');
                                link.target = "_blank"
                                link.href = 'http://manual.programaengfood.com.br';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                            else {
                                setStatus('Todos')
                                setKey(value.key)
                                switch (value.key) {
                                    case '1': navigate(PARCEIROS)
                                        break;
                                    case '2': navigate(PARCEIROS_CLIENTES)
                                        break;
                                    case '3':
                                        break;
                                    case '4': navigate(PARCEIROS_DADOS)
                                        break;
                                    case '5': navigate(PARCEIROS_CONTATO)
                                        break;
                                    case '6':
                                        break;
                                    case '7': navigate(PARCEIROS_TRANSACTION)
                                        break;
                                    case '8': navigate(PARCEIROS_TOKENS)
                                        break;
                                    case '9': navigate('/parceiros/revendedores')
                                        break;
                                    case '10': navigate(PARCEIROS_PAGAMENTOS)
                                        break;
                                    case '11': navigate(PARCEIROS_DOWNLOADS)
                                        break;
                                    case '12': navigate(pedidos_menuengfood)
                                        break;
                                }
                            }
                        }}
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: 1,
                                icon: <img src={require('../../../images/business-report.png')} height={24} />,
                                label: 'Dashboard',
                            },
                            {
                                key: 2,
                                icon: <img src={require('../../../images/employees.png')} height={24} />,
                                label: 'Clientes',
                            },
                            {
                                key: 4,
                                icon: <img src={require('../../../images/folder.png')} height={24} />,
                                label: 'Seus dados',
                            },
                            {
                                key: 5,
                                icon: <img src={require('../../../images/doc.png')} height={24} />,
                                label: 'Contrato',
                            },
                            {
                                key: 7,
                                icon: <img src={require('../../../images/online-transfer.png')} height={24} />,
                                label: 'Transações',
                            },
                            register?.id === 0 && {
                                key: 8,
                                icon: <img src={require('../../../images/key.png')} height={24} />,
                                label: 'Tokens',
                            },
                            register?.id === 0 && {
                                key: 9,
                                icon: <img src={require('../../../images/salesman.png')} height={24} />,
                                label: 'Revendedores',
                            },
                            register?.id === 0 && {
                                key: 10,
                                icon: <img src={require('../../../images/cashless-payment.png')} height={24} />,
                                label: 'Pagamentos',
                            },
                            {
                                key: 11,
                                icon: <img src={require('../../../images/baixar.png')} height={24} />,
                                label: 'Downloads',
                            },
                            {
                                key: 12,
                                icon: <img src={require('../../../images/app.png')} height={24} />,
                                label: 'Menuengfood',
                            },
                            {
                                key: 19,
                                icon: <img src={require('../../../images/manual.png')} height={24} />,
                                label: 'Tutoriais',
                            },
                            {
                                key: 20,
                                icon: <img src={require('../../../images/logout.png')} height={24} />,
                                label: 'Sair',
                            },
                        ]}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{
                            padding: 0
                        }}>

                        <Space style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <MenuOutlined style={{ marginLeft: 10 }} onClick={() => setCollapsed(!collapsed)} />
                            {renderIcons()}
                        </Space>


                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 500,
                        }}>

                        <Outlet />

                    </Content>
                </Layout>
            </Layout>
            <FooterCtrl />
        </React.Fragment>
        );
    }


    function renderMobile() {
        return <div style={{ margin: 0 }}>
            <Affix offsetTop={0}>
                <div style={{
                    display: 'flex', justifyContent: 'space-between',
                    minHeight: 50, backgroundColor: 'white', alignItems: 'center'
                }}>
                    <Space>
                        <MenuOutlined style={{ marginLeft: 10 }}
                            onClick={() => setVisibleDrawer(!visibleDrawer)} />
                        <img src={logoPNG} height={30} />
                        <Typography.Text strong>Revendedor</Typography.Text>
                    </Space>

                    {
                        renderIcons()
                    }
                </div>
            </Affix>
            <Divider style={{ marginTop: 10 }} />
            <Layout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 10,
                        minHeight: 500,
                    }}>
                    {
                        //renderCtrl()
                    }
                    <Outlet />
                </Content>
            </Layout>

            <Drawer
                title={<Space>
                    <img src={logoPNG} height={30} />
                    <div>
                        <Typography.Text strong>Revendedor </Typography.Text>
                    </div>
                </Space>}
                placement={'left'}
                width={'60%'}
                onClose={() => setVisibleDrawer(false)}
                visible={visibleDrawer}>
                <Menu
                    style={{ width: '100%' }}

                    mode="inline"
                    selectedKeys={key}
                    onClick={(value) => {
                        if (value.key === '20') {
                            saveParceiro(undefined)
                            navigate('/parceiros/login')
                        } else if (value.key === '19') {
                            const link = document.createElement('a');
                            link.target = "_blank"
                            link.href = 'http://manual.programaengfood.com.br';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        else {
                            setStatus('Todos')
                            setKey(value.key)
                            switch (value.key) {
                                case '1': navigate('/parceiros')
                                    break;
                                case '2': navigate('/parceiros/clientes')
                                    break;
                                case '3':
                                    break;
                                case '4': navigate('/parceiros/dados')
                                    break;
                                case '5': navigate('/parceiros/contrato')
                                    break;
                                case '6':
                                    break;
                                case '7': navigate('/parceiros/transacoes')
                                    break;
                                case '8': navigate('/parceiros/tokens')
                                    break;
                                case '9': navigate('/parceiros/revendedores')
                                    break;
                                case '10': navigate('/parceiros/pagamentos')
                                    break;
                                case '11': navigate('/parceiros/downloads')
                                    break;
                                case '12': navigate('/parceiros/pedidos-menuengfood')
                                    break;
                            }
                        }
                        setVisibleDrawer(false)
                    }}
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: 1,
                            icon: <img src={require('../../../images/business-report.png')} height={24} />,
                            label: 'Dashboard',
                        },
                        {
                            key: 2,
                            icon: <img src={require('../../../images/employees.png')} height={24} />,
                            label: 'Clientes',
                        },
                        {
                            key: 4,
                            icon: <img src={require('../../../images/folder.png')} height={24} />,
                            label: 'Seus dados',
                        },
                        {
                            key: 5,
                            icon: <img src={require('../../../images/doc.png')} height={24} />,
                            label: 'Contrato',
                        },
                        {
                            key: 7,
                            icon: <img src={require('../../../images/online-transfer.png')} height={24} />,
                            label: 'Transações',
                        },
                        register?.id === 0 && {
                            key: 8,
                            icon: <img src={require('../../../images/key.png')} height={24} />,
                            label: 'Tokens',
                        },
                        register?.id === 0 && {
                            key: 9,
                            icon: <img src={require('../../../images/salesman.png')} height={24} />,
                            label: 'Revendedores',
                        },
                        register?.id === 0 && {
                            key: 10,
                            icon: <img src={require('../../../images/cashless-payment.png')} height={24} />,
                            label: 'Pagamentos',
                        },
                        {
                            key: 11,
                            icon: <img src={require('../../../images/baixar.png')} height={24} />,
                            label: 'Downloads',
                        },
                        {
                            key: 12,
                            icon: <img src={require('../../../images/app.png')} height={24} />,
                            label: 'Menuengfood',
                        },
                        {
                            key: 19,
                            icon: <img src={require('../../../images/manual.png')} height={24} />,
                            label: 'Tutoriais',
                        },
                        {
                            key: 20,
                            icon: <img src={require('../../../images/logout.png')} height={24} />,
                            label: 'Sair',
                        },
                    ]}
                />
            </Drawer>

        </div>


    }


    return isDesktop ? renderDesktop() : renderMobile()




}

const mapStateToProps = (state) => ({
    parceiro: state.parceiroReducer.parceiro,
    token: state.parceiroReducer.parceiro?.token
})

const mapDispatchToProps = dispatch => ({
    saveParceiro: (user) => dispatch(parceiroAction.save_parceiro(user)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ParceirosOutlet)