import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Typography, Button, Input, Card, Col, Row, Divider, Space, Alert, Select, DatePicker,
    message
} from 'antd'
import * as userAction from '../../store/actions/user.action'
import InputMask from 'react-input-mask'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br';
import PhoneInput from '../../components/phoneMask'
import { validateEmail } from '../../service/util'
import { cpf } from 'cpf-cnpj-validator'
import { api } from '../../service/api'
import axios from 'axios'
import { isDesktop } from 'react-device-detect'
import TelefoneBrasileiroInput from 'react-telefone-brasileiro'

const { Option } = Select

export const RegisterCtrl = (props) => {

    const navigate = useNavigate()
    // const { user, saveUser } = props
    const [register, setRegister] = useState(props.cliente)
    const [mask, setMask] = useState("(99) 9999-9999");
    const [loading, setloading] = useState(false)

    useEffect(() => {
        // console.log('user register', register)
        setRegister({ ...register, parceiro: props.parceiroID })
        // console.log('Parceiro ID', props.parceiroID)
    }, [])

    useEffect(() => {
        validarForm()
    }, [register])

    function validarForm() {
        var erro = false
        try {
            if (!register)
                erro = true
            if (!register)
                erro = true
            if (!register?.pwd)
                erro = true
            if (!validateEmail(register?.tok_email))
                erro = true
            if (!register?.fantasia)
                erro = true

            if (!register?.razao_social)
                erro = true
            if (register?.fone1?.includes('_') || register?.fone1 === '')
                erro = true
            if (!register?.responsavel)
                erro = true
            if (!register?.cep)
                erro = true
            if (!register?.endereco)
                erro = true
            if (!register?.bairro)
                erro = true
            if (!register?.cidade)
                erro = true
        } catch (error) {

        }
        return erro
    }

    async function salvar() {
        try {
            setloading(true)

            const response = await api.post('token', register)
            if (response.status === 200) {
                message.success('Dados salvos com sucesso!')
                //  saveUser(response.data)
                props.setModalRegister(false)
                // navigate('/account')
            } else {
                message.error('Não foi possível salvar os dados!')
            }
        } catch (error) {
            message.error(error.message)
        }
        setloading(false)
    }


    async function loadCep() {
        try {
            console.log('cep', register?.cep)
            setloading(true)
            const response = await axios.get('https://viacep.com.br/ws/'
                .concat(register?.cep.replace('_', ''), '/json/'))
            if (response.status === 200) {
                var aux = JSON.parse(JSON.stringify(register))
                aux.bairro = response.data.bairro
                aux.endereco = response.data.logradouro
                aux.estado = response.data.uf
                aux.cidade = response.data.localidade
                setRegister(aux)
            } else {
                message.error('Error ao consultar CEP!')
            }
        } catch (error) {
            console.log(error.message)
            message.error(error.message)
        }
        setloading(false)
    }


    return <div>
        <div style={{ margin: isDesktop ? 40 : 0 }}>
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <Card size="small" title={<Typography.Title level={5} style={{ color: 'royalblue' }} >Crie seu Token Engfood </Typography.Title>}>

                    <Row gutter={[16, 8]}>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="Email">Email</label>
                                <span style={{ color: 'red' }}>*</span>
                                <span style={{ marginLeft: 3 }}>(não poderá ser alterado)</span>

                            </div>
                            <div>
                                <Input id="Email" name="Email"
                                    disabled={register?.tokenid > 0}
                                    placeholder='Informe seu e-mail'
                                    value={register?.tok_email}
                                    status={register?.tok_emailErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!validateEmail(register?.tok_email))
                                            setRegister({ ...register, tok_emailErro: true })
                                        else
                                            setRegister({ ...register, tok_emailErro: false })
                                    }}
                                    onChange={(e) => setRegister({ ...register, tok_email: e.target.value })}
                                />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="Email"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="senha">Senha</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input.Password id="senha" name="senha" placeholder='Informe a senha'
                                    value={register?.pwd} autoComplete={'new-password'}
                                    onChange={(e) => setRegister({ ...register, pwd: e.target.value })}
                                    status={register?.pwdErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.pwd)
                                            setRegister({ ...register, pwdErro: true })
                                        else if (register?.pwd?.trim() === '' || register?.pwd !== register?.pwd2)
                                            setRegister({ ...register, pwdErro: true })
                                        else
                                            setRegister({ ...register, pwdErro: false })
                                    }}
                                />

                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="senha"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="senha2">Confirmar senha</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input.Password id="senha2" name="senha2" placeholder='Confirme a senha'
                                    value={register?.pwd2}
                                    onChange={(e) => setRegister({ ...register, pwd2: e.target.value })}
                                    status={register?.pwdErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (register?.pwd?.trim() === '' || register?.pwd !== register?.pwd2)
                                            setRegister({ ...register, pwdErro: true })
                                        else
                                            setRegister({ ...register, pwdErro: false })
                                    }} />

                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="senha2"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5} style={{ color: 'royalblue' }} >Dados do Estabelecimento </Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ marginTop: -7, marginBottom: 5 }} />
                        </Col>

                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="fantasia">Nome ou Fantasia</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="fantasia" name="fantasia" placeholder='Nome fantasia'
                                    value={register?.fantasia}
                                    onChange={(e) => setRegister({ ...register, fantasia: e.target.value })}
                                    status={register?.fantasiaErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.fantasia)
                                            setRegister({ ...register, fantasiaErro: true })
                                        else if (register?.fantasia?.trim() === '')
                                            setRegister({ ...register, fantasiaErro: true })
                                        else
                                            setRegister({ ...register, fantasiaErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="fantasia"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="razao">Sobrenome ou Razão Social</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="razao" name="razao" placeholder='Razão social'
                                    value={register?.razao_social}
                                    onChange={(e) => setRegister({ ...register, razao_social: e.target.value })}
                                    status={register?.razao_socialErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.razao_social)
                                            setRegister({ ...register, razao_socialErro: true })
                                        else if (register?.razao_social?.trim() === '')
                                            setRegister({ ...register, razao_socialErro: true })
                                        else
                                            setRegister({ ...register, razao_socialErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="razao"
                                    data-valmsg-replace="true">
                                </span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="cnpj">CNPJ</label>
                            </div>
                            <div>
                                <InputMask id="cnpj" name="cnpj" placeholder='CNPJ'
                                    mask={'99.999.999/9999-99'}
                                    value={register?.cnpj}
                                    onChange={(e) => setRegister({ ...register, cnpj: e.target.value })}
                                    children={() => <Input />}>

                                </InputMask>
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="cnpj"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="ie">Inscrição Estadual</label>
                            </div>
                            <div>
                                <Input id="ie" name="ie" placeholder='Inscrição estadual'
                                    value={register?.ins_estadual}
                                    onChange={(e) => setRegister({ ...register, ins_estadual: e.target.value })} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="ie"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="fone1">Telefone</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>

                                <TelefoneBrasileiroInput
                                    className={"telefone-brasileiro-input"}
                                    temDDD={true}
                                    separaDDD={true}

                                    value={register?.fone1}
                                    defaultValue={register?.fone1}
                                    onChange={(e) => setRegister({ ...register, fone1: e.target.value })} />

                                {
                                    /*
                                <InputMask
                                    {...props}
                                    mask={mask}
                                    onBlur={e => {
                                        if (e.target.value.replace("_", "").length === 14) {
                                            setMask("(99) 9999-9999");
                                        }
                                        if (register?.fone1?.includes('_') || register?.fone1 === '')
                                            setRegister({ ...register, fone1Erro: true })
                                        else
                                            setRegister({ ...register, fone1Erro: false })
                                    }}
                                    onFocus={e => {
                                        if (e.target.value.replace("_", "").length === 14) {
                                            setMask("(99) 99999-9999");
                                        }
                                    }}
                                    children={() => <Input status={register?.fone1Erro === true ? 'error' : ''} />}
                                    value={register?.fone1}
                                    onChange={(e) => setRegister({ ...register, fone1: e.target.value })}>

                                </InputMask>
                                    */
                                }

                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="fone1"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="fone2">Telefone Adicional</label>
                            </div>
                            <div>
                                <TelefoneBrasileiroInput
                                    className={"telefone-brasileiro-input"}
                                    temDDD={true}
                                    separaDDD={true}
                                    value={register?.fone2}
                                    defaultValue={register?.fone2}
                                    onChange={(e) => setRegister({ ...register, fone2: e.target.value })} />
                                    {
                                        /*
                                    <PhoneInput id="fone2" name="fone2" placeholder='Outro telefone'
                                    value={register?.fone2}
                                    onChange={(e) => setRegister({ ...register, fone2: e.target.value })} />
                                        */
                                    }
                                
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="fone2"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="segmento">Segmento</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Select id="segmento" name="segmento" value={register?.segmento}
                                    defaultValue={"Delivery"}
                                    style={{ width: '100%' }}
                                    onChange={(e) => setRegister({ ...register, segmento: e })}>
                                    <Option value="Bar">Bar</Option>
                                    <Option value="Cafeteria">Cafeteria</Option>
                                    <Option value="Cantina Escolar">Cantina Escolar</Option>
                                    <Option value="Churrascaria">Churrascaria</Option>
                                    <Option value="Delivery">Delivery</Option>
                                    <Option value="Hamburgueria">Hamburgueria</Option>
                                    <Option value="Lanchonete">Lanchonete</Option>
                                    <Option value="Loja de Açaí">Loja de Açaí</Option>
                                    <Option value="Pastelaria">Pastelaria</Option>
                                    <Option value="Pizzaria">Pizzaria</Option>
                                    <Option value="Restaurante">Restaurante</Option>
                                    <Option value="Outros">Outros</Option>
                                </Select>

                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="segmento"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="responsavel">Responsável (Nome e Sobrenome)</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="responsavel" name="responsavel" placeholder='Nome do responsável'
                                    value={register?.responsavel}
                                    onChange={(e) => setRegister({ ...register, responsavel: e.target.value })}
                                    status={register?.responsavelErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.responsavel)
                                            setRegister({ ...register, responsavelErro: true })
                                        else if (register?.responsavel?.trim() === '')
                                            setRegister({ ...register, responsavelErro: true })
                                        else
                                            setRegister({ ...register, responsavelErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="responsavel"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="cpf">CPF</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <InputMask id="cpf" name="cpf" placeholder='CPF do responsável'
                                    mask={"999.999.999-99"}
                                    value={register?.cpf}
                                    onChange={(e) => setRegister({ ...register, cpf: e.target.value })}
                                    children={() => <Input status={register?.cpfErro ? 'error' : ''} />}
                                    onBlur={e => {
                                        if (!cpf.isValid(register?.cpf))
                                            setRegister({ ...register, cpfErro: true })
                                        else
                                            setRegister({ ...register, cpfErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="cpf"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        {
                            /*
                            <Col xs={24} lg={4} hidden>
                            <div>
                                <label class="control-label" for="nascimento">Nascimento</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <DatePicker id="nascimento" name="nascimento"
                                    format={'DD/MM/YYYY'}
                                    allowClear={false}
                                    value={dayjs(register?.nascimento)}
                                    onChange={value => setRegister({ ...register, nascimento: value })}
                                    status={register?.nascimentoErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.nascimento)
                                            setRegister({ ...register, nascimentoErro: true })                                        
                                        else
                                            setRegister({ ...register, nascimentoErro: false })
                                    }}
                                />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="nascimento"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                            */
                        }
                        <Col span={24}>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5} style={{ color: 'royalblue' }} >Endereço do Estabelecimento </Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ marginTop: -7, marginBottom: 5 }} />
                        </Col>
                        <Col xs={24} lg={4}>
                            <div>
                                <label class="control-label" for="cep">CEP</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <InputMask id="cep" name="cep"
                                    mask={"99999-999"}
                                    value={register?.cep}
                                    onChange={(e) => setRegister({ ...register, cep: e.target.value })}
                                    children={() => <Input />}
                                    status={register?.cepErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.cep)
                                            setRegister({ ...register, cepErro: true })
                                        else if (register?.cep?.trim() === '')
                                            setRegister({ ...register, cepErro: true })
                                        else {
                                            loadCep()
                                            setRegister({ ...register, cepErro: false })
                                        }
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="cep"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={16}>
                            <div>
                                <label class="control-label" for="endereco">Endereço</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="endereco" name="endereco"
                                    value={register?.endereco}
                                    onChange={(e) => setRegister({ ...register, endereco: e.target.value })}
                                    status={register?.enderecoErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.endereco)
                                            setRegister({ ...register, enderecoErro: true })
                                        else if (register?.endereco?.trim() === '')
                                            setRegister({ ...register, enderecoErro: true })
                                        else
                                            setRegister({ ...register, enderecoErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="endereco"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={4}>
                            <div>
                                <label class="control-label" for="numero">Número</label>
                            </div>
                            <div>
                                <Input id="numero" name="numero"
                                    value={register?.numero}
                                    onChange={(e) => setRegister({ ...register, numero: e.target.value })} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="numero"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={6}>
                            <div>
                                <label class="control-label" for="complemento">Complemento</label>
                            </div>
                            <div>
                                <Input id="complemento" name="complemento"
                                    value={register?.complemento}
                                    onChange={(e) => setRegister({ ...register, complemento: e.target.value })} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="complemento"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div>
                                <label class="control-label" for="bairro">Bairro</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="bairro" name="bairro"
                                    value={register?.bairro}
                                    onChange={(e) => setRegister({ ...register, bairro: e.target.value })}
                                    status={register?.bairroErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.bairro)
                                            setRegister({ ...register, bairroErro: true })
                                        else if (register?.bairro?.trim() === '')
                                            setRegister({ ...register, bairroErro: true })
                                        else
                                            setRegister({ ...register, bairroErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="bairro"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={6}>
                            <div>
                                <label class="control-label" for="cidade">Cidade</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Input id="cidade" name="cidade"
                                    value={register?.cidade}
                                    onChange={(e) => setRegister({ ...register, cidade: e.target.value })}
                                    status={register?.cidadeErro === true ? 'error' : ''}
                                    onBlur={() => {
                                        if (!register?.cidade)
                                            setRegister({ ...register, cidadeErro: true })
                                        else if (register?.cidade?.trim() === '')
                                            setRegister({ ...register, cidadeErro: true })
                                        else
                                            setRegister({ ...register, cidadeErro: false })
                                    }} />
                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="cidade"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>
                        <Col xs={24} lg={4}>
                            <div>
                                <label class="control-label" for="uf">UF</label>
                                <span style={{ color: 'red' }}>*</span>
                            </div>
                            <div>
                                <Select
                                    id="uf" name="uf"
                                    defaultValue={'CE'}
                                    value={register?.estado} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        setRegister({ ...register, estado: value })
                                    }}>
                                    <Option value={'AC'}>AC</Option>
                                    <Option value={'AL'}>AL</Option>
                                    <Option value={"AP"}>AP</Option>
                                    <Option value={"AM"}>AM</Option>
                                    <Option value={"BA"}>BA</Option>
                                    <Option value={"CE"}>CE</Option>
                                    <Option value={"DF"}>DF</Option>
                                    <Option value={"ES"}>ES</Option>
                                    <Option value={"GO"}>GO</Option>
                                    <Option value={"MA"}>MA</Option>
                                    <Option value={"MT"}>MT</Option>
                                    <Option value={"MS"}>MS</Option>
                                    <Option value={"MG"}>MG</Option>
                                    <Option value={"PA"}>PA</Option>
                                    <Option value={"PB"}>PB </Option>
                                    <Option value={"PR"}>PR</Option>
                                    <Option value={"PE"}>PE</Option>
                                    <Option value={"PI"}>PI</Option>
                                    <Option value={"RJ"}>RJ</Option>
                                    <Option value={"RN"}>RN</Option>
                                    <Option value={"RS"}>RS</Option>
                                    <Option value={"RO"}>RO</Option>
                                    <Option value={"RR"}>RR</Option>
                                    <Option value={"SC"}>SC</Option>
                                    <Option value={"SP"}>SP</Option>
                                    <Option value={"SE"}>SE</Option>
                                    <Option value={"TO"}>TO</Option>
                                </Select>

                            </div>
                            <div>
                                <span class="field-validation-valid" data-valmsg-for="uf"
                                    data-valmsg-replace="true"></span>
                            </div>
                        </Col>

                        <Col span={24} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Space style={{ marginTop: 15 }}>
                                <Button onClick={() => props.setModalRegister(false)}>Voltar </Button>
                                <Button type='primary'
                                    loading={loading}
                                    disabled={validarForm()}
                                    onClick={() => salvar()}>Salvar </Button>
                            </Space>
                        </Col>


                    </Row>
                </Card>


            </div>
            <br />
            <Row>
                <Col span={24}>
                    <Alert description={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        Ao clicar em salvar você concorda com a nossa
                        <Button type='link' onClick={() => navigate('/politica-de-privacidade')}>Política de Privacidade</Button>
                    </div>}
                        type="info"
                    />
                </Col>
            </Row>
            <br />
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    token: state.userReducer.user?.token
})

const mapDispatchToProps = dispatch => ({
    saveUser: (user) => dispatch(userAction.save_user(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCtrl)